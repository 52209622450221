/* eslint-disable react/jsx-pascal-case */
import React, { Component} from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Alert, 
  Space
} from "antd";
import signinbg from "../../images/img-signin.jpg";
import HomeFooter from "../../layout/FooterNEw";
import axios from "axios";
// const URL = 'https://ityumhouse.com';
// axios.defaults.headers.origin = 'http://localhost:8000';

// import { createRoot } from 'react-dom/client';
// axios.defaults.headers.origin = 'http://localhost:3000';
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;



export default class SignIn extends Component {
  timer;
  constructor(props) {
    super(props);
    this.state={
      end:false,
      setalter:false,
      message:""
    }
    this.formRef = React.createRef();
  }
  handleClear = () => {
    this.formRef.current.setFieldsValue({
      username: "",
      pwd: "",
    });
  };
  render() {
    
    // const [messageApi, contextHolder] = message.useMessage();
    const onFinish = (values) => {
      // const instance = axios.create({
      //   baseURL: 'http://localhost:8000'
      // });
      axios
          // .post("/api/auths/login",values)
          .post("https://yumi.town/api/login",values)
          // .post("https://yumi.town/api/login",values)

          .then((res)=>{
            if (res.status===200){
              console.log("로그인");
              // // console.log(res.headers["set-cookie"]);
              console.log(res);
              // let accessToken = JSON.stringify(res.data);
              
              document.cookie = "auth=logged_in; path=/; Secure; HttpOnly; SameSite=None; Secure";
              let accessToken = document.cookie;
              window.location.href = "/";
              // req.session.jwt = accessToken; // 세션에 토큰 저장
              // localStorage.setItem('jwt', accessToken);
              // sessionStorage.setItem('jwt', accessToken);
              // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
              // window.location.replace("/");
            }
          })
          .catch((err)=>{
            if(err.response.status===400){
              this.setState({setalter:true, message:err.response.data.message})
              setTimeout(() => {
                // 가장 최신의 값
                this.setState({setalter:false, message:""})
              }, 2000);
              this.handleClear()
            }else{console.error(err)}
          })
        };
      
      
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    
    return (
      <>
      {/* {contextHolder} */}
        <Layout className="layout-default layout-signin">
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Title className="mb-15">Sign In</Title>
                
                {this.state.setalter?<Space direction="vertical" style={{ width: '100%' }}>
                <Alert message={this.state.message} type="error" />
                </Space>:<Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title>}
                
                <Form
                  ref={this.formRef}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username",
                      },
                    ]}
                  >
                    <Input placeholder="username"/>
                  </Form.Item>
                  <Form.Item
                    className="pwd"
                    label="Password"
                    name="pwd"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input placeholder="Password" type="password" />
                  </Form.Item>

                  {/* <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked onChange={onChange} />
                    Remember me
                  </Form.Item> */}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Don't have an account?{" "}
                    {/* <Link to="/web/sign-up" className="text-dark font-bold">
                      Sign Up
                    </Link> */}
                  </p>
                </Form>
              </Col>
              {/* <Col
                className="sign-img"
                style={{ padding: 12 }}
                xs={{ span: 24 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <img src={signinbg} alt="" />
              </Col> */}
            </Row>
          </Content>
          <Footer>
            <HomeFooter />
          </Footer>
        </Layout>
        
      </>
    );
  }
}
