/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from "react";
import { Row, Col, Table, Card, Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";


const { Option } = Select;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const getdata = [
  {
    key: "1",
    endpoint: "/state",
    permission: "all",
    send:"",
    result:"state",
    resultdescription:"",
    description: "홈페이지서버 상태를 확인합니다(개발,테스트,운영)",
  },
  {
    key: "2",
    endpoint: "/info",
    permission: "all",
    send:"",
    result:"access_ip, country_name, Address, postcode, serverlist",
    resultdescription:"",
    description: "메인의 Access 정보를 조회하는 API입니다 shodan api와 ipinfo라이브러리를 참조합니다",
  },
  {
    key: "3",
    endpoint: "/auths/list/{degreeNum}",
    permission: "degree1",
    send:"token, degreeNum",
    result:"",
    resultdescription:"",
    description: "보류",
  },
  {
    key: "4",
    endpoint: "/auths/degree",
    permission: "degree1",
    send:"token",
    result:"permission table list",
    resultdescription:"",
    description: "token소유자가 관리자인 경우, 권한명 및 권한등급 테이블을 반환합니다",
  },
  {
    key: "5",
    endpoint: "/auths/degree/list",
    permission: "degree1",
    send:"token",
    result:"권한리스트",
    resultdescription:"",
    description: "",
  },
  {
    key: "6",
    endpoint: "/provide/listt",
    permission: "degree3",
    send:"token",
    result:"업종리스트",
    resultdescription:"",
    description: "",
  },
  {
    key: "7",
    endpoint: "/companyhead/list",
    permission: "degree3",
    send:"token",
    result:"업체명리스트",
    resultdescription:"",
    description: "",
  }
  
  
];
const postdata = [
  {
    key: "1",
    endpoint: "/auths/",
    permission: "토근소유자",
    send:"token",
    result:"result, userid, useremail, username", 
    resultdescription:"",
    description: "헤더에 토큰을 첨부하여 제출하면 복호화하여 사용자의 정보를 반환합니다",
  },
  {
    key: "2",
    endpoint: "/auths/register",
    permission: "all",
    send:"name, email, password",
    result:"success, message",
    resultdescription:"",
    description: "사용자등록을 합니다 state code 200, 409, 400상태를 반환합니다",
  },
  {
    key: "3",
    endpoint: "/auths/login",
    permission: "로컬아이디 소유자",
    send:"email, password",
    result:"token",
    resultdescription:"",
    description: "계정이 확인된 경우 30분 사용가능한 토큰을 반환합니다 ",
  },
  {
    key: "4",
    endpoint: "/auths/degree/add",
    permission: "degree1",
    send:"token, title, degree",
    result:"success, message, status code(200,400,401,409)",
    resultdescription:"",
    description: "token소유자가 관리자인 경우, 권한명과 등급을 등록합니다",
  },
  {
    key: "5",
    endpoint: "/auths/degree/up",
    permission: "degree1",
    send:"token, id, title, degree",
    result:"success, message, status code(200,400,401,409)",
    resultdescription:"",
    description: "token소유자가 관리자인 경우, 권한명과 등급을 수정합니다",
  },
  {
    key: "6",
    endpoint: "/auths/degree/del",
    permission: "degree1",
    send:"token, id",
    result:"success, message, status code(200,400,401,409)",
    resultdescription:"",
    description: "token소유자가 관리자인 경우, 권한명과 등급을 삭제합니다",
  },
  {
    key: "7",
    endpoint: "/auths/degree/up/:id",
    permission: "degree1",
    send:"token",
    result:"",
    resultdescription:"",
    description: "권한수정",
  },
  {
    key: "8",
    endpoint: "/auths/degree/del/:id",
    permission: "degree1",
    send:"token",
    result:"",
    resultdescription:"",
    description: "권한삭제",
  },
  {
    key: "9",
    endpoint: "/provide/add",
    permission: "degree3",
    send:"token",
    result:"",
    resultdescription:"",
    description: "업종추가",
  },
  {
    key: "10",
    endpoint: "/provide/up",
    permission: "degree2",
    send:"token",
    result:"",
    resultdescription:"",
    description: "업종수정",
  },
  {
    key: "11",
    endpoint: "/provide/del/:id",
    permission: "degree2",
    send:"token",
    result:"",
    resultdescription:"",
    description: "업종삭제",
  },
  {
    key: "11",
    endpoint: "/companyhead/add",
    permission: "degree3",
    send:"token",
    result:"",
    resultdescription:"",
    description: "업체명칭 추가",
  },
  {
    key: "12",
    endpoint: "/companyhead/up",
    permission: "degree3",
    send:"token",
    result:"",
    resultdescription:"",
    description: "업체명칭 수정",
  },
  {
    key: "13",
    endpoint: "/companyhead/del/:id",
    permission: "degree2",
    send:"token",
    result:"",
    resultdescription:"",
    description: "업체명칭 삭제",
  },
  
];

export default class extends Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    totalPage: 5,
    totalPage1: 5,
    
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      totalPage: pagination,
    });
  };

  // handleFilter = (e) => {
  //   let newFiltered = [...this.state.data1];
  //   newFiltered = newFiltered.filter(
  //     (data) =>
  //       data.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
  //       data.position.toLowerCase().includes(e.target.value.toLowerCase()) ||
  //       data.office.toLowerCase().includes(e.target.value.toLowerCase()) ||
  //       data.age.toString().includes(e.target.value.toLowerCase()) ||
  //       data.start.toString().includes(e.target.value.toLowerCase()) ||
  //       data.salary.toString().includes(e.target.value.toLowerCase())
  //   );
  //   this.setState({ filteredData: newFiltered });
  // };

  handleTotalPageChange = (val) => {
    this.setState({ totalPage: val });
  };
  handleTotalPageChange1 = (val) => {
    this.setState({ totalPage1: val });
  };
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: "End_Point",
        dataIndex: "endpoint",
        key: "endpoint",
        ellipsis: true,
      },
      {
        title: "Permission",
        dataIndex: "permission",
        key: "permission",
        ellipsis: true,
      },
      {
        title: "Send",
        dataIndex: "send",
        key: "send",
        ellipsis: true,
      },
      
      {
        title: "Result",
        dataIndex: "result",
        key: "result",
        ellipsis: true,
      },
      
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
      },
    ];

    //  search

    const columns1 = [
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "POSITION",
        dataIndex: "position",

        sorter: (a, b) => a.position.length - b.position.length,
        sortOrder: sortedInfo.columnKey === "position" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "OFFICE",
        dataIndex: "office",
        sorter: (a, b) => a.office.length - b.office.length,
        sortOrder: sortedInfo.columnKey === "office" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "AGE",
        dataIndex: "age",
        sorter: (a, b) => a.age.length - b.age.length,
        sortOrder: sortedInfo.columnKey === "age" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "START DATE",
        dataIndex: "start",
        sorter: (a, b) => a.start.length - b.start.length,
        sortOrder: sortedInfo.columnKey === "start" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "SALARY",
        dataIndex: "salary",
        sorter: (a, b) => a.salary.length - b.salary.length,
        sortOrder: sortedInfo.columnKey === "salary" && sortedInfo.order,

        ellipsis: true,
      },
    ];

    return (
      <>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bodyStyle={{ padding: "16px 0px 0px" }}
              bordered={false}
              className="header-solid mb-24"
              title={[<h5 className="font-semibold"> GET APIS( ityumhouse.com/api/ ) to json</h5>]}
            >
              <div className="px-25">
                <Select
                  defaultValue="5"
                  onChange={this.handleTotalPageChange}
                  size="large"
                  style={{ width: "70px" }}
                >
                  <Option value="5">5</Option>
                  <Option value="10">10</Option>
                  <Option value="15">15</Option>
                  <Option value="20">20</Option>
                  <Option value="25">25</Option>
                </Select>

                <label className="ml-10">entries per page</label>
              </div>
              <div className="ant-table-body">
                <Table
                  className="mt-20"
                  columns={columns}
                  
                  dataSource={getdata}
                  tableLayout="auto"
                  // onChange={onchange}
                  scroll={{ x: 500 }}
                  pagination={{
                    pageSize: this.state.totalPage,
                  }}
                />
              </div>
            </Card>
            <Card
              bodyStyle={{ padding: "16px 0px 0px" }}
              bordered={false}
              className="header-solid mb-24"
              title={[<h5 className="font-semibold"> POST APIS( ityumhouse.com/api/ ) to json</h5>]}
            >
              <div className="px-25">
                <Select
                  defaultValue="5"
                  onChange={this.handleTotalPageChange}
                  size="large"
                  style={{ width: "70px" }}
                >
                  <Option value="5">5</Option>
                  <Option value="10">10</Option>
                  <Option value="15">15</Option>
                  <Option value="20">20</Option>
                  <Option value="25">25</Option>
                </Select>

                <label className="ml-10">entries per page</label>
              </div>
              <div className="ant-table-body">
                <Table
                  className="mt-20"
                  columns={columns}
                  dataSource={postdata}
                  tableLayout="auto"
                  // onChange={onchange}
                  scroll={{ x: 500 }}
                  pagination={{
                    pageSize: this.state.totalPage,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
