import React, { Component, } from "react";
import { Card, Col, Row, Typography } from "antd";
import {} from "@ant-design/icons";
import Echart from "../chart/Echart";
import LineChart from "../chart/LineChart";
import postscribe from "postscribe";
import axios from 'axios';

const { Title } = Typography;

// axios.defaults.headers.origin = 'http://localhost:3000';
export default class Home extends Component {
  infodata={
    // access_ip:"",
    // country:"",
    // address:"",
    // carrier:"",
    // postcode:""
  }
  
  // accessinfo = async() => {
  //   axios
  //   .get('/api/info')
  //   .then(({ data }) => {
  //     this.setState({data:data})
  //     // this.infodata=data
  //   })
  //   .catch((err) => {
  //     console.log(err);
      
  //   });
  // }
  
  componentDidMount = () => {
    postscribe(
      "#globe",
      `
      <script src="../js/threejs.js"></script>
      <script src="../js/orbit-controls.js"></script>
      <script src="../js/globe.js"></script>
    `
    );
    // this.accessinfo();
    
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      placement: "left",
      sidebarColor: "#1890ff",
      sidenavType: "transparent",
      fixed: false,
      data:this.infodata
    };
  }
  handleSidebarColor = (color) => {
    this.setState({ sidebarColor: color });
  };

  handleSidenavType = (type) => {
    this.setState({ sidenavType: type });
  };

  handleFixedNavbar = (type) => {
    this.setState({ fixed: type });
  };
  OpenDrawer = () => {
    // /alert("hello");
    this.setState({
      visible: !this.state.visible,
    });
  };
  render() {
    
    const ipicon = [
      <svg
      width="22"
      height="22" 
      key={0}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 576 512">
        <path 
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M418.4 157.9c35.3-8.3 61.6-40 61.6-77.9c0-44.2-35.8-80-80-80c-43.4 0-78.7 34.5-80 77.5L136.2 151.1C121.7 136.8 101.9 128 80 128c-44.2 0-80 35.8-80 80s35.8 80 80 80c12.2 0 23.8-2.7 34.1-7.6L259.7 407.8c-2.4 7.6-3.7 15.8-3.7 24.2c0 44.2 35.8 80 80 80s80-35.8 80-80c0-27.7-14-52.1-35.4-66.4l37.8-207.7zM156.3 232.2c2.2-6.9 3.5-14.2 3.7-21.7l183.8-73.5c3.6 3.5 7.4 6.7 11.6 9.5L317.6 354.1c-5.5 1.3-10.8 3.1-15.8 5.5L156.3 232.2z"/>
      </svg>,
    ];
    const worldicon = [
      <svg
      width="22"
      height="22" 
      key={0}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 576 512">
        <path 
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zm48 0c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z"/>
      </svg>,
    ];
    const addressicon = [
      <svg
      width="22"
      height="22" 
      key={0}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 576 512">
        <path 
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40z"/>
      </svg>,
    ];
    const carriericon = [
      <svg
      width="22"
      height="22" 
      key={0}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 576 512">
        <path 
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M192 32c0-17.7 14.3-32 32-32C383.1 0 512 128.9 512 288c0 17.7-14.3 32-32 32s-32-14.3-32-32C448 164.3 347.7 64 224 64c-17.7 0-32-14.3-32-32zM60.6 220.6L164.7 324.7l28.4-28.4c-.7-2.6-1.1-5.4-1.1-8.3c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32c-2.9 0-5.6-.4-8.3-1.1l-28.4 28.4L291.4 451.4c14.5 14.5 11.8 38.8-7.3 46.3C260.5 506.9 234.9 512 208 512C93.1 512 0 418.9 0 304c0-26.9 5.1-52.5 14.4-76.1c7.5-19 31.8-21.8 46.3-7.3zM224 96c106 0 192 86 192 192c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-70.7-57.3-128-128-128c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/>
      </svg>,
    ];
    const postcodeicon = [
      <svg
      width="22"
      height="22" 
      key={0}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 576 512">
        <path 
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        d="M224 32H64C46.3 32 32 46.3 32 64v64c0 17.7 14.3 32 32 32H441.4c4.2 0 8.3-1.7 11.3-4.7l48-48c6.2-6.2 6.2-16.4 0-22.6l-48-48c-3-3-7.1-4.7-11.3-4.7H288c0-17.7-14.3-32-32-32s-32 14.3-32 32zM480 256c0-17.7-14.3-32-32-32H288V192H224v32H70.6c-4.2 0-8.3 1.7-11.3 4.7l-48 48c-6.2 6.2-6.2 16.4 0 22.6l48 48c3 3 7.1 4.7 11.3 4.7H448c17.7 0 32-14.3 32-32V256zM288 480V384H224v96c0 17.7 14.3 32 32 32s32-14.3 32-32z"/>
      </svg>,
    ];
    const list = [
      {
        title:"IP Address",
        content:this.state.data.access_ip,
        icon: ipicon
      },
      {
        title:"Country",
        content:this.state.data.country_name||"",
        icon: worldicon
      },
      {
        title:"Address",
        content:this.state.data.Address||"",
        icon: addressicon
      },
      
      {
        title:"Postcode",
        content:this.state.data.postcode||"",
        icon: postcodeicon
      },
      
      {
        title:"Using Sever",
        content:this.state.data.serverlist ? JSON.stringify(this.state.data.serverlist)
        .replace(/"|{|}|/g ,'').replace(/\[|\]/g,'').replace(/,port:/g,'\nport:').replace(/,domains:/g,'domains:'):"",
        icon: carriericon
      },
    ];
    const iplist = {
      title:"location",
      content:"00.0000.000.0000",
      icon: worldicon
    }
    return (
      <>
        <div className="layout-content">
          <Row gutter={[24, 0]}>
            <Col lg={12} className="zindex">
              <h2 className="p-15 h2">IOT TEST용 웹</h2>
              <Row className="rowgap-vbox" gutter={[24, 0]}>
              {/* {list.map((c, index) => (
                  <Col key={index} xs={24} sm={24} lg={24} className="mb-24">
                    <Card bordered={false} className="criclebox ">
                      <div className="number">
                        <Row align="middle" gutter={[0]}>
                          <Col xs={18}>
                            <span>{c.title}</span>
                            <Title level={3}>
                              {c.content}{" "}
                            </Title>
                          </Col>
                          <Col xs={6}>
                            <div className="icon-box">{c.icon}</div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))} */}
              </Row>
            </Col>
            <div id="globe" className="globeContainer peekaboo">
              <canvas
                width="700"
                height="655"
                className="w-lg-100 h-lg-100 w-75 h-75 me-lg-0 me-n10 mt-lg-5"
              ></canvas>
            </div>
          </Row>
        </div>
      </>
    );
  }
}
