/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import gifview from '../../images/taxsendview.gif';
import {
  Tabs,
  Drawer,
  AutoComplete,
  Card,
  Row,
  Col,
  Modal,
  Anchor,
  InputNumber,
  Popconfirm,
  Result,
  Avatar,
  Switch,
  Form,
  Radio,
  Input,
  Space,
  Button,
  Carousel,
  Table,
  Select,
  Typography,
  Tag,
  Affix,
} from "antd";

import {
  AppstoreFilled,
  SnippetsFilled,
  SafetyCertificateFilled,
  DashboardFilled,
  NotificationFilled,
  WalletFilled,
  DeleteFilled,
  DesktopOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  MobileOutlined,
  ArrowRightOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  UpOutlined,
  UnlockFilled,
  CodeSandboxCircleFilled,
} from "@ant-design/icons";
import profilavatar from "../../images/face-1.jpg";
import slack from "../../images/slack.svg";
import spotify from "../../images/spotify.svg";
import atlassian from "../../images/atlassian.svg";
import asana from "../../images/asana.svg";
import { render } from "@testing-library/react";
import TextArea from "antd/lib/input/TextArea";
const { Title } = Typography;
const token = localStorage.getItem('jwt') ||false
const {Text } = Typography;
const contentStyle = {

  
  width: '850px',
  
  // height: '850px',
  // color: '#fff',
  // lineHeight: '800px',
  
  // background: '#364d79',
};

const { TabPane } = Tabs;
function onChange(checked) {
  console.log(`switch to ${checked}`);
}

function callback(key) {
}



const chooseSource = [
  {
    key: 'brain',
    label: "label",
    choose: 'choose',
  },
];
const dataSource = [
  {
    key: 'brain',
    brain: "Qwdqwd",
    company: 'John',
  },
];
const dataSource1 = [
  {
    key: 'brain',
    day:"day",
    taxtotal: "taxtotal",
    total: 'John',
  },
];

const totaldataSource = [
  {
    key: 'brain',
    total:"total",
    totalbalance:"totalbalance",
    depositamount: "depositamount",
    currentbalance: 'currentbalance',
    division: 'division',
  },]

  
  
export default class TaxSend extends Component {
  
  
  constructor(props) {
    super(props);
    this.state={
      loguser:false,
      logdegree:false,
      taxcategory:0,
      volume:1,
      selProduct:"",
      totalmoney:{supplyprice:0,taxamount:0,total:0},
      inputtotalmoney:{unitprice:0,taxamount:0,total:0},
      modaropen:false,
      productmodaropen:false,
      moneymodar:false,
      DrawerOpen:false,
      taxdocoptions:{taxmoney:"tax",compcategory:"buisness",doccategory:"receipt"},
      inputtaxdocoptions:{taxmoney:"tax",doccategory:"receipt"},
      option:[],
      optionproduct:[],
      docdate:this.daydata,
      
      getcompany:{
        Address:"",Email:"",FAXnum:"",HPnum:"",
        Telnum:"",title:"",Manager:"",
        Owner:"",brnum:"",class1:"",class2:"",end:""},
      brain:{
        Address:"경상북도 안동시 풍산읍 지풍로 2092-1, 2층 201호",Email:"",FAXnum:"",HPnum:"",
        Telnum:"054-855-2004",title:"브레인컨설팅",Manager:"이선심",
        Owner:"엄수호",brnum:"508-08-70364",class1:"서비스 도매 및 소매업",class2:"소프트웨어개발 컴퓨터",end:""},
      productlist:[
        {
          key: 0,
          month:"",
          day:"",
          product: "",
          standard: '',
          num: '',
          unitprice:'',
          supplyprice:'',
          taxamount:'',
          etc:''
        },
      ],
      docetc:[],
      money:{Cash:"",ChkBill:"",Note:"",Credit:""},

      companylist:[],
      allproductlist:[],
    };
    this.etcref = React.createRef();
    // this.handleStatusChange = this.handleStatusChange.bind(this);
    // this.handleToggleClick = this.handleToggleClick.bind(this);
    
    
  }
  getlogin = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/auths/')
      .then((res)=>{
        let braintemp = this.state.brain
        braintemp.Manager=res.data.username
        braintemp.Email=res.data.useremail
        
        this.setState({
          logdegree:res.data.degree,
          loguser:res.data,
          brain:braintemp
        })
      })
      .catch((err)=>{
        console.error(err)
      });
  }
  componentWillMount(){
    const today = new Date();
      today.setHours(today.getHours()+9);
    const date = today.setDate(today.getDate());
    const daydata = new Date(date).toISOString().split('T')[0]
    this.setState({daydata:daydata})
    this.getlogin();
  }
  // componentDidMount(){
    
    
    
  // }
  
  componentDidUpdate(prevState, prevProps) {
    if(prevProps.productlist!==this.state.productlist){
      this.totalupdate ()
    }

  };
 totalupdate (){
  let newtotalmoney = this.state.totalmoney
      let supplypriceTotal=0
      let taxamountTotal=0
      this.state.productlist.map((item)=>{
        supplypriceTotal+=Number(item.supplyprice.toString().replace(/,/g, ""))
        taxamountTotal+=Number(item.taxamount.toString().replace(/,/g, ""))
      })
      if (this.state.taxcategory===0){
        newtotalmoney.supplyprice = supplypriceTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        newtotalmoney.taxamount = this.state.taxdocoptions.taxmoney==="tax"?taxamountTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"0"
        newtotalmoney.total = this.state.taxdocoptions.taxmoney==="tax"?
        (supplypriceTotal+taxamountTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        :supplypriceTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.setState({totalmoney:newtotalmoney})
      }
      if (this.state.taxcategory===1){
        newtotalmoney.supplyprice = supplypriceTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        newtotalmoney.taxamount = this.state.inputtaxdocoptions.taxmoney==="tax"?taxamountTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"0"
        newtotalmoney.total = this.state.inputtaxdocoptions.taxmoney==="tax"?
        (supplypriceTotal+taxamountTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        :supplypriceTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.setState({totalmoney:newtotalmoney})
      }
 }

  
  getCompany = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/company/list')
      .then((res)=>{
        let newCompanylist=[...this.state.companylist]
        newCompanylist=[]
        res.data.map((item)=>{
          newCompanylist.push({title:item.Company.title,id:item.Company.id})
        })
        this.setState({companylist:newCompanylist})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  getProduct = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/product/list')
      .then((res)=>{
        let newallproductlist=[...this.state.allproductlist]
        newallproductlist=[]
        res.data.map((item)=>{
          newallproductlist.push({
            product:item.title,
            ea:item.ea,
            unitprice:item.ProductPrice?item.ProductPrice.supplyprice:"",
            id:item.id})
        })
        this.setState({allproductlist:newallproductlist})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  
  
  render() {
    
    
    if (this.state.logdegree>3){
      return (
        <>
          <Result
            status="403"
            title="권한없는 접근입니다"
            subTitle=""
            extra={<>
            </>}
          />
        </>
      )
    }else if(!token||!this.state.logdegree){
      return(<>
      <div>
        <Row>
          <Col lg={12} md={24} sm={24} style={{textAlign:"center"}}>
            
            <img src={gifview} alt="my-gif" />
          </Col>
          <Col lg={12} md={24} sm={24} style={{textAlign:"left"}}>
            <Card>
            <Title level={2} style={{marginLeft:"15px", textAlign:"center"}}>
              로그인이 필요한 메뉴
            </Title>
            <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Front-End</Text><br/>
            <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>전사문서 생성</Text><br/>
            <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>일부컴포넌트 DOM직접접근</Text><br/>
            <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>컴포넌트 커스텀</Text><br/><br/>
            <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Back-End</Text><br/>
            <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>BILL사와 연동</Text><br/>
            </Card>
            
          </Col>
        
            
            
            </Row>
          </div>
      </>)
    }

const handleSearch = (value) => {
  this.setState({option:value ? searchResult(value) : []})
};
const handleSearchProduct = (value) => {
  this.setState({optionproduct:value ? searchResultProduct(value) : []})
};
const onSelect = async(value) => {
  let temp=this.state.companylist.filter((item)=>item.title.toLowerCase().includes(value.toLowerCase()))
  let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get(`/api/company/id=${temp[0].id}`)
      .then((res)=>{
        console.log(res.data)
        let fullAddress = res.data.Address?
        res.data.Address.data.address_name+"  "+res.data.Address.data.etc
        :""
        fullAddress=fullAddress.replace("undefined","")
        let data = {
          Address:fullAddress,
          // ETCAddress:res.data.Address.data.etc?res.data.Address.data.etc:"",
          Email:res.data.Email?res.data.Email.email[0]:"",
          FAXnum:res.data.FAXnum?res.data.FAXnum:"",
          HPnum:res.data.HPnum?res.data.HPnum.num[0]:"",Manager:"",
          Telnum:res.data.Telnum?res.data.Telnum.num[0]:"",title:res.data.title,
          Manager:res.data.Manager?res.data.Manager.user.map((item)=>{
            if(item.class==="담당자"){return `${item.name}`}}).filter(element => element):"",
          Owner:res.data.Manager?res.data.Manager.user.map((item)=>{
            if(item.class==="책임자"){return `${item.name}`}}).filter(element => element):"",
          brnum:res.data.Branch.brnum
        }
        this.setState({getcompany:data})
      })
      .catch((err)=>{
        console.error(err)
      })
      this.setState({modaropen:false})
};
const onSelectProduct = async(value) => {
  let temp=this.state.allproductlist.filter((item)=>item.product.toLowerCase().includes(value.toLowerCase()))
  this.setState({selProduct:temp})
};
const SelectProductAdd = async() => {
  let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get(`/api/product/id=${this.state.selProduct[0].id}`)
      .then((res)=>{
        let newproductlist = [...this.state.productlist]
        let key=0
        let doctype = this.state.taxcategory===0?this.state.taxdocoptions.taxmoney:this.state.inputtaxdocoptions.taxmoney
        let money = res.data.ProductPrice?parseInt(res.data.ProductPrice.supplyprice,10):""
        let volume =  Number(this.state.volume)
        for (let i=0; i < 30; i++){
          if ((this.state.productlist.filter((item)=>item.key===i).length===0)){
            key=i
            break
        }else {
          continue
        }}
        if (doctype==="tax"){
          newproductlist.push({
            key: key,
            month:"",
            day:"",
            product: res.data.title,
            standard: res.data.ea,
            num: volume,
            unitprice:money!==""?money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"",
            supplyprice:money!==""?(money*volume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"",
            taxamount:money!==""?((money*0.10)*volume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"",
            etc:''
          })
        }else{
          newproductlist.push({
            key: key,
            month:"",
            day:"",
            product: res.data.title,
            standard: res.data.ea,
            num: volume,
            unitprice:money,
            supplyprice:money!==""?(money*volume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"",
            taxamount:"",
            etc:''
          })
        }
        this.setState({productlist:newproductlist})
        this.setState({productmodaropen:false})
      })
      .catch((err)=>{
        console.error(err)
      })

}

const onChangeCarousel = (currentSlide) => {
  this.setState({taxcategory:currentSlide})
};
const onChangeDate = (e) => {
  e.preventDefault();
  this.setState({daydata:e.target.value})
  console.log(this.state.daydata)
};

const onChangetaxdoc = (e,num) => {
  let newTaxdoc = this.state.taxdocoptions
  switch(num){
    case 1:newTaxdoc.taxmoney=e.target.value; break
    case 2:newTaxdoc.compcategory=e.target.value; break 
    case 3:newTaxdoc.doccategory=e.target.value; break 
  }
  this.setState({taxdocoptions:newTaxdoc})
};

const onChangeinputtaxdoc = (e,num) => {
  let newInputTaxdoc = this.state.inputtaxdocoptions
  switch(num){
    case 1:newInputTaxdoc.taxmoney=e.target.value; break
    case 2:newInputTaxdoc.doccategory=e.target.value; break 
  }
  this.setState({inputtaxdocoptions:newInputTaxdoc})
};


const onChangeCompany = (e,num) => {
  e.preventDefault();
  let temp = this.state.getcompany
  switch  (num){
    case 1:temp.brnum = e.target.value; break
    case 2:temp.title = e.target.value; break
    case 3:temp.Owner = e.target.value; break
    case 4:temp.Address = e.target.value; break
    case 5:temp.Manager = e.target.value; break
    case 6:temp.Telnum = e.target.value; break
    case 7:temp.Email = e.target.value; break
    case 8:temp.class1 = e.target.value; break
    case 9:temp.class2 = e.target.value; break
    case 10:temp.end = e.target.value; break
  }
  this.setState({getcompany:temp})
};

const onChangeBrain = (e,num) => {
  e.preventDefault();
  let temp = this.state.brain
  switch  (num){
    case 1:temp.brnum = e.target.value; break
    case 2:temp.title = e.target.value; break
    case 3:temp.Owner = e.target.value; break
    case 4:temp.Address = e.target.value; break
    case 5:temp.Manager = e.target.value; break
    case 6:temp.Telnum = e.target.value; break
    case 7:temp.Email = e.target.value; break
    case 8:temp.class1 = e.target.value; break
    case 9:temp.class2 = e.target.value; break
    case 10:temp.end = e.target.value; break
  }
  this.setState({brain:temp})
};
const onChangeProduct =(e,key,num)=>{
  e.preventDefault();
  let newproductlist = [...this.state.productlist]
  let temp = newproductlist.filter((item)=>item.key===key)
  let index = newproductlist.findIndex( x => x.key === key );
  switch  (num){
    case 1:temp[0].month = e.target.value 
    newproductlist[index]=temp[0]; break
    case 2:temp[0].day = e.target.value; break
    case 3:temp[0].product = e.target.value; break
    case 4:temp[0].standard = e.target.value; break
    case 5:temp[0].num = e.target.value; break
    case 6:temp[0].unitprice = Number(e.target.value.replace(/,/g, "")) ; break
    case 7:temp[0].supplyprice = Number(e.target.value.replace(/,/g, "")); break
    case 8:temp[0].taxamount = Number(e.target.value.replace(/,/g, "")); break
    case 9:temp[0].etc = e.target.value; break
  }
  
  newproductlist[index]=temp[0]
  this.setState({productlist:newproductlist})
}
const onChangevolume = (value) => {
  this.setState({volume:Number(value)})
};
const onChangeMoney = (value,num) => {
  value.preventDefault();
  let temp = this.state.money
  switch (num) {
    case 1:temp.Cash=value.target.value;
      break
    case 2:temp.ChkBill=value.target.value;
      break
    case 3:temp.Note=value.target.value;
      break
    case 4:temp.Credit=value.target.value;
      break
  }
  this.setState({money:temp})
};
const onChangeinputotal = (e) => {
  let total= Math.floor(Number(e.target.value)/10)*10 
  let taxamount=this.state.taxdocoptions.taxmoney==="tax"?Math.floor(Number(total*0.10)/10) * 10:0
  let unitprice = this.state.taxdocoptions.taxmoney==="tax"?Math.floor(Number(total-taxamount)/10) * 10:0
  let data ={
    unitprice:unitprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    total:total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    taxamount:taxamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
  this.setState({inputtotalmoney:data})
};
const onChangeDocetc = (value,key) => {
  let newDocetc = [...this.state.docetc]
  newDocetc=[]
  let list = this.etcref.querySelectorAll('input')
  list.forEach((item,index)=>{
    newDocetc.push({key:index,etc:item.value})
  })
  this.setState({docetc:newDocetc})
  
};
const inputotalAdd = () => {
  let newproductlist = [...this.state.productlist]
  for (let i=0; i < 30; i++){
    if ((this.state.productlist.filter((item)=>item.key===i).length===0)){
      newproductlist.push({
        key: i,
        month:"",
        day:"",
        product: "",
        standard: '',
        num: '',
        unitprice:this.state.inputtotalmoney.unitprice,
        supplyprice:this.state.inputtotalmoney.total,
        taxamount:this.state.inputtotalmoney.taxamount,
        etc:''
      })
      this.setState({productlist:newproductlist})
      break
  }else {
    continue
  }}
  this.setState({moneymodar:false})
}

const searchResult = (value) =>
  this.state.companylist.filter((item)=>item.title.toLowerCase().includes(value.toLowerCase()))
    .map((item, idx, array) => {
      const category = `${item.title}`;
        return {
          value: item.title,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                <a
                >
                  {category} 
                </a>
              </span>
              <span>{idx===0? `을 포함한 ${array.length}건`:""}<a style={{color:"#B8B8B8"}}>(ID:{item.id})</a></span>
            </div>
            
          ),
        };
    });
  const searchResultProduct = (value) =>
    this.state.allproductlist.filter((item)=>item.product.toLowerCase().includes(value.toLowerCase()))
      .map((item, idx, array) => {
        const category = `${item.product}`;
          return {
            value: item.product,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>
                  <a
                  >
                    {category} 
                  </a>
                </span>
                <span>{idx===0? `을 포함한 ${array.length}건`:""}<a style={{color:"#B8B8B8"}}>(ID:{item.id})</a></span>
              </div>
              
            ),
          };
      });
const producttargetdel =(value)=>{
  const newData = this.state.productlist.filter((item) => item.key !== value);
    this.setState({productlist:newData})
}
const producttargetup =()=>{
  let temp=this.state.productlist.length
  let list = [...this.state.productlist]
  for (let i=0; i < 30; i++){
    if ((this.state.productlist.filter((item)=>item.key===i).length===0)){
      list.push({
        key: i,
        month:"",
        day:"",
        product: "",
        standard: '',
        num: '',
        unitprice:'',
        supplyprice:'',
        taxamount:'',
        etc:''
      })
      this.setState({productlist:list})
      break
  }else {
    continue
  }}}

const TaxSendcall = async(valuse)=>{
  var taxInvoice = {
      InvoiceeASPEmail        : '',
      IssueDirection          : this.state.taxcategory===0?1:2,
      TaxInvoiceType          : 1,
      TaxType                 : this.state.taxdocoptions.taxmoney==='tax'?1:this.state.taxdocoptions.taxmoney==='zerotax'?2:3,
      TaxCalcType             : 1,
      PurposeType             : this.state.taxdocoptions.doccategory==="receipt"?2:1,
      ModifyCode              : '',
      WriteDate               : this.state.daydata.replace(/[\s-]/g,''),
      AmountTotal             : this.state.totalmoney.supplyprice,
      TaxTotal                : this.state.totalmoney.taxamount,
      TotalAmount             : this.state.totalmoney.total,
      Cash                    : this.state.money.Cash,
      ChkBill                 : this.state.money.ChkBill,
      Note                    : this.state.money.Note,
      Credit                  : this.state.money.Credit,
    };
  this.state.docetc.map((item,index)=>{
    taxInvoice[`Remark${item.key+1}`] = item.etc
    return taxInvoice
  });
  var InvoicerParty = {
      MgtNum     : '1',
			CorpNum    : this.state.brain.brnum.replace(/[\s-]/g,''),
			TaxRegID   : '',
			CorpName   : this.state.brain.title,
			CEOName    : this.state.brain.Owner,
			Addr       : this.state.brain.Address,
			BizClass   : this.state.brain.class2,
			BizType    : this.state.brain.class1,
			ContactID  : 'yumzi114',
			ContactName: '엄태현',
			TEL        : this.state.brain.Telnum,
			HP         : this.state.brain.HPnum,
			Email      : this.state.brain.Email
  };
  var InvoiceeParty = {
      MgtNum     : '',
      CorpNum    : this.state.getcompany.brnum.replace(/[\s-]/g,''),
      TaxRegID   : '',
      CorpName   : this.state.getcompany.title,
      CEOName    : this.state.getcompany.Owner.length===1?this.state.getcompany.Owner[0]:this.state.getcompany.Owner,
      Addr       : this.state.getcompany.Address,
      BizClass   : this.state.getcompany.class2,
      BizType    : this.state.getcompany.class1,
      ContactID  : '',
      ContactName: this.state.getcompany.Manager.length===1?this.state.getcompany.Manager[0]:this.state.getcompany.Manager,
      TEL        : this.state.getcompany.Telnum,
      HP         : this.state.getcompany.HPnum,
      Email      : this.state.getcompany.Email
  };
  var TaxInvoiceTradeLineItems={
    'TaxInvoiceTradeLineItem': []
   }
  this.state.productlist.map((item,index)=>{
    TaxInvoiceTradeLineItems.TaxInvoiceTradeLineItem.push(
      {
        PurchaseExpiry: '',
					Name          : item.unitprice,
					Information   : item.standard,
					ChargeableUnit: item.num,
					UnitPrice     : item.unitprice,
					Amount        : item.supplyprice,
					Tax           : item.taxamount,
					Description   : item.etc
      }
    )
  })
  taxInvoice.TaxInvoiceTradeLineItems=TaxInvoiceTradeLineItems
  taxInvoice.InvoicerParty=InvoicerParty
  taxInvoice.InvoiceeParty=InvoiceeParty
  console.log(taxInvoice)
  
  let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/bill/regist',taxInvoice)
      .then((res)=>{
        console.log(res)
      })
      .catch((err)=>{
        console.error(err)
      });
}
const Taxcolumns = [
  {
    title: '공급자',
    dataIndex: 'brain',
    key: 'brain',
    render:(_,record)=>{
      return (
        <>
        <Space size={[0, 0]} wrap style={{
          borderCollapse:"separate",
        }}>
      <Input
        addonBefore="등록번호"
        value={this.state.brain.brnum}
        onChange={(event) => onChangeBrain(event, 1)}
        size="small"
        style={{
          width: '250px',
          
        }}
      />
      <Input
        addonBefore="종사업장"
        value={this.state.brain.end}
        onChange={(event) => onChangeBrain(event, 10)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        // defaultValue={}
        addonBefore="상호"
        value={this.state.brain.title}
        onChange={(event) => onChangeBrain(event, 2)}
        size="small"
        style={{
          width: '250px',
        }}
      />
      <Input
        addonBefore="성명"
        value={this.state.brain.Owner}
        onChange={(event) => onChangeBrain(event, 3)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="사업장주소"
        value={this.state.brain.Address}
        onChange={(event) => onChangeBrain(event, 4)}
        size="small"
        // defaultValue={}
        style={{
          width: '400px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="업태"
        value={this.state.brain.class1}
        onChange={(event) => onChangeBrain(event, 8)}
        size="small"

        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="종목"
        value={this.state.brain.class2}
        onChange={(event) => onChangeBrain(event, 9)}
        size="small"
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="담당자"
        value={this.state.brain.Manager}
        onChange={(event) => onChangeBrain(event, 5)}
        size="small"
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="연락처"
        value={this.state.brain.Telnum}
        onChange={(event) => onChangeBrain(event, 6)}
        size="small"
        
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="이메일"
        size="small"
        value={this.state.brain.Email}
        onChange={(event) => onChangeBrain(event, 7)}
        style={{
          width: '400px',
        }}
      />
      </Space>
      </>)
      }
  },
  {
    title: ()=>{return <>
    <Space size={[0, 8]} wrap>
    <p style={{marginBottom:"0px",paddingRight:"10px",fontSize:"12px"}}>공급받는자</p>
    <Button
      shape="circle"
      onClick={()=>{
        this.getCompany()
        this.setState({modaropen:true})}}
      size="small"
      type="default" 
      icon={<SearchOutlined/>}></Button>
      
      </Space>
      </>;},
    dataIndex: 'company',
    key: 'company',
    render:(_,record)=>{
      return (
        <>
        <Space size={[0, 0]} wrap style={{
          borderCollapse:"separate",
        }}>
      <Input
        addonBefore="등록번호"
        value={this.state.getcompany.brnum}
        onChange={(event) => onChangeCompany(event, 1)}
        size="small"
        style={{
          width: '250px',
          
        }}
      />
      <Input
        addonBefore="종사업장"
        value={this.state.getcompany.end}
        onChange={(event) => onChangeCompany(event, 10)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="상호"
        value={this.state.getcompany.title}
        onChange={(event) => onChangeCompany(event, 2)}
        size="small"
        style={{
          width: '250px',
        }}
      />
      <Input
        addonBefore="성명"
        value={this.state.getcompany.Owner}
        onChange={(event) => onChangeCompany(event, 3)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="사업장주소"
        size="small"
        value={this.state.getcompany.Address}
        onChange={(event) => onChangeCompany(event, 4)}
        // initialValues={this.state.Address}
        style={{
          width: '400px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="업태"
        size="small"
        value={this.state.getcompany.class1}
        onChange={(event) => onChangeCompany(event, 8)}
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="종목"
        size="small"
        value={this.state.getcompany.class2}
        onChange={(event) => onChangeCompany(event, 9)}
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="담당자"
        size="small"
        value={this.state.getcompany.Manager}
        onChange={(event) => onChangeCompany(event, 5)}
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="연락처"
        size="small"
        value={this.state.getcompany.Telnum}
        onChange={(event) => onChangeCompany(event, 6)}
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="이메일"
        size="small"
        value={this.state.getcompany.Email}
        onChange={(event) => onChangeCompany(event, 7)}
        style={{
          width: '400px',
        }}
      />
      </Space>
      </>)
      }
  },
];
const choosecolum = [
  {
    title: 'label',
    dataIndex: 'label',
    key: 'key',
    width:'550px',
    render:(_,record)=>{
      switch(this.state.taxdocoptions.taxmoney){
        case "tax":
          return (
            <>
            <Row style={{textAlign:"center",justifyContent:"center"}}>
            <Title level={2} style={{color:"red",textAlign:"center"}}>세금계산서</Title>
            <p style={{color:"red",textAlign:"center",marginLeft:"160px"}}>공급자<br/>(보관용)</p>
            </Row>
            </>); break
        case "zerotax":
          return (
            <>
            <Row style={{textAlign:"center",justifyContent:"center"}}>
            <Title level={2} style={{color:"red",textAlign:"center"}}>영세율 세금계산서</Title>
            <p style={{color:"red",textAlign:"center",marginLeft:"70px"}}>공급자<br/>(보관용)</p>
            </Row>
            </>); break
        case "freetax":
          return (
            <>
            <Row style={{textAlign:"center",justifyContent:"center"}}>
            <Title level={2} style={{color:"red",textAlign:"center"}}>계산서</Title>
            <p style={{color:"red",textAlign:"center",marginLeft:"214px"}}>공급자<br/>(보관용)</p>
            </Row>
            </>); break
      }
      }
  },
  {
    title: 'choose',
    dataIndex: 'choose',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
          addonBefore="일련번호"
          size="small"
          style={{
          width: '300px',
        }}
      />
        
        </>)}
  }  

]
const Purchasechoosecolum = [
  {
    title: 'label',
    dataIndex: 'label',
    key: 'key',
    width:'550px',
    render:(_,record)=>{
      switch(this.state.inputtaxdocoptions.taxmoney){
        case "tax":
          return (
            <>
            <Row style={{textAlign:"center",justifyContent:"center"}}>
            <Title level={2} style={{color:"blue",textAlign:"center"}}>세금계산서(매입)</Title>
            <p style={{color:"blue",textAlign:"center",marginLeft:"85px"}}>공급자받는자<br/>(보관용)</p>
            </Row>
            </>);break
        case "zerotax":
          return (
            <>
            <Row style={{textAlign:"center",justifyContent:"center"}}>
            <Title level={2} style={{color:"blue",textAlign:"center"}}>영세율 세금계산서(매입)</Title>
            <p style={{color:"blue",textAlign:"center",marginLeft:"2px"}}>공급자받는자<br/>(보관용)</p>
            </Row>
            </>);break
        case "freetax":
          return (
            <>
            <Row style={{textAlign:"center",justifyContent:"center"}}>
            <Title level={2} style={{color:"blue",textAlign:"center"}}>계산서(매입)</Title>
            <p style={{color:"blue",textAlign:"center",marginLeft:"140px"}}>공급자받는자<br/>(보관용)</p>
            </Row>
            </>);break
      }
      }
  },
  {
    title: 'choose',
    dataIndex: 'choose',
    key: 'choose',
    render:(_,record)=>{
      return (
        <>
        <Input
          addonBefore="일련번호"
          size="small"
          style={{
          width: '300px',
        }}
      />
        
        </>)}
  }  

]
const Taxcolumns1 = [
  {
    title: '작성일자',
    dataIndex: 'day',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        type="date"
        onChange={onChangeDate}
        value={this.state.daydata}
        style={{
          width: '115px',
        }}
      />
        </>)}
  },
  {
    title: '공급가액',
    dataIndex: 'total',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        disabled
        value={this.state.totalmoney.supplyprice}
        size="small"
        style={{
          width: '335px',
          color:"black",
        }}
      />
        </>)}
  },{
    title: '세 액',
    dataIndex: 'taxtotal',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        value={this.state.totalmoney.taxamount}
        disabled
        style={{
          width: '335px',
          color:"black",
        }}
      />
        </>)}
  },
]
const Taxcolumns2 = [
  {
    title: '월',
    dataIndex: 'month',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,1)}
        defaultValue={record.month}
        style={{
          width: '30px',
        }}
      />
        </>)}
  },
  {
    title: '일',
    dataIndex: 'day',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,2)}
        defaultValue={record.day}
        style={{
          width: '30px',
        }}
      />
        </>)}
  },{
    title: ()=>{return<>품목
    <Button 
      type="default" 
      shape="circle" 
      onClick={()=>{
        this.getProduct()
        this.setState({productmodaropen:true})}}
      icon={<SearchOutlined />} 
      size="small" 
      style={{marginLeft:"10px",marginTop:"4px"}}
      />
     </>;},
    dataIndex: 'product',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Row gutter={[2, 0]}>
        <Input
        defaultValue={record.product}
        onChange={(event) => onChangeProduct(event, record.key,3)}
        size="small"
        style={{
          width: '120px',
        }}
      />
      
      </Row>

        </>)}
  },{
    title: '규격',
    dataIndex: 'standard',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,4)}
        defaultValue={record.standard}
        style={{
          width: '30px',
        }}
      />
        </>)}
  },{
    title: '수량',
    dataIndex: 'num',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,5)}
        defaultValue={record.num}
        style={{
          width: '40px',
        }}
      />
        </>)}
  },{
    title: '단가',
    dataIndex: 'unitprice',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,6)}
        defaultValue={record.unitprice}
        style={{
          width: '80px',
        }}
      />
        </>)}
  },{
    title: '공급가액',
    dataIndex: 'supplyprice',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,7)}
        defaultValue={record.supplyprice}
        style={{
          width: '110px',
        }}
      />
        </>)}
  }
  ,{
    title: '세액',
    dataIndex: 'taxamount',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,8)}
        defaultValue={record.taxamount}
        style={{
          width: '70px',
          
        }}
      />
        </>)}
  },{
    title: '비고',
    dataIndex: 'etc',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(event) => onChangeProduct(event, record.key,9)}
        defaultValue={record.etc}
        style={{
          width: '100px',
        }}
      />
        </>)}
  },{
    title: ()=>{return <Button
      shape="circle"
      size="small"
      type="default" 
      onClick={producttargetup}
      icon={<PlusOutlined/>}></Button>;},
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Popconfirm 
        title={`${record.product}를 삭제하시겠습니까?`}
        onConfirm={()=>{producttargetdel(record.key)}}
        >
        <Button 
          type="default" 
          shape="circle" 
          icon={<DeleteOutlined />} 
          size="small" />
          </Popconfirm>
        </>)}
  }
  
]
const TaxPurchasecolumns = [
  {
    title: '공급받는자',
    dataIndex: 'brain',
    key: 'brain',
    render:(_,record)=>{
      return (
        <>
        <Space size={[0, 0]} wrap style={{
          borderCollapse:"separate",
        }}>
      <Input
        addonBefore="등록번호"
        value={this.state.brain.brnum}
        onChange={(event) => onChangeBrain(event, 1)}
        size="small"
        style={{
          width: '250px',
          
        }}
      />
      <Input
        addonBefore="종사업장"
        value={this.state.brain.end}
        onChange={(event) => onChangeBrain(event, 10)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="상호"
        value={this.state.brain.title}
        onChange={(event) => onChangeBrain(event, 2)}
        size="small"
        style={{
          width: '250px',
        }}
      />
      <Input
        addonBefore="성명"
        size="small"
        value={this.state.brain.Owner}
        onChange={(event) => onChangeBrain(event, 3)}
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="사업장주소"
        size="small"
        value={this.state.brain.Address}
        onChange={(event) => onChangeBrain(event, 4)}
        style={{
          width: '400px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="업태"
        size="small"
        value={this.state.brain.class1}
        onChange={(event) => onChangeBrain(event, 8)}
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="종목"
        size="small"
        value={this.state.brain.class2}
        onChange={(event) => onChangeBrain(event, 9)}
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="담당자"
        size="small"
        value={this.state.brain.Manager}
        onChange={(event) => onChangeBrain(event, 5)}
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="연락처"
        size="small"
        value={this.state.brain.Telnum}
        onChange={(event) => onChangeBrain(event, 6)}
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="이메일"
        size="small"
        value={this.state.brain.Email}
        onChange={(event) => onChangeBrain(event, 7)}
        style={{
          width: '400px',
        }}
      />
      </Space>
      </>)
      }
  },
  {
  title: ()=>{return <>
      <Space size={[0, 8]} wrap>
      <p style={{marginBottom:"0px",paddingRight:"10px",fontSize:"12px"}}>공급자</p>
      <Button
          shape="circle"
          onClick={()=>{
            this.getCompany()
            this.setState({modaropen:true})}}
          size="small"
          type="default" 
          icon={<SearchOutlined/>}></Button></Space></>;},
    dataIndex: 'company',
    key: 'company',
    render:(_,record)=>{
      return (
        <>
        <Space size={[0, 0]} wrap style={{
          borderCollapse:"separate",
        }}>
      <Input
        addonBefore="등록번호"
        value={this.state.getcompany.brnum}
        onChange={(event) => onChangeCompany(event, 1)}
        size="small"
        style={{
          width: '250px',
          
        }}
      />
      <Input
        addonBefore="종사업장"
        value={this.state.getcompany.end}
        onChange={(event) => onChangeCompany(event, 10)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="상호"
        value={this.state.getcompany.title}
        onChange={(event) => onChangeCompany(event, 2)}
        size="small"
        style={{
          width: '250px',
        }}
      />
      <Input
        addonBefore="성명"
        value={this.state.getcompany.Owner}
        onChange={(event) => onChangeCompany(event, 3)}
        size="small"
        style={{
          width: '150px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="사업장주소"
        size="small"
        value={this.state.getcompany.Address}
        onChange={(event) => onChangeCompany(event, 4)}
        style={{
          width: '400px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="업태"
        size="small"
        value={this.state.getcompany.class1}
        onChange={(event) => onChangeCompany(event, 8)}
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="종목"
        size="small"
        value={this.state.getcompany.class2}
        onChange={(event) => onChangeCompany(event, 9)}
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="담당자"
        size="small"
        value={this.state.getcompany.Manager}
        onChange={(event) => onChangeCompany(event, 5)}
        style={{
          width: '200px',
        }}
      />
      <Input
        addonBefore="연락처"
        size="small"
        value={this.state.getcompany.Telnum}
        onChange={(event) => onChangeCompany(event, 6)}
        style={{
          width: '200px',
        }}
      />
      </Space>
      <Space size={[0, 8]} wrap>
      <Input
        addonBefore="이메일"
        size="small"
        value={this.state.getcompany.Email}
        onChange={(event) => onChangeCompany(event, 7)}
        style={{
          width: '400px',
        }}
      />
      </Space>
      </>)
      }
  },
];
const totalcolum = [
  {
    title: ()=>{return <Title level={5} style={{margin:"0",padding:"0"}}>합계금액</Title>;},
    dataIndex: 'total',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        value={this.state.totalmoney.total}
        disabled
        style={{
          width: '210px',
          color:"black",
        }}
      />
        </>)}
  },
  {
    title: "현금",
    dataIndex: 'totalbalance',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        onChange={(e)=>{onChangeMoney(e,1)}}
        size="small"
        style={{
          width: '135px',
        }}
      />
        
        </>)}
  },{
    title: "수표",
    dataIndex: 'depositamount',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        onChange={(e)=>{onChangeMoney(e,2)}}
        size="small"
        style={{
          width: '135px',
        }}
      />
        
        </>)}
  },{
    title: "어음",
    dataIndex: 'currentbalance',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        onChange={(e)=>{onChangeMoney(e,3)}}
        size="small"
        style={{
          width: '135px',
        }}
      />
        
        </>)}
  },{
    title: "외상미수금",
    dataIndex: 'currentbalance',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        <Input
        size="small"
        onChange={(e)=>{onChangeMoney(e,4)}}
        style={{
          width: '135px',
        }}
      />
        
        </>)}
  },
]
const choosecolum2 = [
  {
    title: 'label',
    dataIndex: 'label',
    key: 'key',
    width:'132px',
    render:(_,record)=>{
        return (
          <>
          <Title level={5} style={{textAlign:"center"}}>합계금액입력</Title>
          </>)
          }
  },
  {
    title: 'choose',
    dataIndex: 'choose',
    key: 'key',
    render:(_,record)=>{
      return (
        <>
        {/* <Radio.Group >
          <Radio value={1}>직접입력</Radio>
          <Radio value={2}>단가 부가세포함</Radio>
          
        </Radio.Group> */}
        <Button
        // shape="circle"
        size="small"
        type="dashed" 
        onClick={() => this.setState({moneymodar:true})}
        icon={<PlusOutlined/>}>합계금액입력</Button>
        
        </>)}
  }  
]
    
    return (
      <>
      
      <center>
        <Tabs className="tabs-sliding" defaultActiveKey="1" onChange={callback} style={{}} centered>
          <TabPane tab="전자세금계산서" key="1" >
            <Row gutter={[24, 24]}>
              <Col span={24} lg={24} id="my-scroll-layout" >
                <Card
                  id="profile"
                  className="card-profile-head"
                  bordered={false}
                  style={{
                    textAlign:"left",
                    // overflow:'scroll',
                    maxWidth:"890px"
                  }}
                  bodyStyle={{ display: "none" }}
                  title={
                    <Row
                      justify="space-between"
                      align="middle"
                      gutter={[24, 0]}
                    >
                      <Col span={24} md={12} className="col-info">
                        <Avatar.Group>
                          {/* <Avatar size={74} shape="square" src={profilavatar} /> */}

                          <div className="avatar-info">
                            <h4 className="font-semibold m-0">접속사용자 정보</h4>
                            <p>Email : {this.state.loguser.useremail}<br/> 사용자이름 : {this.state.loguser.username}</p>
                          </div>
                        </Avatar.Group>
                      </Col>
                      <Col
                        span={24}
                        md={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <small className="font-regular mr-5">
                          Switch to invisible
                        </small>
                        <Switch defaultChecked onChange={onChange} /> */}
                      </Col>
                    </Row>
                  }
                ></Card>
                <Card
                  id="change-password"
                  className="mb-24 header-solid"
                  Width= '860px'
                  bordered={false}
                  style={{
                    // overflow:'scroll',
                    maxWidth:"890px"
                  }}
                  title={[
                    <>
                    <Row >
                    <h5 className="mb-0" key={0}>
                      세금계산서 및 매입세금계산서
                    </h5>
                    </Row>
                    </>,
                  ]}
                  
                >
                  <Modal
                    title={[<><Title level={3}>
                      사업장찾기
                    </Title>
                    {this.state.taxcategory===1?<p style={{color:"GrayText", fontSize:"12px"}}>
                        문서형태  :  세금계산서(매입)&nbsp;
                        {this.state.inputtaxdocoptions.taxmoney==="tax"? "과세(10%)":""}
                         {this.state.inputtaxdocoptions.taxmoney==="zerotax"? "영세(0%)":""}
                         {this.state.inputtaxdocoptions.taxmoney==="freetax"? "면세(세액없음)":""}
                         &nbsp;
                         {this.state.inputtaxdocoptions.doccategory==="receipt"? "영수건":""}
                         {this.state.inputtaxdocoptions.doccategory==="Bill"? "청구건":""}
                         {this.state.inputtaxdocoptions.doccategory==="nooption"? "없음":""}
                      </p>:<p style={{color:"GrayText",fontSize:"12px"}}>
                        문서형태  :  세금계산서&nbsp;
                        {this.state.taxdocoptions.taxmoney==="tax"? "과세(10%)":""}
                         {this.state.taxdocoptions.taxmoney==="zerotax"? "영세(0%)":""}
                         {this.state.taxdocoptions.taxmoney==="freetax"? "면세(세액없음)":""}
                         &nbsp;
                         {this.state.taxdocoptions.doccategory==="receipt"? "영수건":""}
                         {this.state.taxdocoptions.doccategory==="Bill"? "청구건":""}
                         {this.state.taxdocoptions.doccategory==="nooption"? "없음":""}
                      </p>}
                    </>]}
                    centered
                    className="companymodar"
                    visible={this.state.modaropen}
                    onOk={() => this.setState({modaropen:false})}
                    onCancel={() => this.setState({modaropen:false})}
                  > <AutoComplete
                      dropdownMatchSelectWidth={252}
                      
                      style={{
                        width: 300,
                      }}
                      // dataSource={this.state.companyfilter}
                      options={this.state.option}
                      onSelect={onSelect}
                      onSearch={handleSearch}
                    >
                      <Input.Search size="large" placeholder="input here" enterButton />
                    </AutoComplete>
                  </Modal>
                  <Modal
                    className="productmodar"
                    title={[<><Title level={3}>
                      품목찾기
                    </Title>
                    {this.state.taxcategory===1?<p style={{color:"GrayText", fontSize:"12px"}}>
                        문서형태  :  세금계산서(매입)&nbsp;
                        {this.state.inputtaxdocoptions.taxmoney==="tax"? "과세(10%)":""}
                         {this.state.inputtaxdocoptions.taxmoney==="zerotax"? "영세(0%)":""}
                         {this.state.inputtaxdocoptions.taxmoney==="freetax"? "면세(세액없음)":""}
                         &nbsp;
                         {this.state.inputtaxdocoptions.doccategory==="receipt"? "영수건":""}
                         {this.state.inputtaxdocoptions.doccategory==="Bill"? "청구건":""}
                         {this.state.inputtaxdocoptions.doccategory==="nooption"? "없음":""}

                      </p>:<p style={{color:"GrayText",fontSize:"12px"}}>
                        문서형태  :  세금계산서&nbsp;
                        {this.state.taxdocoptions.taxmoney==="tax"? "과세(10%)":""}
                         {this.state.taxdocoptions.taxmoney==="zerotax"? "영세(0%)":""}
                         {this.state.taxdocoptions.taxmoney==="freetax"? "면세(세액없음)":""}
                         &nbsp;
                         {this.state.taxdocoptions.doccategory==="receipt"? "영수건":""}
                         {this.state.taxdocoptions.doccategory==="Bill"? "청구건":""}
                         {this.state.taxdocoptions.doccategory==="nooption"? "없음":""}
                      </p>}
                    </>]}
                    centered
                    visible={this.state.productmodaropen}
                    onOk={() => SelectProductAdd()}
                    onCancel={() => this.setState({productmodaropen:false})}
                  > 
                  
                  <Row>
                  <AutoComplete
                      dropdownMatchSelectWidth={252}
                      
                      style={{
                        width: 300,
                      }}
                      // dataSource={this.state.companyfilter}
                      options={this.state.optionproduct}
                      onSelect={onSelectProduct}
                      onSearch={handleSearchProduct}
                    >
                      <Input.Search size="large" placeholder="input here" enterButton />
                    </AutoComplete>
                    <InputNumber size="large" min={1} max={100000} onChange={onChangevolume}
                    value={this.state.volume} style={{marginLeft:"10px"}}/>
                    </Row>
                    
                  </Modal>
                  <Modal
                    className=""
                    title={[<><Title level={3}>
                      합계금액으로 입력
                    </Title>
                    {this.state.taxcategory===1?<p style={{color:"GrayText", fontSize:"12px"}}>
                        문서형태  :  세금계산서(매입)&nbsp;
                        {this.state.inputtaxdocoptions.taxmoney==="tax"? "과세(10%)":""}
                         {this.state.inputtaxdocoptions.taxmoney==="zerotax"? "영세(0%)":""}
                         {this.state.inputtaxdocoptions.taxmoney==="freetax"? "면세(세액없음)":""}
                         &nbsp;
                         {this.state.inputtaxdocoptions.doccategory==="receipt"? "영수건":""}
                         {this.state.inputtaxdocoptions.doccategory==="Bill"? "청구건":""}
                         {this.state.inputtaxdocoptions.doccategory==="nooption"? "없음":""}

                      </p>:<p style={{color:"GrayText",fontSize:"12px"}}>
                        문서형태  :  세금계산서&nbsp;
                        {this.state.taxdocoptions.taxmoney==="tax"? "과세(10%)":""}
                         {this.state.taxdocoptions.taxmoney==="zerotax"? "영세(0%)":""}
                         {this.state.taxdocoptions.taxmoney==="freetax"? "면세(세액없음)":""}
                         &nbsp;
                         {this.state.taxdocoptions.doccategory==="receipt"? "영수건":""}
                         {this.state.taxdocoptions.doccategory==="Bill"? "청구건":""}
                         {this.state.taxdocoptions.doccategory==="nooption"? "없음":""}
                      </p>}
                    </>]}
                    centered
                    visible={this.state.moneymodar}
                    onOk={() => inputotalAdd()}
                    onCancel={() => this.setState({moneymodar:false})}
                  > 
                  
                  <Input
                  type="number"
                  onChange={onChangeinputotal}
                  />
                  <Space style={{marginTop:"10px"}} >
                  <Input
                  style={{color:"black"}}
                    value={this.state.inputtotalmoney.unitprice}
                    disabled
                  />
                  <Input
                  style={{color:"black"}}
                  value={this.state.inputtotalmoney.taxamount}
                  disabled
                  />
                  </Space>
                  </Modal>
                  
                  <Carousel 
                  effect="fade"
                  touchMove={false}
                  afterChange={onChangeCarousel}>
                    <div>
                  <Form
                    name="basic"
                    style={contentStyle}
                    // style={{
                    //   width: '845px',
                    // }}
                    className="taxtable"
                    // name="dynamic_form_nest_item"
                  >
                    <Space size={[17, 8]} wrap >
                  <Form.Item
                  label={ <p style={{fontSize:"13px",verticalAlign:"center",marginTop:"18px",marginLeft:"10px",}}>과세형태</p> }
                  >
                  <Radio.Group value={this.state.taxdocoptions.taxmoney} buttonStyle="solid" size="small" 
                  onChange={(e)=>onChangetaxdoc(e,1)}>
                    <Radio.Button size="small" value="tax" style={{fontSize:"12px"}} >과세(10%)</Radio.Button>
                    <Radio.Button size="small" value="zerotax" style={{fontSize:"12px"}}>영세(0%)</Radio.Button>
                    <Radio.Button size="small" value="freetax" style={{fontSize:"12px"}}>면세(세액없음)</Radio.Button>
                  </Radio.Group>
                  </Form.Item>
                  <Form.Item
                  label={ <p style={{fontSize:"13px",verticalAlign:"center",marginTop:"18px"}}>거래처유형</p> }
                  >
                  <Radio.Group value={this.state.taxdocoptions.compcategory} buttonStyle="solid" size="small" 
                  onChange={(e)=>onChangetaxdoc(e,2)}>
                    <Radio.Button size="small" value="buisness" style={{fontSize:"12px"}}>사업자</Radio.Button>
                    <Radio.Button size="small" value="personal" style={{fontSize:"12px"}}>개인</Radio.Button>
                    <Radio.Button size="small" value="foreigner" style={{fontSize:"12px"}}>외국인</Radio.Button>
                  </Radio.Group>
                  </Form.Item>
                  <Form.Item
                  label={ <p style={{fontSize:"13px",verticalAlign:"center",marginTop:"18px"}}>문서유형</p> }
                  >
                  <Radio.Group value={this.state.taxdocoptions.doccategory} buttonStyle="solid" size="small" 
                  onChange={(e)=>onChangetaxdoc(e,3)}>
                    <Radio.Button size="small" value="receipt" style={{fontSize:"12px"}}>영수</Radio.Button>
                    <Radio.Button size="small" value="Bill" style={{fontSize:"12px"}}>청구</Radio.Button>
                    <Radio.Button size="small" value="nooption" style={{fontSize:"12px"}}>없음</Radio.Button>
                  </Radio.Group>
                  </Form.Item>

                  </Space>
                  
                  <Table
                    style={{maxWidth: "850px",}}
                    initialValues="inline"
                    size="small"
                    showHeader={false}
                    pagination={false}
                    columns={choosecolum}
                    dataSource={chooseSource}
                    >
                    </Table>
                    <Table
                    bordered
                    style={{maxWidth: "850px",}}
                    initialValues="inline"
                    size="small"
                    pagination={false}
                    columns={Taxcolumns}
                    dataSource={dataSource}
                    >
                    </Table>
                    <Table
                    
                    bordered
                    style={{maxWidth: "850px",}}
                    initialValues="inline"
                    size="small"
                    pagination={false
                    }
                    columns={Taxcolumns1}
                    dataSource={dataSource1}
                    >
                    </Table>
                    <Table
                    
                    bordered
                    style={{maxWidth: "850px",}}
                    initialValues="inline"
                    size="small"
                    showHeader={false}
                    pagination={false}
                    columns={choosecolum2}
                    dataSource={chooseSource}
                    >
                    </Table>
                    <Table
                    
                    bordered
                    style={{maxWidth: "850px",}}
                    initialValues="inline"
                    size="small"
                    pagination={false
                    }
                    columns={Taxcolumns2}
                    dataSource={this.state.productlist}
                    >
                    </Table>
                    <Table
                    
                    bordered
                    style={{maxWidth: "850px",}}
                    initialValues="inline"
                    size="small"
                    pagination={false
                    }
                    columns={totalcolum}
                    dataSource={totaldataSource}
                    >
                    </Table>
                    
                    <Col span={15}>
                      
                    </Col>
                    <Col span={9}>
                    </Col>
                    <div ref={ (ref) => this.etcref=ref }>
                    <Form.List 
                    
                    name="etc" style={{marginBottom:"0px"}}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, index }) => (
                            <Space
                              key={key}
                              style={{
                                display: 'flex',
                                marginBottom: 0,
                              }}
                              align="baseline"
                            >
                                <Input 
                                addonBefore="비고"
                                size="small"
                                onChange={(value)=>onChangeDocetc(value,key)}
                                style={{
                                  width: '810px',
                                }}
                                />
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button 
                            type="dashed" 
                            onClick={() => add()} 
                            block icon={<PlusOutlined 
                            />}>
                              비고추가
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    </div>
                    <Button
                    type="primary"
                    danger
                    onClick={TaxSendcall}
                    style={{marginBottom:"10px",fontSize:"18px"}}
                    >제 출</Button>
                  </Form>
                  
                  <div style={{marginBottom:"60px"}}></div>
                    </div>
                    <div>
                    <Form
                      style={contentStyle}
                      // style={{
                      //   width: '845px',
                      // }}
                      className="taxtable2"
                      // name="dynamic_form_nest_item"
                      >
                        <Space size={[17, 8]} wrap >
                      <Form.Item
                      label={ <p style={{fontSize:"13px",verticalAlign:"center",marginTop:"18px",marginLeft:"10px"}}>과세형태</p> }
                      >
                      <Radio.Group value={this.state.inputtaxdocoptions.taxmoney} buttonStyle="solid" size="small" 
                      onChange={(e)=>onChangeinputtaxdoc(e,1)}>
                        <Radio.Button size="small" value="tax" style={{fontSize:"12px"}} >과세(10%)</Radio.Button>
                        <Radio.Button size="small" value="zerotax" style={{fontSize:"12px"}}>영세(0%)</Radio.Button>
                        <Radio.Button size="small" value="freetax" style={{fontSize:"12px"}}>면세(세액없음)</Radio.Button>
                      </Radio.Group>
                      </Form.Item>
                      
                      <Form.Item
                      label={ <p style={{fontSize:"13px",verticalAlign:"center",marginTop:"18px"}}>문서유형</p> }
                      onChange={(e)=>onChangeinputtaxdoc(e,2)}>
                      <Radio.Group value={this.state.inputtaxdocoptions.doccategory} buttonStyle="solid" size="small" >
                        <Radio.Button size="small" value="receipt" style={{fontSize:"12px"}}>영수</Radio.Button>
                        <Radio.Button size="small" value="Bill" style={{fontSize:"12px"}}>청구</Radio.Button>
                        <Radio.Button size="small" value="nooption" style={{fontSize:"12px"}}>없음</Radio.Button>
                      </Radio.Group>
                      </Form.Item>

                      </Space>
                      <Table
                        style={{maxWidth: "850px",}}
                        initialValues="inline"
                        size="small"
                        showHeader={false}
                        pagination={false}
                        columns={Purchasechoosecolum}
                        dataSource={chooseSource}
                        >
                        </Table>
                        <Table
                        bordered
                        style={{maxWidth: "850px",}}
                        initialValues="inline"
                        size="small"
                        pagination={false}
                        columns={TaxPurchasecolumns}
                        dataSource={dataSource}
                        >
                        </Table>
                        <Table
                        
                        bordered
                        style={{maxWidth: "850px",}}
                        initialValues="inline"
                        size="small"
                        pagination={false
                        }
                        columns={Taxcolumns1}
                        dataSource={dataSource1}
                        >
                        </Table>
                        <Table
                        
                        bordered
                        style={{maxWidth: "850px",}}
                        initialValues="inline"
                        size="small"
                        showHeader={false}
                        pagination={false}
                        columns={choosecolum2}
                        dataSource={chooseSource}
                        >
                        </Table>
                        <Table
                        
                        bordered
                        style={{maxWidth: "850px",}}
                        initialValues="inline"
                        size="small"
                        pagination={false
                        }
                        columns={Taxcolumns2}
                        dataSource={this.state.productlist}
                        >
                        </Table>
                        <Table
                        
                        bordered
                        style={{maxWidth: "850px",}}
                        initialValues="inline"
                        size="small"
                        pagination={false
                        }
                        columns={totalcolum}
                        dataSource={totaldataSource}
                        >
                        </Table>
                        <Col span={15}>
                          
                        </Col>
                        <Col span={9}>
                        </Col>
                        <Form.List name="etc" style={{marginBottom:"0px"}}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: 'flex',
                                    marginBottom: 0,
                                  }}
                                  align="baseline"
                                >
                                    <Input 
                                    addonBefore="비고"
                                    size="small"
                                    style={{
                                      width: '810px',
                                    }}
                                    />
                                  {/* </Form.Item> */}
                                  
                                  <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                              ))}
                              <Form.Item>
                                <Button 
                                type="dashed" 
                                onClick={() => add()} 
                                block icon={<PlusOutlined 
                                />}>
                                  비고추가
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        <Button
                          type="primary"
                          onClick={TaxSendcall}

                          style={{marginBottom:"10px",fontSize:"18px"}}
                          >제 출</Button>
                        </Form>
                      
                  <div style={{marginBottom:"60px"}}></div>
                  
                    </div>
                  </Carousel>
                  
                  
                  
                  
                </Card>
                <Card
                  id="2fa"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <div className="ant-flex-setting" key={0}>
                      <h5 className="mb-0 font-semibold">
                        Two-factor authentication
                      </h5>
                    </div>,
                  ]}
                >
                  
                </Card>
                <Card
                  id="accounts"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <React.Fragment key={0}>
                      <h5 className="font-semibold">Accounts</h5>
                      <p className="font-regular">
                        Here you can setup and manage your integration settings.
                      </p>
                    </React.Fragment>,
                  ]}
                >
                 
                </Card>
                <Card
                  id="notifications"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <React.Fragment key={0}>
                      <h5 className="font-semibold">Notifications</h5>
                      <p className="font-regular">
                        Choose how you receive notifications. These notification
                        settings apply to the things you’re watching.
                      </p>
                    </React.Fragment>,
                  ]}
                >
                  
                </Card>
                <Card
                  id="session"
                  className="mb-24 header-solid list-settings-sessions"
                  bordered={false}
                  title={[
                    <React.Fragment key={0}>
                      <h5 className="font-semibold">Sessions</h5>
                      <p className="font-regular">
                        This is a list of devices that have logged into your
                        account. Remove those that you do not recognize.
                      </p>
                    </React.Fragment>,
                  ]}
                >
                </Card>
                <Card
                  id="delete-account"
                  className="header-solid mb-24"
                  bordered={false}
                  title={[
                    <React.Fragment key={0}>
                      <h5 className="font-semibold">Delete Account</h5>
                      <p className="font-regular">
                        Once you delete your account, there is no going back.
                        Please be certain.
                      </p>
                    </React.Fragment>,
                  ]}
                >
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="전자명세서" key="2">
            <Card
              style={{
                height: "300px",
                padding: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href="#" className="text-center text-muted font-bold">
                <h6 className="font-semibold text-muted">Empty Tab</h6>
              </a>
            </Card>
          </TabPane>
          <TabPane tab="현금영수증" key="3">
            <Card
              style={{
                height: "300px",
                padding: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href="#" className="text-center text-muted font-bold">
                <h6 className="font-semibold text-muted">Empty Tab</h6>
              </a>
            </Card>
          </TabPane>
        </Tabs>
        </center>
      </>
    );
  }
}
