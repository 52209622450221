/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Tabs,
  Card,
  Row,
  Col,
  Anchor,
  Avatar,
  Select,
  Affix,
  Descriptions,
  Timeline,
} from "antd";
import {
  AppstoreFilled,
  SnippetsFilled,
  GithubOutlined,
  ControlOutlined,
  IeOutlined,
  ProfileOutlined,
  AliwangwangOutlined,
} from "@ant-design/icons";
import profilavatar from "../../images/adventurer.svg";

const { TabPane } = Tabs;
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
function callback(key) {
  console.log(key);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

const { Link } = Anchor;
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
export default class Settings extends Component {
  render() {
    return (
      <>
        {/* <Tabs className="tabs-sliding" defaultActiveKey="1" onChange={callback}>
          <TabPane tab="INFO" key="1">
            <Row gutter={[24, 24]}>
              <Col lg={6} span={24}>
                <Affix offsetTop={10}>
                  <Card className="header-solid" bordered={false}>
                    <Anchor className="fixed" affix={false}>
                      <Link
                        href="#profile"
                        title={[
                          <div className="ant-anchor-link-menu" key={0}>
                            <AppstoreFilled className="text-lg text-gray-6" />
                            <h4>주인장</h4>
                          </div>,
                        ]}
                      />

                      <Link
                        href="#basic-info"
                        title={[
                          <div className="ant-anchor-link-menu" key={0}>
                            <SnippetsFilled className="text-lg text-gray-6" />
                            <h4>홈페이지 소개</h4>
                          </div>,
                        ]}
                      />
                      <Link
                        href="#menu-info"
                        title={[
                          <div className="ant-anchor-link-menu" key={0}>
                            <ProfileOutlined className="text-lg text-gray-6" />
                            <h4>메뉴구성</h4>
                          </div>,
                        ]}
                      />
                      <Link
                        href="#tech-info"
                        title={[
                          <div className="ant-anchor-link-menu" key={0}>
                            <ControlOutlined className="text-lg text-gray-6" />
                            <h4>기술구성</h4>
                          </div>,
                        ]}
                      />
                      <Link
                        href="#sns"
                        title={[
                          <div className="ant-anchor-link-menu" key={0}>
                            <AliwangwangOutlined className="text-lg text-gray-6" />
                            <h4>SNS</h4>
                          </div>,
                        ]}
                      />
                    </Anchor>
                  </Card>
                </Affix>
              </Col>

              <Col span={24} lg={18} id="my-scroll-layout">
                <Card
                  id="profile"
                  className="card-profile-head"
                  bordered={false}
                  bodyStyle={{ display: "none" }}
                  title={
                    <Row
                      justify="space-between"
                      align="middle"
                      gutter={[24, 0]}
                    >
                      <Col span={24} md={12} className="col-info">
                        <Avatar.Group>
                          <Avatar size={74} shape="square" src={profilavatar} />

                          <div className="avatar-info">
                            <h4 className="font-semibold m-0">yum..</h4>
                            <p>안농</p>
                          </div>
                        </Avatar.Group>
                      </Col>
                      
                    </Row>
                  }
                ></Card>

                <Card
                  id="basic-info"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <h5 className="mb-0" key={0}>
                      홈페이지 소개
                    </h5>,
                  ]}
                >
                  <p>
                      테스트 및 개발용도로 사용중인 홈페이지입니다.<br/><br/>
                      새로운 프레임워크나 라이브러리를 적용하면서 서버를 닫기도 할겁니다.<br/><br/>
                      블로그 또한 여유가되면 하려고 합니다.<br/><br/>
                      "백견이불여일타!!" 내가 직접짜보기도 바쁩니다..<br/><br/>
                      블로그를 쓰게 된다면 초보자중심으로 작성하려합니다<br/><br/>
                      만약 처음 코드를 접하는데 어떤 방향으로 잡아야할지 고민중이시라면<br/><br/>
                      "나는 하드웨어도 관심있고 네트워크도 알고싶어" 하는 분은 파이썬을 추천합니다<br/><br/>
                      파이썬은 라이브러리도 많고 응용프로그램 만들기도 쉽습니다<br/><br/>
                      웹프레임워크는 장고나 플라스크를 찾아보시면 됩니다<br/><br/>
                      "난 웹으로 팔거야 좀더 사람을 많이 뽑는 언어로 하고 싶어" 하는 분은 자바스크립트를 추천합니다<br/><br/>
                      자바스크립트도 노드를 통해 하드웨어에 접근할 수 있긴한데 파이썬만큼 정보나 라이브러리가 많진 않습니다<br/><br/>
                      파이썬은 언어가 알아보기 쉽고 깔끔하고 스텍오버플로우같은 정보공유가 잘되어있어서 접근하기 좋은 반면<br/><br/>
                      자바스크립트는 네트워크를 통해 발전한 언어라서인지 빨리 수용하고 변하다보니 버전별 작성방법도 생각해야해서 어지러울수있음<br/><br/>
                      대신 웹쪽엔 필수, 특히 프론트엔드쪽에서 필수라 사람도 많이 구하는편입니다<br/><br/>

                      {" "}
                    </p>
                  
                </Card>
                <Card
                  id="menu-info"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <h5 className="mb-0" key={0}>
                      메뉴구성
                    </h5>,
                  ]}
                >
                  <Timeline>
                  <Timeline.Item color="green">
                    HOMEPAGE
                    <small>Home, Info, User, APIS</small>
                    <p>
                      메인페이지, 사이트소개, 사용자관리와 홈페이를 구성하는 API들을 설명하는 메뉴로 구성되어 있습니다.{" "}
                    </p>
                  </Timeline.Item>
                  <Timeline.Item color="red">
                    SERVER
                    <small>Dashboard(web,H/W)</small>
                    <p>
                      {" "}
                      홈페이지를 구동하는 서버의 상태를 볼수있습니다.<br/>
                      NGINX, 네트워크, 하드웨어의 상태
                      {" "}
                    </p>
                  </Timeline.Item>
                  <Timeline.Item>
                    BLOG
                    <small>각언어별 자료 및 사용법</small>
                    <p>
                      {" "}
                      언어별로 추천하는 사이트나 서적을 소개하고 라이브러리 사용법이나 원리를 설명해두었습니다.<br/>
                      안쓸확률 99.80%
                      {" "}
                    </p>
                  </Timeline.Item>
                  <Timeline.Item color="warning">
                    WORK
                    <small>관리자 전용메뉴입니다 (개인개발용)</small>
                    <p>
                      {" "}
                      개발목적으로 사용하는 메뉴입니다.{" "}
                    </p>
                  </Timeline.Item>
                </Timeline>
                  
                </Card>
                <Card
                  id="tech-info"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <div className="ant-flex-setting" key={0}>
                      <h5 className="mb-0 font-semibold">
                        기술구성
                      </h5>
                    </div>,
                  ]}
                >
                  <h4 className="mb-0 font-semibold">
                        FRONT_END
                      </h4>
                    <Descriptions>
                      <Descriptions.Item label="React" span={3}>
                      웹페이지를 앱처럼 부드럽게 작동하게 해줍니다. React Dom 덕분에 페이지 변환이 효율적이고 서버부담이 줄었습니다
                      </Descriptions.Item>
                      <Descriptions.Item label="React ANT Design" span={3}>
                      부트스트랩과 같은 컴퍼넌트 라이브러리입니다 비슷한 MUI라이브러리도 있는데 ANT코드가 비교적 깔끔합니다
                      </Descriptions.Item>
                    </Descriptions>
                    
                    <h4 className="mb-0 font-semibold">
                        BACK_END
                      </h4>
                      <Descriptions>
                        <Descriptions.Item label="Ubuntu" span={3}>
                        윈도우가 구성하기 쉽고 편한면이 있지만.. 편하다는건! 그만큼 소스를 잡아먹는다는것! 오픈소스이고 보안면이나 안정성때문에 대부분 리눅스서버를 사용합니다<br/>
                        일부 대기업에서 윈도우서버를 사용하기도 하는데 그건 운영적인면에서 사용하는 경우도 있습니다(리눅스관리할 인력문제)<br/>
                        CentOS등 다양한 리눅스서버가 있습니다
                        </Descriptions.Item>
                        <Descriptions.Item label="Nginx" span={3}>
                        아파치도 써보았으니 다소 무거운 느낌.. 아파치는 안에 구성된 기능을 활성화하는 방식이라 무거운반면 엔진엑스는 추가하는 방식이고 속도가 빠릅니다
                        </Descriptions.Item>
                        <Descriptions.Item label="Prometheus" span={3}>
                        서버의 하드웨어데이터를 수집해서 특정포트로 뿌려줍니다
                        </Descriptions.Item>
                        <Descriptions.Item label="Grafana" span={3}>
                        수집한 프로메테우스 및 엔진엑스데이터를 그래프로 만들어주고 url이나 ip를 통해 공유합니다
                        </Descriptions.Item>
                        <Descriptions.Item label="Shodan" span={3}>
                        보안을 위한 모의해킹사이트 라이브러리입니다 nginx를 통해 ip를 수집하고 shodan으로 위치나 관련된 서버목록을 조회합니다. 네트워크구성(VPN등)에 따라 다를 수 있습니다
                        </Descriptions.Item>
                        <Descriptions.Item label="Forever(node)" span={3}>
                        node파일을 백그라운드에서 돌려주는 노드라이브러리입니다 노드몬같이 파일변화를 감지해서 자동적용합니다
                        </Descriptions.Item>
                        <Descriptions.Item label="sequelize(node)" span={3}>
                        DB와 Express를 따로따로 구축하면 번거롭고 어지럽습니다 Express단에서 노드로 편하게 구축하기 좋습니다(ORM)
                        </Descriptions.Item>
                        <Descriptions.Item label="Express(node)" span={3}>
                        장고와 비교했을때 확실히 빠릅니다..(논블로킹) 장고는 다 구성되어있는 기능을 익혀야해서 첫장벽은 높고 익숙하면 쉬운 반면 익스프레스는 하나하나 구성하는 구조<br/>
                        리액트 템플릿에 필요한 데이터를 API로 제공합니다
                        </Descriptions.Item>
                        <Descriptions.Item label="PostgreSQL" span={3}>
                        Postgresql은 update문이 실행시 삭제후 입력을 실행하는 방식이라 수정하는 작업이 많은 데이터엔 부적합합니다 반면 insert와 delete가 빠른 SQL입니다<br/>
                        보통 전반적으로 고른 mySQL이 대중적임
                        </Descriptions.Item>
                    </Descriptions>
                    <h4 className="mb-0 font-semibold">
                        NETWORK
                      </h4>
                      <Descriptions>
                        <Descriptions.Item label="기타통신" span={3}>
                          가까운 지역의 사용자 동선과 응용프로그램접근을 생각해서 로드벨런스, CDN은 구성하지 않았습니다
                          대신 NGINX에서 캐시메모리를 확보해뒀습니다

                        </Descriptions.Item>
                      </Descriptions>
                    
                </Card>
                <Card
                  id="sns"
                  className="mb-24 header-solid"
                  bordered={false}
                  title={[
                    <React.Fragment key={0}>
                      <h5 className="font-semibold">SNS</h5>
                      <p className="font-regular">
                        SNS를 하지않습니다..<br/>
                        깃허브도 비공개 레퍼토리를 사용하기에 코드 공유가 안되어있습니다.<br/>
                        아래 메일주소와 게더타운인스턴스주소와 깃허브프로필입니다
                      </p>
                      <hr className="gradient-line" />
                      <Descriptions title="Link">
                        <Descriptions.Item label="Email" span={3}>
                          yum114@ityumhouse.com
                        </Descriptions.Item>
                        <Descriptions.Item label="Social" span={3}>
                          <a href="https://github.com/yumzi114" className="mx-5 px-5" target="blank">
                            {<GithubOutlined style={{ color: "#344e86" }}/>}
                          </a>
                        </Descriptions.Item>
                        <Descriptions.Item label="GatherTown" span={3}>
                          <a href="https://app.gather.town/app/ElEDI9oPNyKCmZih/mango" className="mx-5 px-5" target="blank">
                            {<IeOutlined style={{ color: "#344e86" }}/>}
                          </a>
                        </Descriptions.Item>
                      </Descriptions>
                    </React.Fragment>,
                  ]}
                >
                </Card>
                
                
              </Col>
            </Row>
          </TabPane>
          
        </Tabs> */}
      </>
    );
  }
}
