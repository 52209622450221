import React, { Component } from "react";
import {
  Steps,
  Card,
  Row,
  Col,
  Button,
  Table,
  Form,
  Input,
  message,
  Result,
  Select,

} from "antd";
import { FormOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons";
import axios from "axios";

const token = localStorage.getItem('jwt') ||null
const { Step } = Steps;



export default class UserList extends Component {
  state = {
    isLoged:false,
    selrow:[],
    selid:false,
    seltitle:false,
    seldegree:false,
    selemail:false,
    selname:false,
    selpermi:"public",
    degree:[],
    target:false,
    current: 0,
    upmode:false,
    userlist:[],
    Filterlist:[],
    editingKey:'',
  };
  getdegree = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/auths/degree')
      .then((res)=>{
        let newdegree = [...this.state.degree];
        let newFilterlist = [...this.state.Filterlist];
        newFilterlist=[]
        newdegree=[];
        res.data.map((item)=>{
          newdegree.push({
            key: `${item.id}`,
            title: `${item.title}`,
            degree: `${item.degree}`,
            id: `${item.id}`,
          })
          newFilterlist.push({
            key: item.id,
            text: `${item.title}`,
            value: `${item.title}`,
          })
          return (newdegree,newFilterlist)
        })
        this.setState({degree:newdegree,Filterlist:newFilterlist,isLoged:true})
      })
      .catch((err) => {
        // if (err.response.status===401){
        //   window.location = "/web/sign-in";
        // }
        console.log(err);
      });
    }
  getuserlist = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/auths/list')
      .then((res)=>{
        let newuserlist = [...this.state.userlist];
        newuserlist=[];
        res.data.map((item)=>{
          return newuserlist.push(item)
        })
        this.setState({userlist:newuserlist})
      })
      .catch((err) => {
        console.log(err);
      });
    }

  onChangePage = (current) => {
    // console.log("onChangePage:", current);
    this.setState({ current });
  };
  onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  next = () => {
    this.setState({
      current: this.state.current + 1,
    });
  };

  prev = () => {
    this.setState({
      current: this.state.current - 1,
    });
  };
  componentWillMount(){
    this.getdegree();
    this.getuserlist();
  }
  // componentDidMount() {
    
  // }
  // componentDidUpdate() {
  //   this.getdegree();
  // }
  
  render() {
    if (!token || !this.state.isLoged){
      return (
        <>
          <Result
            status="403"
            title="관리자용"
            subTitle=""
            // extra={<Button type="primary" href="/web/sign-in" size="large">Login</Button>}
          />
        </>
      )
    }
    
    const { Option } = Select;
    const { current} = this.state;
    const colnum = this.state.upmode ? 14:24
    const formcolnum = this.state.upmode ? 10:24
    // 테이블데이터
    
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        // key: 'title',
      },
      {
        title: 'Degree',
        dataIndex: 'degree',
        // key: 'degree',
      },
      {
        title: 'ID',
        dataIndex: 'id',
        // key: 'id',
        align: 'right',
        
      },
    ];
    const userlistcolumns = [
      {
        title: 'Provider',
        dataIndex: 'provider',
        // width: 110,
        filters: [
          {
            text: 'local',
            value: 'local',
          },
          ],
        onFilter: (value, record) => record.provider.indexOf(value) === 0,
        sorter: (a, b) => a.provider.length - b.provider.length,
        sortDirections: ['descend','ascend'],
      },
      {
        title: 'ID',
        children: [
          {
            title: 'Email',
            dataIndex: 'email',
            onFilter: (value, record) => record.email.indexOf(value) === 0,
            sorter: (a, b) => a.email.length - b.email.length,
            sortDirections: ['descend','ascend'],
          },
          {
            title: 'SNSID',
            dataIndex: 'snsId',
            onFilter: (value, record) => record.snsId.indexOf(value) === 0,
            sorter: (a, b) => a.snsId.length - b.snsId.length,
            sortDirections: ['descend','ascend'],
          },
        ]
      },
      
      {
        title: 'Name',
        dataIndex: 'name',
        // width: 140,
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ['descend','ascend'],
      },
      {
        title: 'Permission',
        dataIndex: 'permititle',
        // width: 110,
        onFilter: (value, record) => record.permititle.indexOf(value) === 0,
        filters:this.state.Filterlist
      },

    ];
    
    
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // let newselrow = [...this.state.selrow];
        // newselrow=[];
        // this.setState({selrow:selectedRows})
        this.setState({
          selid:selectedRows[0].key,
          seltitle:selectedRows[0].title,
          seldegree:selectedRows[0].degree,
          
        })
      }}
    const listRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // let newseluser = [...this.state.seluser];
        // newseluser=[];
        // selectedRows.map((item)=>{
        //   return newseluser.push(item)
        // })
        this.setState({
          selid:selectedRows[0].id,
          selemail:selectedRows[0].email,
          selname:selectedRows[0].name,
          selpermi:selectedRows[0].permititle,
        })
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      }}
    const onFinish = async(values) => {
      // console.log(values)
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/auths/degree/add',values)
      .then((res)=>{
        this.getdegree()
        message.success('등록되었습니다');
      })
      .catch((err) => {
        console.log(err);
      });
    }
    const onFinishDel = async() => {
      let data = {id:this.state.selid}
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/auths/degree/del',data)
      .then((res)=>{
        this.getdegree()
        message.success('삭제되었습니다');
      })
      .catch((err) => {
        console.log(err);
      });
    }
    const onFinishUp = async() => {
      let data = {
        id:this.state.selid,
        title:this.state.seltitle,
        degree:this.state.seldegree,
      }
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/auths/degree/up',data)
      .then((res)=>{
        this.getdegree()
        message.success('수정되었습니다');
      })
      .catch((err) => {
        console.log(err);
      });
    }
    const titleset = (e) => {
      e.preventDefault();
      this.setState({ seltitle: e.target.value });
    };
    const degreeset = (e) => {
      e.preventDefault();
      this.setState({ seldegree: e.target.value });
    };
    const emailset = (e) => {
      e.preventDefault();
      this.setState({ selemail: e.target.value });
    };
    const nameset = (e) => {
      e.preventDefault();
      this.setState({ selname: e.target.value });
    };
    const permiset = (value) => {
      this.setState({ selpermi: value });
    };
    // const onFinishUser = (values) => {
    //   console.log('Received values of form: ', values);
    // };
    const userDel = async() => {
      if(this.state.selid){
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post(`/api/auths/del/${this.state.selid}`)
        .then((res)=>{
          this.getuserlist()
          message.success('삭제되었습니다');
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }
    const userUp = async() => {
      if(this.state.selid){
        let data = {
          email:this.state.selemail,
          name:this.state.selname,
          permission:this.state.selpermi,
        }
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post(`/api/auths/up/${this.state.selid}`, data)

        .then((res)=>{
          this.getuserlist()
          message.success('수정되었습니다');
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }
    


    const creatForm =
        <>
          <Col span={24} lg={formcolnum} md={formcolnum}>
          <Form 
          method="post" 
          layout="vertical"
          onFinish={onFinish}
          >
            <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item label="TITLE" name="title" colon={false}>
                <Input placeholder="권한명칭" name="title" value={""}/>
              </Form.Item>
              <Form.Item label="DEGREE" name="degree" colon={false}>
                <Input placeholder="레벨" value={""}/>
              </Form.Item>
              </Col>
              <Col span={12} className="text-right">
                
              </Col>
              <Col span={12} className="text-left">
                <Button 
                type="default" 
                size="small" 
                shape="round" 
                htmlType="submit"
                // icon={<DownloadOutlined />}
                
                className="px-25">
                  등록
                </Button>
              </Col>
            </Row>
            </Form>
          </Col>
          </>
    const updateForm =
    <>
      <Col span={24} lg={formcolnum} md={formcolnum}>
      <Form 
      method="post" 
      layout="vertical"
      onFinish={onFinishUp}
      >
        <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item label="ID" name="id" colon={false} >
            <Input 
            placeholder="id" 
            disabled
            // value={this.state.seltitle} 
            // onChange={titleset}
            name="id"
            defaultValue={this.state.selid}
            key={this.state.selid}
            />
          </Form.Item>
          <Form.Item label="TITLE" name="title" colon={false} >
            <Input 
            placeholder="권한명칭" 
            // value={this.state.seltitle} 
            onChange={titleset}
            name="title"
            defaultValue={this.state.seltitle}
            key={this.state.selid}
            />
          </Form.Item>
          <Form.Item label="DEGREE" name="degree" colon={false}>
            <Input 
            placeholder="레벨" 
            name="degree"
            // value={this.state.seldegree} 
            onChange={degreeset}
            defaultValue={this.state.seldegree}
            key={this.state.selid}
            />
          </Form.Item>
          </Col>
          <Col span={12} className="text-right">
            
          </Col>
          <Col span={12} className="text-left">
            <Button 
            type="default" 
            size="small" 
            shape="round" 
            htmlType="submit"
            // icon={<DownloadOutlined />}
            className="px-25">
              수정
            </Button>
          </Col>
        </Row>
        </Form>
      </Col>
      </>
    const deleteForm =
    <>
      <Col span={24} lg={formcolnum} md={formcolnum}>
      <Form 
      layout="vertical"
      method="post" 
      // initialValues={{ 
      //   title:this.state.seltitle,
      //   degree:this.state.seldegree,
      //    }}
      onFinish={onFinishDel}
      >
        <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item label="ID" name="id" colon={false} >
            <Input 
            placeholder="id" 
            disabled
            value={this.state.seltitle} 
            // onChange={titleset}
            name="id"
            defaultValue={this.state.selid}
            key={this.state.selid}
            />
          </Form.Item>
          <Form.Item label="title" name="title" colon={false} >
            <Input 
            placeholder="권한명칭" 
            value={this.state.seltitle} 
            // onChange={titleset}
            name="title"
            defaultValue={this.state.seltitle}
            key={this.state.selid}
            disabled
            />
          </Form.Item>
          <Form.Item label="degree" name="degree" colon={false}>
            <Input 
            placeholder="레벨" 
            name="degree"
            // value={this.state.seldegree} 
            // onChange={degreeset}
            disabled
            defaultValue={this.state.seldegree}
            key={this.state.selid}
            />
          </Form.Item>
          </Col>
          <Col span={12} className="text-right">
            
          </Col>
          <Col span={12} className="text-left">
            <Button 
            type="default" 
            size="small" 
            shape="round" 
            htmlType="submit"
            className="px-25">
              삭제
            </Button>
          </Col>
        </Row>
        </Form>
      </Col>
      </>
    return (
      
      <>
        <div className="mx-auto mt-50" style={{ maxWidth: "1500px" }}>
          <div className="mb-50">
            <h3 className="mt-25 text-center h3 ">사용자 권한관리</h3>
            <h5 className="text-center font-regular h5">
              관리자전용 페이지
            </h5>
            <Steps
              className="my-50"
              progressDot
              current={current}
              onChange={this.onChangePage}
            >
              <Step title="권한관리" />
              <Step title="사용자권한" />
              <Step title="타임라인" />
            </Steps>
          </div>
          <div className="mb-24">
            {current === 0 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                    권한종류 및 등급을 설정합니다
                    </h5>
                    <p className="font-regular text-center">
                    추가,수정,삭제클릭시 입력창이 표시됩니다.<br/>사용자권한부여 및 수정은 상단 슬라이더 및 아래 Next로 다음페이지를 확인하세요
                    </p>
                  </>,
                ]}
              >
                  <Row justify="start">
                    <Col span={6}>
                      <Button
                        onClick={()=>{
                          let mode=!this.state.upmode
                          this.setState({
                            upmode:mode,
                            target:1
                            })}}
                        type="dashed"
                        className="px-25"
                        size="small"
                        icon={<FormOutlined />}
                      >
                        추가
                      </Button>
                    </Col>
                    <Col span={6} >
                      <Button
                        onClick={()=>{
                          let mode=!this.state.upmode
                          this.setState({
                            upmode:mode,
                            target:2})}}
                        type="dashed"
                        size="small"
                        className="px-25"
                        icon={<EditOutlined />}
                        
                      >
                        수정
                      </Button>
                      
                    </Col>
                    <Col span={6}>
                      <Button
                        onClick={()=>{
                          let mode=!this.state.upmode
                          this.setState({
                            upmode:mode,
                            target:3})}}
                        type="dashed"
                        size="small"
                        className="px-25"
                        icon={<DeleteOutlined />}
                        
                      >
                        삭제
                      </Button>
                    </Col>
                    <Col span={6}></Col>
                    </Row>
                  <Row gutter={[24, 0]}>
                  <Col span={24} lg={colnum} md={colnum}>
                    <Table 
                    rowSelection={{
                      type: "radio",
                      ...rowSelection,
                    }}
                    key={this.state.degree.key}
                    dataSource={this.state.degree} 
                    columns={columns} />
                    </Col>
                    {this.state.upmode && this.state.target ===1 ? creatForm:""}
                    {this.state.upmode && this.state.target ===2 ? updateForm:""}
                    {this.state.upmode && this.state.target ===3 ? deleteForm:""}
                  </Row>
                  <Row gutter={[24, 0]}>
                    {/* 내용공간 */}
                    <Col span={15}>
                    
                    </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                      <Col span={15}></Col>
                    <Col span={9}className="text-right">
                      <Button
                        onClick={this.next}
                        type="primary"
                        className="px-25"
                      >
                        NEXT
                      </Button>
                    </Col>
                    </Row>
                  
              </Card>
            )}
            {current === 1 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                      사용자의 등급을 설정합니다
                    </h5>
                    <p className="font-regular text-center">
                      사용자계정관리 및 등급을 설정할 수 있습니다
                    </p>
                  </>,
                ]}
              >
                
                  <Row gutter={[24, 0]}>
                   {/* 내용공간 */}
                   <Col span={24}>
                   <Table 
                    rowSelection={{
                      type: "radio",
                      ...listRowSelection,
                    }}
                    key={this.state.userlist.id}
                    dataSource={this.state.userlist} 
                    columns={userlistcolumns}
                    onChange={this.onChange}
                    bordered
                    scroll={{
                      x: 900,
                      y: 500,
                    }}
                    />
                    
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} justify="center">
                    <Col span={24} className="text-center">
                    <Form
                      // className="text-center"
                      name="complex-form"
                    
                                            // labelCol={{
                      //   span: 5,
                      // }}
                      // wrapperCol={{
                      //   span: 19,
                      // }}
                      // style={{
                      //   maxWidth: 800,
                      // }}

                    >
                      <Form.Item >
                        <Input.Group compact >
                          <Form.Item 
                            
                            name="email"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: 'Street is required',
                              },
                            ]}
                          >
                            <Input
                              style={{
                                width: '40%',
                              }}
                              placeholder="Email"
                              name="email"
                              defaultValue={this.state.selemail}
                              key={this.state.selid}
                              onChange={emailset}
                            />
                          </Form.Item>
                          <Form.Item
                            name="name"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: 'Street is required',
                              },
                            ]}
                          >
                            <Input
                              style={{
                                width: '20%',
                              }}
                              placeholder="name"
                              name="name"
                              defaultValue={this.state.selname}
                              key={this.state.selid}
                              onChange={nameset}
                            />
                          </Form.Item>
                          <Form.Item
                            name="permission"
                            noStyle
                            
                            key={this.state.selid}
                            // defaultValue={this.state.selpermi}
                            // key={this.state.selid}
                            rules={[
                              {
                                required: true,
                                message: 'Province is required',
                              },
                            ]}
                          >
                            <Select placeholder="Select Permission" 
                            size="large" 
                            defaultValue={this.state.selpermi}
                            onChange={permiset}
                            >
                              {
                                this.state.degree.map((item)=>{
                                  return <Option value={item.title}>{item.title}</Option>
                                })
                              }
                              
                            </Select>
                          </Form.Item>
                        </Input.Group>
                        
                      </Form.Item>
                    </Form>
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} justify="center">
                      <Col span={4}>
                          <Button
                            onClick={userUp}
                            type="dashed"
                            size="small"
                            className="px-25"
                            icon={<EditOutlined />}
                          >
                            수정
                          </Button>
                          
                        </Col>
                        <Col span={2}></Col>
                        <Col span={4}className="text-center">
                          <Button
                            onClick={userDel}
                            type="dashed"
                            size="small"
                            className="px-25"
                            icon={<DeleteOutlined />}
                          >
                            삭제
                          </Button>
                        </Col>
                    </Row>
                  <Row gutter={[24, 0]}>
                  <Col span={12} className="text-left">
                    <Button 
                    onClick={this.prev} 
                    className="px-25">
                        PREV
                    </Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Button
                        onClick={this.next}
                        type="primary"
                        className="px-25"
                      >
                        NEXT
                    </Button>
                  </Col>
                  </Row>
              </Card>
            )}
            {current === 2 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                      시간대별 내역을 조회합니다
                    </h5>
                    <p className="font-regular text-center">
                      Give us more details about you. What do you enjoy doing in
                      your spare time?
                    </p>
                  </>,
                ]}
              >
                <Row justify="start">
                    <Col span={6}>
                      
                    </Col>
                    <Col span={6} >
                      
                      
                    </Col>
                    <Col span={6}>
                      
                    </Col>
                    <Col span={6}></Col>
                    </Row>
                  <Row gutter={[24, 0]}>
                   {/* 내용공간 */}
                    <Col span={12}>
                      
                    </Col>
                    {/* NEXT버튼공간 */}
                    <Col span={12} className="text-right">
                    
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]}>
                  <Col span={12} className="text-left">
                    <Button 
                    onClick={this.prev} 
                    className="px-25">
                        PREV
                    </Button>
                  </Col>
                  <Col span={12} className="text-right">
                    {/* <Button
                        onClick={this.next}
                        type="primary"
                        className="px-25"
                      >
                        NEXT
                    </Button> */}
                  </Col>
                  </Row>
              </Card>
            )}
          </div>
        </div>
      </>
    );
  }
}
