
import Home from "../component/pages/Home";
import Info from "../component/pages/homepage/Info";
import signin from "../component/pages/user/SignIn";
import signup from "../component/pages/user/SignUp";
import profile from "../component/pages/user/Profile";
import userlist from "../component/pages/user/UserList";
import apilist from "../component/pages/Apilist";
import HDboard from "../component/pages/HDboard";
import Company from "../component/pages/company/Company";
import TaxSend from "../component/pages/tax/TaxSend";
import Searchmap from "../component/pages/map/searchmap";
import ASReport from "../component/pages/AS/ASReport";
import TimerServer from "../component/pages/iot/TimerServer"


const routes = [
  {
    path: "/web",
    exact: true,
    name: "main",
    component: Home,
  },
  {
    path: "/web/home",
    exact: true,
    name: "home",
    component: Home,
  },
  {
    path: "/web/info",
    exact: true,
    name: "Info",
    component: Info,
  },
  {
    path: "/web/sign-in",
    exact: true,
    name: "signin",
    component: signin,
  },
  {
    path: "/web/control/timer",
    exact: true,
    name: "timerserver",
    component: TimerServer,
  },
  // {
  //   path: "/web/sign-up",
  //   exact: true,
  //   name: "signup",
  //   component: signup,
  // },
  // {
  //   path: "/web/profile",
  //   exact: true,
  //   name: "profile",
  //   component: profile,
  // },
  // {
  //   path: "/web/userlist",
  //   exact: true,
  //   name: "userlist",
  //   component: userlist,
  // },
  // {
  //   path: "/web/home/apis",
  //   exact: true,
  //   name: "apilist",
  //   component: apilist,
  // },
  // {
  //   path: "/web/board/hw",
  //   exact: true,
  //   name: "HDboard",
  //   component: HDboard,
  // },
  // {
  //   path: "/web/company",
  //   exact: true,
  //   name: "Company",
  //   component: Company,
  // },
  // {
  //   path: "/web/bill/new",
  //   exact: true,
  //   name: "Company",
  //   component: TaxSend,
  // },
  // {
  //   path: "/web/map/movement",
  //   exact: true,
  //   name: "movement",
  //   component: Searchmap,
  // },
  // {
  //   path: "/web/diary/asinfo",
  //   exact: true,
  //   name: "movement",
  //   component: ASReport,
  // },
  
  
];

export default routes;
