import {
  AuditOutlined,
  BankOutlined,
  FormOutlined,
  AreaChartOutlined,
  CodeOutlined,
  HomeOutlined,
  CompassOutlined,
  DashboardOutlined,
  ApiOutlined
} from "@ant-design/icons";

const menuItems = {
  items: [
    {
      id: "null0",
      title: "HOMEPAGE",
      className: "d-none",
      type: "group",
      children: [
        {
          id: "das",
          title: <span className="label">HOMEPAGE</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <HomeOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "home",
              title: "Home",
              type: "item",
              url: "/web/home",
            },
            {
              id: "info",
              title: "Info",
              type: "item",
              url: "/web/info",
            },
            // {
            //   id: "User",
            //   title: "User",
            //   type: "collapse",
            //   children: [
            //     {
            //       id: "Profile",
            //       title: "Profile",
            //       type: "item",
            //       url: "/web/profile",
            //     },
            //     {
            //       id: "userlist",
            //       title: "Userlist",
            //       type: "item",
            //       url: "/web/userlist",
            //     }]
            // },
            // {
            //   id: "api",
            //   title: "APIS",
            //   type: "item",
            //   url: "/web/home/apis",
            // },
          ],
        },
      ],
    },
    {
      id: "server",
      title: "SERVER",
      type: "group",
      children: [
        {
          id: "board",
          title: <span className="label">Dashboard</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <DashboardOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "webboard",
              title: "WEB",
              type: "item",
              url: "/web/board/web"
            },
            {
              id: "hwboard",
              title: "H/W",
              type: "item",
              url: "/web/board/hw"
            },
            
          ],
        }
      ],
    },
    // {
    //   id: "blog",
    //   title: "BLOG",
    //   type: "group",
    //   children: [
    //     {
    //       id: "python",
    //       title: <span className="label">PYTHON</span>,
    //       type: "collapse",
    //       icon: (isSelected, color) => (
    //         <CodeOutlined
    //           className="icon"
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       )
    //     },
    //     {
    //       id: "javascript",
    //       title: <span className="label">JAVASCRIPT</span>,
    //       type: "collapse",
    //       icon: (isSelected, color) => (
    //         <CodeOutlined
    //           className="icon"
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       )
    //     },
    //     {
    //       id: "sql",
    //       title: <span className="label">SQL</span>,
    //       type: "collapse",
    //       icon: (isSelected, color) => (
    //         <CodeOutlined
    //           className="icon"
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       )
    //     },
    //     {
    //       id: "linux",
    //       title: <span className="label">LINUX</span>,
    //       type: "collapse",
    //       icon: (isSelected, color) => (
    //         <CodeOutlined
    //           className="icon"
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       )
    //     }
    //   ],
    // },
    {
      id: "work",
      title: "WORK",
      type: "group",
      children: [
        {
          id: "control",
          title: <span className="label">Control</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <ApiOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "control_yumiot",
              title: "개인 IOT",
              type: "item",
              url: "/web/control/iot",
            },
            {
              id: "control_timer",
              title: "타이머잠금장치",
              type: "item",
              url: "/web/control/timer",
            },
            // {
            //   id: "companyinfo",
            //   title: "부가정보",
            //   type: "item",
            //   url: "/",
            // }
          ]
          
        },
        // {
        //   id: "diary",
        //   title: <span className="label">DIARY</span>,
        //   type: "collapse",
        //   icon: (isSelected, color) => (
        //     <ApiOutlined
        //       className="icon"
        //       style={{
        //         background: isSelected ? color : "",
        //       }}
        //     />
        //   ),children: [
        //     {
        //       id: "asinfo",
        //       title: "AS관리",
        //       type: "item",
        //       url: "/web/diary/asinfo",
        //     },
        //     {
        //       id: "workdiary",
        //       title: "업무일지",
        //       type: "item",
        //       url: "/web/diary/workdiary",
        //     },
        //     {
        //       id: "calendar",
        //       title: "일정",
        //       type: "item",
        //       url: "/web/diary/calendar",
        //     },]
        // },
        // {
        //   id: "bill",
        //   title: <span className="label">BILL</span>,
        //   type: "collapse",
        //   icon: (isSelected, color) => (
        //     <AuditOutlined
        //       className="icon"
        //       style={{
        //         background: isSelected ? color : "",
        //       }}
        //     />
        //   ),children: [
        //     {
        //       id: "newbill",
        //       title: "전자문서발급",
        //       type: "item",
        //       url: "/web/bill/new",
        //     },
        //     {
        //       id: "billjob",
        //       title: "재발급 및 전자업무",
        //       type: "item",
        //       url: "/web/bill/billjob",
        //     },
        //     ]
        // },
        // {
        //   id: "map",
        //   title: <span className="label">MAP</span>,
        //   type: "collapse",
        //   icon: (isSelected, color) => (
        //     <CompassOutlined 
        //       className="icon"
        //       style={{
        //         background: isSelected ? color : "",
        //       }}
        //     />
        //   ),children: [
        //     {
        //       id: "movement",
        //       title: "분포도 및 동선",
        //       type: "item",
        //       url: "/web/map/movement",
        //     },]
        // },
        // {
        //   id: "total",
        //   title: <span className="label">TOTAL</span>,
        //   type: "collapse",
        //   icon: (isSelected, color) => (
        //     <AreaChartOutlined
        //       className="icon"
        //       style={{
        //         background: isSelected ? color : "",
        //       }}
        //     />
        //   )
        // }

      ]
      
      
    },
    
  ],
};

export default menuItems;
