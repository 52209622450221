import React, { Component } from "react";
import { Card, Row, Col, Button } from "antd";
import logo from "../../images/logo.png";
import axios from "axios";
import { Redirect } from 'react-router-dom';
axios.defaults.withCredentials = true;
export default class TimerServer extends Component {
	constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      loginid: "",
    };
  }
  getuser = async(req, res, next)=>{
    const cookies = document.cookie.split('; ');
    const targetCookie = cookies.find(cookie => cookie.startsWith(`auth-token=`));
    if (targetCookie) {
      axios.post("https://yumi.town/api/loginfo", { withCredentials: true })
      .then(response => {
        this.setState({
        isLogged:true,
        loginid:response.data.username,
        })
      })
      .catch(error => {
        this.setState({
          isLogged:false,
          loginid:"",
          })
        window.location.href = "/web/sign-in";
      });
    }else{
      this.setState({
        isLogged:false,
        loginid:"",
        })
      window.location.href = "/web/sign-in";
    }
  }
  componentDidMount(){
    this.getuser()
  }
  print() {
    window.print();
  }
  render() {
    if (!this.state.isLogged) {
      return null; 
    }
    return (
      <>
        <Card
          className="card-invoice header-solid mb-24 mx-auto my-50 print"
          bordered={false}
          style={{ maxWidth: "800px" }}
          title={[<img src={logo} className="brand" alt="" key={0}/>]}
        >
          <div style={{ height: '100vh' }}>
            <iframe
              src="https://yumi.town/esp"
              title="Embedded Webpage"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </div>
        </Card>
      </>
    );
  }
}
