/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { Layout, Button,  Card, Form, Input, Alert, Result } from "antd";
import logo1 from "../../images/kakaotalk.png";
import logo2 from "../../images/logo-apple.svg";
import logo3 from "../../images/Google__G__Logo.svg.png";
import { Link } from "react-router-dom";
import HomeFooter from "../../layout/FooterNEw";
import axios from 'axios';
import Marquee from 'react-fast-marquee';


const { Header, Footer, Content } = Layout;

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailms:"이름을 입력해주세요",
      password: "",
      passwordms:"",
      name:"",
      namems:"",
      errorms:false,
      isSuccess:false
    };
  }
  
  
  render() {
    const emailset = (e) => {
      e.preventDefault();
      this.setState({ email: e.target.value });
    };
    const passwordset = (e) => {
      e.preventDefault();
      this.setState({ password: e.target.value });
    };
    const nameset = (e) => {
      e.preventDefault();
      this.setState({ name: e.target.value });
    };
    const submitHandler = (e) => {}
      // e.preventDefault();
      // state에 저장한 값을 가져옵니다.
        
      
    
    const onFinish = (values) => {
      this.setState({isSuccess:false,errorms:false})
      if(values.password!==values.confirm_password){
        this.setState({isSuccess:false,errorms:2})
        return
      }else if (values.password===values.confirm_password){
        this.setState({errorms:false, isSuccess:false})
        let body = {
          email: this.state.email,
          name:this.state.name,
          password: this.state.password,
        };
        axios
          .post("/api/auths/register", body)
          .then((res) => {
            if (res.status===200){
              this.setState({isSuccess:true, errorms:false})
            } 
            } 
          )
          .catch((err)=>{
            if(err.response.status===409){
              this.setState({isSuccess:false,errorms:1})
            } else {
              console.error(err)
            }
          });}
    };
    const Resultcon=<>
      <Result
      status="success"
      title="정상 등록되었습니다"
      subTitle={this.state.email+"계정으로 등록되었습니다"}
      extra={[
        // <Button type="primary" key="console">
        //   Go Console
        // </Button>,
        <Button key="login" href="/web/sign-in">로그인하기</Button>,
    ]}
  />
    </>
    const content = <>
    <Content className="p-0">
        <div className="sign-up-header">
          <div className="content">
            <h1 className="mb-3 font-bold mbt-5">사용자 등록</h1>
            <p className="text-lg" style={{ lineHeight: "24px" }}>
              어쩌구저쩌구
            </p>
          </div>
        </div>
        <Card
          className="card-signup header-solid h-full ant-card pt-0"
          title={
            <h5 className="font-semibold text-center">SNS 계정으로 등록</h5>
          }
          bordered="false"
          bodyStyle={{ paddingTop: "0px" }}
        >
          <div className="sign-up-gateways">
            <Button type="false">
              <img src={logo1} alt="" />
            </Button>
            
            <Button type="false">
              <img src={logo3} alt="" />
            </Button>
          </div>
          <div className="divider my-25">
            <hr className="gradient-line" />
            <p className="font-semibold text-muted">
              <span className="label">ID로 등록</span>
            </p>
          </div>
          <Form
            method="post" 
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            // action="https://ityumhouse.com/api/auths/register"
            // onSubmitCapture={register}
            className="row-col"
          >
            <Form.Item
              name="name"
              // for="name"
              rules={[
                { required: true, message: "이름을 입력해주세요" },
              ]}
            >
              <Input placeholder="Name" id="name" value={this.state.name} onChange={nameset}/>
            </Form.Item>
            <Form.Item
              name="email"
              // for="email"
              rules={[
                { required: true, message: "email를 입력해주세요" },
                { type: 'email', 
                  message: 'E-Mail형식이 아닙니다', },
              ]}
            >
              <Input placeholder="Email" type="email" id="email" value={this.state.email} onChange={emailset}/>
            </Form.Item>
            <Form.Item
              name="password"
              // for="password"
              rules={[
                { required: true, message: "password를 입력해주세요" },
              ]}
            >
              <Input placeholder="password" type="password" id="password" value={this.state.password} onChange={passwordset}/>
            </Form.Item>
            <Form.Item
              name="confirm_password"
              // for="password"
              rules={[
                { required: true, message: "password를 입력해주세요" },
              ]}
            >
              <Input placeholder="password " type="password" id="confirm_password" />
            </Form.Item>
          {this.state.errorms===1?<Alert
            message="사용중인 이메일입니다"
            banner
            showIcon
            closable
            style={{ marginBottom: "16px" }}
          />:''}
          {this.state.errorms===2?<Alert
            message="비밀번호를 확인해주세요"
            banner
            showIcon
            closable
            style={{ marginBottom: "16px" }}
          />:''}
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
              >
                등록
              </Button>
            </Form.Item>
          </Form>
          <p className="font-semibold text-muted text-center">
            Already have an account?{" "}
            <Link to="/web/sign-in" className="font-bold text-dark">
              Login
            </Link>
          </p>
        </Card>
      </Content>
    </>
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <>
        <div className="layout-default ant-layout layout-sign-up ">
          <Header>
            {/* <Header_Pro /> */}
          </Header>
          {this.state.isSuccess?Resultcon:content}
          
          <Footer>
            <HomeFooter />
          </Footer>
        </div>
      </>
    );
  }
}
