/*!
=========================================================
* Muse Ant Design Dashboard PRO - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import "./App.css";
import "./responsive.css";
import { Switch, Route, Redirect } from "react-router-dom";

import SignUp from "./component/pages/user/SignUp";
import SignIn from "./component/pages/user/SignIn";
import Main from "./component/layout/main";
import Pricing from "./component/pages/Pricing";
import Cover from "./component/authentication/signup/Cover";

import Illustration from "./component/authentication/signup/Illustration";
import PageFound from "./component/pages/PageFound";
import axios from "axios";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/web/sign-in" exact component={SignIn} />
        <Route path="/web/sign-up" exact component={SignUp} />
        <Route path="/web/authentication/sign-up/cover" exact component={Cover} />
        {/* <Route
          path="/web/authentication/sign-up/illustration"
          exact
          component={Illustration}
        /> */}
        {/* <Route path="/web/sign-in" exact component={SignIn} /> */}
        <Route path="/web/pages/pricing" exact component={Pricing} />

        <Route path="/web" component={Main} />
        {/* <Route path={"*"} component={PageFound} /> */}

        <Redirect
          to={{
            pathname: "/web/home",
          }}
        />
      </Switch>
    </div>
  );
}

export default App;
