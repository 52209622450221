import React, { Component } from "react";
import Iframe from 'react-iframe'
import { Link } from "react-router-dom";
import { ShadowDom } from "react";
import gifview from '../../images/companyview.gif';
// import video from "../../../../public/video/companyvideo.webm";
import {
  Steps,
  Card,
  Form, 
  Input, 
  Row,
  Col,
  message,
  Collapse,
  Checkbox,
  span,
  Tag,
  theme,
  Tooltip,
  InputNumber, 
  Popconfirm, 
  Table, 
  Space,
  Cascader,
  Typography,
  Button,
  Spin,
  Select,
  Result,
  Affix,
  Modal,
  Tabs
} from "antd";

import { FolderOutlined,
  DownloadOutlined,
  PlusOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined, 
  CloseOutlined,
  SearchOutlined,
  UserOutlined,
  LoadingOutlined,
  MinusOutlined,
  SolutionOutlined,
  
} from "@ant-design/icons";

import DaumPostcodeEmbed from 'react-daum-postcode';
import axios from "axios";
import { render } from "@testing-library/react";

const { Step } = Steps;
const token = localStorage.getItem('jwt') ||false
const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
const {Text } = Typography;
const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const BranchData =[]

export default class Company extends Component {
  state = {
    current: 0,
    loguser:false,
    logdegree:false,
    ProvideList:[],
    CompanyheadList:[],
    BranchsList:[],
    SearchList:[],
    SearchData:[],
    SearchSelkey:false,
    SelEmail:[],
    SelTELNum:[],
    SelHPNum:[],
    SelFAXNum:[],
    SelAddress:"",
    SelUser:[],
    Filtered:[],
    residenceschildren:[],
    residences:[],
    SelProvideTitle:'',
    SelProvideas:'',
    SelCompanyheadTitle:'',
    SelBranchsBrnum:'',
    EditingProvideKey:false,
    EditingCompanyheadKey:false,
    EditingBranchsKey:false,
    EditingBranchs:false,
    AddEmail:'',
    AddTELNum:'',
    AddHPNum:'',
    AddFAXNum:'',
    AddAddress:'',
    AddAddress2:'',
    AddUser:"",
    AddUserClass:"",
    brnum:'',
    Searchname:'',
    isModalOpen:false,
    totalPage1: 5,

  };
  onChange = (current) => {
    console.log("onChange:", current);
    this.setState({ current });
  };

  next = () => {
    this.setState({
      current: this.state.current + 1,
    });
  };

  prev = () => {
    this.setState({
      current: this.state.current - 1,
    });
  };
  callback(key) {
    console.log(key);
  }
  provideedit = (record) => {
    let key=record.key
    if(this.state.EditingProvideKey===key){
      this.setState({
        EditingProvideKey:false,
        SelProvideTitle:'',
        SelProvideas:''
      })
    }else{
      this.setState({
        EditingProvideKey:record.key,
        SelProvideTitle:record.title,
        SelProvideas:record.as
      })
    }
  }
  companyheadedit = (record) => {
    let key=record.key
    if(this.state.EditingCompanyheadKey===key){
      this.setState({
        EditingCompanyheadKey:false
      })
    }else{
      this.setState({
        EditingCompanyheadKey:record.key
      })
    }
  }
  Branchsedit = (record) => {
    let key=record.key
    if(this.state.EditingBranchsKey===key){
      this.setState({
        EditingBranchsKey:false,
        EditingBranchs:false,
        SelBranchsBrnum:'',
      })
    }else{
      this.getbreanchslist()
      this.setState({
        EditingBranchsKey:record.key,
        EditingBranchs:record,
        SelBranchsBrnum:record.brnum,
      })
    }
  }
  
  getlogin = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/auths/')
      .then((res)=>{
        this.setState({
          logdegree:res.data.degree,
          loguser:res.data
        })
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  getprovidlist=async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/provide/list')
      .then((res)=>{
        let newProvideList = [this.state.ProvideList];
        let newresidenceschildren = [this.state.residenceschildren];
        newProvideList=[];
        res.data.map((item)=>{
          newProvideList.push({
            key: item.id,
            title: item.title,
            as: item.astitle,
            value: item.title,
            label: item.title,
          })
          newresidenceschildren.push({
            key: item.id,
            value:item.id,
            label:item.title
          })
        })
        this.setState({ProvideList:newProvideList, residenceschildren:newresidenceschildren})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  getcompanyheadlist=async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/Companyhead/list')
      .then((res)=>{
        let newCompanyheadList = [this.state.CompanyheadList];
        let newresidences = [this.state.residences];
        newCompanyheadList=[];
        res.data.map((item)=>{
          newCompanyheadList.push({
            key: item.id,
            title: item.title,
            value: item.title,
            label: item.title,
          })
          newresidences.push({
            key: item.id,
            value: item.id,
            label: item.title,
            children:this.state.residenceschildren
          })
        })
        this.setState({CompanyheadList:newCompanyheadList, residences:newresidences})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  getbreanchslist=async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/company/list')
      .then((res)=>{
        let newBranchsList = [this.state.BranchsList];
        newBranchsList=[];
        res.data.map((item)=>{
          newBranchsList.push({
            key: item.id,
            title: item.title,
            companyhead:item.Companyhead.title,
            provide:item.Provide.title,
            brnum:item.brnum,
            description:item.Company.title
          })
        })
        this.setState({BranchsList:newBranchsList, Filtered:newBranchsList})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  searchcompany=async(req, res, next)=>{
    let data={name:this.state.Searchname}
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/company/search',data)
      .then((res)=>{
        let newSearchList = [this.state.SearchList];
        let newSearchData = [this.state.SearchData];
        newSearchList=[];
        newSearchData=[];
        res.data.map((item)=>{
          newSearchData.push(item)
          newSearchList.push({
            key: item.id,
            id:item.id,
            title: item.title,
            email:item.Email!==null ? `${item.Email.email.length}`:"-",
            fax:item.FAXnum!==null ? item.FAXnum.num.length:"-",
            hp:item.HPnum!==null ? item.HPnum.num.length:"-",
            user:item.Manager!==null ? item.Manager.user.length:"-",
            tel:item.Telnum!==null ? item.Telnum.num.length:"-",
            // add:item.Address!==null ? item.Address.data.address_name:"미등록",
            add:item.Address!==null ? <CheckOutlined />:"-",
          })
        })
        this.setState({SearchList:newSearchList,
          SearchData:newSearchData})
          
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  SearchEnd = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get(`/api/contact/companyid=${this.state.SearchSelkey}`)
      .then((res)=>{
        let newSearchList = [...this.state.SearchList];
        let newSearchData = [...this.state.SearchData];
        newSearchList=[];
        newSearchData=[];
        newSearchData.push(res.data)
        let data={
          key: res.data.id,
          id:res.data.id,
          title: res.data.title,
          email:res.data.Email!==null ? `${res.data.Email.email.length}`:"-",
          fax:res.data.FAXnum!==null ? res.data.FAXnum.num.length:"-",
          hp:res.data.HPnum!==null ? res.data.HPnum.num.length:"-",
          user:res.data.Manager!==null ? res.data.Manager.user.length:"미등록",
          tel:res.data.Telnum!==null ? res.data.Telnum.num.length:"-",
          add:res.data.Address!==null ? res.data.Address.data.address_name:"미등록",
        }
        
        newSearchList.push(data)
        this.setState({SearchList:newSearchList,
          SearchData:newSearchData})
      })
      .catch((err)=>{
        console.error(err)
      })

  }
  componentWillMount(){
    this.getlogin();
    this.getprovidlist();
    this.getcompanyheadlist();
    this.getbreanchslist();
  }
  // componentDidMount() {
    
    
    
  // }
  handleTotalPageChange1 = (val) => {
    this.setState({ totalPage1: val });
  };
  showModal = () => {
    this.setState({isModalOpen:true});
  };
  handleOk = () => {
    this.setState({isModalOpen:false});
  };
  handleCancel = () => {
    this.setState({isModalOpen:false});
  };
  
  render() {
    if (!token || !this.state.logdegree){
      return (
        <>
      <div>
        <Row>
          <Col lg={12} md={24} sm={24} style={{textAlign:"center"}}>
            
            <img src={gifview} alt="my-gif" />
          </Col>
          <Col lg={12} md={24} sm={24} style={{textAlign:"left"}}>
            <Card>
              <Title level={2} style={{marginLeft:"15px", textAlign:"center"}}>
                로그인이 필요한 메뉴
              </Title>
              <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Front-End</Text><br/>
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>KAKAO를 통해주소를 검색하고 DAUM API로 위도경도를 저장방식</Text><br/>
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>컴포넌트 커스텀</Text><br/><br/>
              <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Back-End</Text><br/>
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>각 테이블별로 정규화 및 Join하는 방식</Text><br/> 
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>하나로마트=마트와 같이 약칭사용</Text><br/> 
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>특정테이블 신규레코드 생성시 명칭을 합쳐서 다른 테이블로 트리거실행(삽입,수정,삭제) </Text><br/> 
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>연락처, 사용자와 같은 테이블은 리스트타입, 주소테이블은 JSON타입 </Text><br/> 
              
            
            </Card>
          </Col>
            </Row>
          </div>
      </>
      )
    }
    // else if (this.state.logdegree>3){
    //   return (
    //     <>
    //       <Result
    //         status="403"
    //         title="권한이 없습니다"
    //         subTitle=""
    //         // extra={<Button type="primary" href="/web/sign-in" size="large">Login</Button>}
    //       />
    //     </>
    //   )
    // }
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({SearchSelkey:selectedRowKeys[0]})
        let newSelEmail = [...this.state.SelEmail];
        let newSelTELNum = [...this.state.SelTELNum];
        let newSelHPNum = [...this.state.SelHPNum];
        let newSelFAXNum = [...this.state.SelFAXNum];
        let newSelUser = [...this.state.SelUser];
        newSelEmail=[];
        newSelTELNum=[];
        newSelHPNum=[];
        newSelFAXNum=[];
        newSelUser=[];
        this.state.SearchData.map((item)=>{
          if (item.id===selectedRows[0].key){
            if (item.id===selectedRows[0].key){
              if (item.Email){
                item.Email.email.map((initem)=>{
                newSelEmail.push(initem)
                })
              }
              if (item.Telnum){
                item.Telnum.num.map((initem)=>{
                newSelTELNum.push(initem)
                })
              }
              if (item.HPnum){
                item.HPnum.num.map((initem)=>{
                newSelHPNum.push(initem)
               })
              }
              if (item.FAXnum){
                item.FAXnum.num.map((initem)=>{
                newSelFAXNum.push(initem)
                })
              }
              if (item.Manager){
                item.Manager.user.map((initem)=>{
                newSelUser.push(`${initem.class}:${initem.name}`)
                })
              }
              this.setState({SelAddress:item.Address!==null?item.Address.data.address_name+"  "+item.Address.data.etc:"미등록"})
              }
            }
        })
        this.setState({SelEmail:newSelEmail,
          SelTELNum:newSelTELNum,
          SelHPNum:newSelHPNum,
          SelFAXNum:newSelFAXNum,
          SelUser:newSelUser,
        })
      }}



    const ProvideTitleSet = (e) => {
      e.preventDefault();
      this.setState({ SelProvideTitle: e.target.value });
    };
    const ProvideAsSet = (e) => {
      e.preventDefault();
      this.setState({ SelProvideas: e.target.value });
    };
    const CompanyheadSet = (e) => {
      e.preventDefault();
      this.setState({ SelCompanyheadTitle: e.target.value });
    };
    const SearchSet = (e) => {
      e.preventDefault();
      this.setState({ Searchname: e.target.value });
    };
    const AddressOnchange = (e) => {
      e.preventDefault();
      this.setState({ AddAddress2: e.target.value });
    };
    // const BranchsTitleSet = (e) => {
    //   e.preventDefault();
    //   this.setState({ SelBranchsTitle: e.target.value });
    // };
    // const BrnumInSet = (e) => {
    //   e.preventDefault();
    //   this.setState({ SelBranchsBrnum: e.target.value });
    // };

    const BranchOnchangeCompany = (e) => {
      let temp=this.state.EditingBranchs
      temp.companyhead=e
      this.setState({EditingBranchs:temp})
    };
    const BranchOnchangeProvide = (e) => {
      let temp=this.state.EditingBranchs
      temp.provide=e
      this.setState({EditingBranchs:temp})
    };
    const BranchOnchangeTitle = (e) => {
      e.preventDefault();
      let temp=this.state.EditingBranchs
      temp.title=e.target.value
      this.setState({EditingBranchs:temp})
    };
    const BranchOnchangeBrnum = (e) => {
      e.preventDefault();
      e.target.value = e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,2})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
      let temp=this.state.EditingBranchs
      temp.brnum=e.target.value
      this.setState({EditingBranchs:temp})
    };
    const AddEmailOnchange = (e) => {
      e.preventDefault();
      this.setState({AddEmail:e.target.value})
    };
    
    const AddTELOnchange = (e) => {
      e.preventDefault();
      this.setState({AddTELNum:e.target.value});
        // setInputValue(e.target.value);
    };

    const AddHPOnchange = (e) => {
      e.preventDefault();
      this.setState({AddHPNum:e.target.value});
        // setInputValue(e.target.value);
    };

    const AddFAXOnchange = (e) => {
      e.preventDefault();
      this.setState({AddFAXNum:e.target.value});
        // setInputValue(e.target.value);
    };
    const AddUserOnchange = (e) => {
      e.preventDefault();
      this.setState({AddUser:e.target.value});
        // setInputValue(e.target.value);
    };
    const AddUserClassOnchange = (e) => {
      e.preventDefault();
      this.setState({AddUserClass:e.target.value});
        // setInputValue(e.target.value);
    };

    const BrnumSet = (e) => {
      e.preventDefault();
      e.target.value = e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,2})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
        this.setState({ brnum: e.target.value });
      
    };
    const SelecthandleChange=(value)=> {
      let newSelEmail = [...this.state.SelEmail]
      newSelEmail=[]
       value.map((item)=>{
        newSelEmail.push(item)
      })
      this.setState({SelEmail:newSelEmail})
     }


     const SelecthandleChangeTel=(value)=> {
      let newSelTELNum = [...this.state.SelTELNum]
      newSelTELNum=[]
       value.map((item)=>{
        newSelTELNum.push(item)
      })
      this.setState({SelTELNum:newSelTELNum})
     }


     const SelecthandleChangeHP=(value)=> {
      let newSelHPNum = [...this.state.SelHPNum]
      newSelHPNum=[]
       value.map((item)=>{
        newSelHPNum.push(item)
      })
      this.setState({SelHPNum:newSelHPNum})
     }

     const SelecthandleChangeFAX=(value)=> {
      let newSelFAXNum = [...this.state.SelFAXNum]
      newSelFAXNum=[]
       value.map((item)=>{
        newSelFAXNum.push(item)
      })
      this.setState({SelFAXNum:newSelFAXNum})
     }
     const SelecthandleChangeUser=(value)=> {
      let newSelUser = [...this.state.SelUser]
      newSelUser=[]
       value.map((item)=>{
        newSelUser.push(item)
      })
      this.setState({SelUser:newSelUser})
     }
    
    const EmailAdd = (e) => {
      e.target.value = e.target.value
      let newSelEmail = [...this.state.SelEmail]
      newSelEmail.push(this.state.AddEmail)
      this.setState({SelEmail:newSelEmail})
    };
    const TELNumAdd = (e) => {
      e.target.value = e.target.value
      let newSelTELNum = [...this.state.SelTELNum]
      newSelTELNum.push(this.state.AddTELNum)
      this.setState({SelTELNum:newSelTELNum})
    };

    const HPNumAdd = (e) => {
      e.target.value = e.target.value
      let newSelHPNum = [...this.state.SelHPNum]
      newSelHPNum.push(this.state.AddHPNum)
      this.setState({SelHPNum:newSelHPNum})
    };
    const FAXNumAdd = (e) => {
      e.target.value = e.target.value
      let newSelFAXNum = [...this.state.SelFAXNum]
      newSelFAXNum.push(this.state.AddFAXNum)
      this.setState({SelFAXNum:newSelFAXNum})
    };
    const UserAdd = (e) => {
      e.target.value = e.target.value
      let newSelUser = [...this.state.SelUser]
      // let user = {:this.state.AddUser}
      newSelUser.push(`${this.state.AddUserClass}:${this.state.AddUser}`)
      this.setState({SelUser:newSelUser})
    };


    const handleFilter = (e) => {
      let newFiltered = [...this.state.BranchsList];
      newFiltered = newFiltered.filter(
        (data) =>
          data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.companyhead.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.provide.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.brnum.toString().includes(e.target.value.toLowerCase()) ||
          data.description.toString().includes(e.target.value.toLowerCase())
          
      );
      this.setState({ Filtered: newFiltered });
    };
    
    

    const onFinishProvide = async(values) => {
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/provide/add',values)
      .then((res)=>{
        this.getprovidlist()
        message.success('등록되었습니다');
      })
      .catch((err) => {
        if (err.response.status===409){
          message.error('존재하는 업종입니다');
        }
        console.error(err);
      });
    }
    const onFinishCompanyHead = async(values) => {
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/companyhead/add',values)
      .then((res)=>{
        this.getcompanyheadlist();
        message.success('등록되었습니다');
      })
      .catch((err) => {
        if (err.response.status===409){
          message.error('존재하는 업종입니다');
        }
        console.error(err);
      });
    }
    const onFinishBranch = async(values) => {
      let data = {
        title:values.title, 
        provideid:values.class[1],
        companyheadid:values.class[0],
        brnum:this.state.brnum}
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/company/add',data)
      .then((res)=>{
        this.getbreanchslist()
        message.success('등록되었습니다');
      })
      .catch((err) => {
        if (err.response.status===409){
          message.error('존재하는 사업장번호입니다');
        }
        console.error(err);
      });
        
    }
    const EmailSave = async() => {
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post(`/api/contact/email/up/${this.state.SearchSelkey}`,this.state.SelEmail)
      .then((res)=>{
        message.success('등록되었습니다');
        this.SearchEnd()
      })
      .catch((err) => {
        if (err.response.status===400){
          message.error('등론된 사업장이 아닙니다');
        }
        console.error(err);
      });
    }
    const TELNumSave = async() => {
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post(`/api/contact/telnum/up/${this.state.SearchSelkey}`,this.state.SelTELNum)
      .then((res)=>{
        message.success('등록되었습니다');
        this.SearchEnd()
      })
      .catch((err) => {
        if (err.response.status===400){
          message.error('등론된 사업장이 아닙니다');
        }
        console.error(err);
      });
    }

    const HPNumSave = async() => {
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post(`/api/contact/hpnum/up/${this.state.SearchSelkey}`,this.state.SelHPNum)
      .then((res)=>{
        message.success('등록되었습니다');
        this.SearchEnd()
      })
      .catch((err) => {
        if (err.response.status===400){
          message.error('등론된 사업장이 아닙니다');
        }
        console.error(err);
      });
    }

    const FAXNumSave = async() => {
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post(`/api/contact/faxnum/up/${this.state.SearchSelkey}`,this.state.SelFAXNum)
      .then((res)=>{
        message.success('등록되었습니다');
        this.SearchEnd()
      })
      .catch((err) => {
        if (err.response.status===400){
          message.error('등론된 사업장이 아닙니다');
        }
        console.error(err);
      });
    }
    const AddressSave = async() => {
      // let data = {address:this.state.AddAddress}
      const data={}
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get(`/api/contact/addressapi/addr=${this.state.AddAddress}`)
      .then((res)=>{
        res.data.etc = this.state.AddAddress2
        instance
        .post(`/api/contact/address/up/${this.state.SearchSelkey}`,res.data)
        .then((res)=>{
          message.success('등록되었습니다');
          this.SearchEnd()
        })
        .catch((err) => {
          if (err.response.status===400){
            message.error('등록된 사업장이 아닙니다');
          }
          console.error(err);
        });
        })
      .catch((err) => {
        console.error(err);
      });
    }
    const ManagerSave = async() => {
      let userlist=[]
      this.state.SelUser.map((item)=>{
        userlist.push({
          class:item.split(':')[0],
          name:item.split(':')[1]
          })
      })
      console.log(userlist)
      let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post(`/api/contact/manager/up/${this.state.SearchSelkey}`,userlist)
      .then((res)=>{
        message.success('등록되었습니다');
        this.SearchEnd()
      })
      .catch((err) => {
        if (err.response.status===400){
          message.error('등론된 사업장이 아닙니다');
        }
        console.error(err);
      });
    }


    const provideeditSave=async() => {
      if(this.state.EditingProvideKey){
        let data = {id:this.state.EditingProvideKey, 
          title:this.state.SelProvideTitle, 
          astitle:this.state.SelProvideas}
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post('/api/provide/up',data)
        .then((res)=>{
          this.setState({
            EditingProvideKey:false,
            SelProvideTitle:'',
            SelProvideas:''
          })
          this.getprovidlist()
          message.success('수정되었습니다');
        })
        .catch((err) => {
          if (err.response.status===409){
            message.error('존재하지 않는 업종입니다');
          }
          console.error(err);
        });
      }
    }
    const CompanyHeadeditSave=async() => {
      if(this.state.EditingCompanyheadKey){
        let data = {id:this.state.EditingCompanyheadKey, 
          title:this.state.SelCompanyheadTitle, }
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post('/api/CompanyHead/up',data)
        .then((res)=>{
          this.setState({
            EditingCompanyheadKey:false,
            SelCompanyheadTitle:'',
          })
          this.getcompanyheadlist()
          message.success('수정되었습니다');
        })
        .catch((err) => {
          if (err.response.status===409){
            message.error('존재하지 않는 업체입니다');
          }
          console.error(err);
        });
      }
    }
    const BranchSave=async() => {
      if(this.state.EditingBranchsKey && this.state.EditingBranchsKey===this.state.EditingBranchs.key){
        // let data = JSON.stringify(this.state.EditingBranchs);
        let data = this.state.EditingBranchs;
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post('/api/Company/up',data)
        .then((res)=>{
          this.setState({
            EditingBranchsKey:false, 
            EditingBranchs:false,
          })
          this.getbreanchslist()
          message.success('수정되었습니다');
        })
        .catch((err) => {
          if (err.response.status===409){
            message.error('존재하지 않는 사업장입니다');
          }
          console.error(err);
        });
        
      }
    }
    
    const provideeditDel = async(value)=>{
      let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post(`/api/provide/del/${value.key}`)
        .then((res)=>{
          // this.setState({
          //   EditingProvideKey:false,
          //   SelProvideTitle:'',
          //   SelProvideas:''
          // })
          this.getprovidlist()
          message.success('삭제되었습니다');
        })
        .catch((err) => {
          if (err.response.status===409){
            message.error('존재하지 않는 업종입니다');
          }
          console.error(err);
        });
    }
    const CompanyHeadeditDel = async(value)=>{
      let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post(`/api/CompanyHead/del/${value.key}`)
        .then((res)=>{
          // this.setState({
          //   EditingProvideKey:false,
          //   SelProvideTitle:'',
          //   SelProvideas:''
          // })
          this.getcompanyheadlist()
          message.success('삭제되었습니다');
        })
        .catch((err) => {
          if (err.response.status===409){
            message.error('존재하지 않는 업종입니다');
          }
          console.error(err);
        });
    }
    const BranchsDel = async(value)=>{
      let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post(`/api/Company/del/${value.key}`)
        .then((res)=>{
          // this.setState({
          //   EditingProvideKey:false,
          //   SelProvideTitle:'',
          //   SelProvideas:''
          // })
          this.getbreanchslist()
          message.success('삭제되었습니다');
        })
        .catch((err) => {
          if (err.response.status===409){
            message.error('존재하지 않는 사업장입니다');
          }
          console.error(err);
        });
    }
    const providecolumns = [
      {
        title: '구분',
        dataIndex: 'title',
        key: 'key',
        width:'40%',
        render:(_,record)=>{
          const isEdit= this.state.EditingProvideKey
          // if (this.state.EditingProvide===record){}
          return (isEdit===record.key?
          <Input
            size="small"
            onChange={ProvideTitleSet}
            defaultValue={record.title}
            style={{
              width: 'calc',
              // maxWidth:'200px'
            }}
          />:record.title)
          }
      },
      {
        title: '별칭',
        dataIndex: 'as',
        width:'40%',
        key: 'key',
        render:(_,record)=>{
          const isEdit= this.state.EditingProvideKey
          // if (this.state.EditingProvide===record){}
          return (isEdit===record.key?
            <Input.Group compact>
              <Input
              size="small"
              onChange={ProvideAsSet}
              defaultValue={record.as}
              style={{
                width: 'calc(70%)',
              }}
              />
              <Button 
              size="small"
              type="primary"
              onClick={provideeditSave}
                // htmlType="submit"
                >저장</Button>
            </Input.Group>
            :record.as)
              }
      },
    
      {
        title: 'Action',
        with:'80',
        key: 'action',
        render: (_, record) => (
          <>
            <span>
              <Row gutter={[24, 0]} style={{paddingLeft:5,paddingRight:1}}>
                <Col span={12}>
                  {this.state.logdegree<=3?
                    <Button 
                    onClick={() => this.provideedit(record)}
                    type="default" 
                    shape="circle" 
                    icon={<EditOutlined />} 
                    size="small" />
                    :""  
                }
                
                </Col>
                <Col span={12} style={{paddingLeft:1,paddingRight:15}}>
                  {this.state.logdegree<=2? <Popconfirm 
                title={`${record.title}를 삭제하시겠습니까?`} 
                onConfirm={()=>{provideeditDel(record)}}
                >
                  <Button 
                type="default" 
                shape="circle" 
                icon={<DeleteOutlined />} 
                size="small" />
                </Popconfirm>:'' }
                
                </Col>
                </Row>
            </span>
          </>
          
        ),
      },
    ];
    const companyheadcolumns = [
      {
        title: '업체명칭',
        dataIndex: 'title',
        width:'70%',
        key: 'key',
        render:(_,record)=>{
          const isEdit= this.state.EditingCompanyheadKey
          // if (this.state.EditingProvide===record){}
          return (isEdit===record.key?
            <Input.Group compact>
              <Input
              size="small"
              onChange={CompanyheadSet}
              defaultValue={record.title}
              style={{
                  width: 'calc(70%)',
                }}
              rules={[
                {
                  required: true,
                  message: "업체명을 입력해주세요",
                },
              ]}
              />
              <Button 
              size="small"
                type="primary"
                onClick={CompanyHeadeditSave}
                // htmlType="submit"
                
                >저장</Button>
            </Input.Group>
            :record.title)
              }
      },
      {
        title: 'Action',
        with:'80px',
        key: 'action',
        render: (_, record) => (
          <>
            <span>
              <Row gutter={[24, 0]}>
                <Col span={12} style={{paddingLeft:15,paddingRight:1}}>
                  {this.state.logdegree<=3?
                  <Button 
                  onClick={() => this.companyheadedit(record)}
                  type="default" 
                  shape="circle" 
                  icon={<EditOutlined />} 
                  size="small" />:""
                  }
                </Col>
                <Col span={12} style={{paddingLeft:1,paddingRight:15}}>
                {this.state.logdegree<=2? <Popconfirm 
                title={`${record.title}를 삭제하시겠습니까?`} 
                onConfirm={()=>{CompanyHeadeditDel(record)}}>
                  <Button 
                type="default" 
                shape="circle" 
                icon={<DeleteOutlined />} 
                size="small" />
                </Popconfirm>:'' }
                </Col>
                </Row>
            </span>
          </>
        ),
      },
    ];
    const companycolumns = [
      {
        title: '업체명',
        dataIndex: 'companyhead',
        
        key: 'key',
        render:(_,record)=>{
          const isEdit= this.state.EditingBranchsKey
          return (isEdit===record.key?
            <Space wrap>
              <Select
                defaultValue={record.companyhead}
                // style={{
                //   width: 120,
                // }}
                onChange={BranchOnchangeCompany}
                options={this.state.CompanyheadList}
              />
            </Space>
            :record.companyhead)
              }
      },
      {
        title: '지점명',
        dataIndex: 'title',
        key: 'key',
        render:(_,record)=>{
          const isEdit= this.state.EditingBranchsKey
          // if (this.state.EditingProvide===record){}
          return (isEdit===record.key?
            <Space wrap>
              <Input
              size="small"
              onChange={BranchOnchangeTitle}
              defaultValue={record.title}
              style={{
                  width: 'calc',
                }}
              rules={[
                {
                  required: true,
                  message: "지점명을 입력해주세요",
                },
              ]}
              />
              </Space>
            :record.title)
              }
      },
      {
        title: '구분',
        dataIndex: 'provide',
        
        key: 'key',
        render:(_,record)=>{
          const isEdit= this.state.EditingBranchsKey
          return (isEdit===record.key?
            <Space wrap>
              <Select
                defaultValue={record.provide}
                // style={{
                //   width: 120,
                // }}
                onChange={BranchOnchangeProvide}
                options={this.state.ProvideList}
              />
            </Space>
            :record.provide)
              }
      },
      {
        title: '사업자번호',
        dataIndex: 'brnum',
        with:'30%',
        key: 'key',
        render:(_,record)=>{
          const isEdit= this.state.EditingBranchsKey
          // if (this.state.EditingProvide===record){}
          return (isEdit===record.key?
            <Input.Group compact>
              <Input
              size="small"
              onChange={BranchOnchangeBrnum}
              defaultValue={record.brnum}
              type='text'
              maxLength={11}
              style={{
                  width: 'calc',
                  maxWidth:'60%'
                }}
              />
              <Button 
              size="small"
                type="primary"
                onClick={BranchSave}
                // htmlType="submit"
                >저장</Button>
            </Input.Group>
            :record.brnum)
              }
      },
      {
        title: 'Action',
        width:'80px',
        key: 'action',
        render: (_, record) => (
          <>
            <span>
              <Row gutter={[24, 0]} >
                <Col span={12} style={{paddingLeft:15,paddingRight:1}}>
                  {this.state.logdegree<=3?
                    <Button 
                    onClick={() => this.Branchsedit(record)}
                    type="default" 
                    shape="circle" 
                    icon={<EditOutlined />} 
                    size="small" />
                    :""  
                }
                
                </Col>
                <Col span={12} style={{paddingLeft:1,paddingRight:15}}>
                  {this.state.logdegree<=2? 
                  <Popconfirm 
                title={`${record.description}를 삭제하시겠습니까?`} 
                onConfirm={()=>{BranchsDel(record)}}
                >
                  <Button 
                type="default" 
                shape="circle" 
                icon={<DeleteOutlined />} 
                size="small" />
                </Popconfirm>:'' }
                </Col>
                </Row>
            </span>
          </>
          
        ),
        
      },
    ];
   
  //   const Seltest = ()=>{
  //     return (
  //       <>
  //       {this.state.SelEmail.map((item)=>(
  //         <Option value={item}>{item}</Option>
  //       ))}
        
  //       </>
  //     );
  // };
    
    const companyinfocolumns=[
      {title: '업체명',key: 'id',dataIndex: 'title',},
      {title: 'Email',key: 'id',dataIndex: 'email',},
      {title: 'TEL',key: 'id',dataIndex: 'tel',},
      {title: 'HP',key: 'id',dataIndex: 'hp',},
      {title: 'FAX',key: 'id',dataIndex: 'fax',},
      {title: 'Address',key: 'id',dataIndex: 'add',},
      {title: 'User',key: 'id',dataIndex: 'user',},
    ]
    // const residenceschildren= this.state.ProvideList.map({
    //   value:this.state.ProvideList.id,
    //   label:this.state.ProvideList.title,
    // })
    // const residences = [
    //   {
    //     value: 'ddd',
    //     label: 'qdqdw',
    //     children:this.state.residenceschildren
    //   },
    //   {
    //     value: 'jiangsu',
    //     label: 'Jiangsu',
    //     // children: this.state.residenceschildren
    //   },
    // ];
    const handleComplete = (data) => {
      let fullAddress = data.address;
      let extraAddress = '';
  
      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      }
      this.setState({
        AddAddress:fullAddress,
        isModalOpen:false})
    };

    


   
    const { current } = this.state;
    return (
      <>
        <div className="mx-auto mt-50" style={{ maxWidth: "1500px" }}>
          <div className="mb-50">
            <h3 className="mt-25 text-center h3 ">업체관리</h3>
            <h5 className="text-center font-regular h5">
              업종구분 및 업체정보를 수정등록합니다 
              <br/>스텝별로 기본업종과 업체명을 등록하고 본점과 지점을 등록하는 방식입니다
            </h5>
            
            <Steps
              className="my-50"
              progressDot
              current={current}
              onChange={this.onChange}

            >
              <Step 
              title="구분관리" 
              
              />
              <Step 
              title="업체등록" 
              />
              <Step 
              title="추가정보" 
              />
              <Step 
              title="맵검색(웹)" 
              />
            </Steps>
          </div>
          <div className="mb-24">
            {current === 0 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                      구분관리(카테고리 등록/수정)
                    </h5>
                    <p className="font-regular text-center">
                    삭제와 수정은 특정관리자만 가능합니다<br/>
                      사업장구분과 명칭을 관리합니다 실제 업체등록은 다음단계에서 등록할 수 있습니다<br/>
                      별칭은 사업장조회시 사업장이름과 합쳐서 조회됩니다
                      
                    </p>
                  </>,
                ]}
              >
                
                <Row gutter={[24, 0]}>
                  <Col span={24} lg={14} md={24}>
                  <center>
                    <Table 
                    dataSource={this.state.ProvideList} 
                    columns={providecolumns} 
                    style={{maxWidth:"580px"}}
                    // scroll={{
                    //   x: 570,
                    // }}
                    bordered
                    title={() => {
                      return(
                        <>
                          <Title level={5}>
                          {/* <FolderOutlined
                          style={{color:'#eb2f96'}}
                          /> */}
                            업종구분
                          
                          </Title>
                        </>
                      )
                    }}
                    footer={() => {
                      return (
                        <>
                        <Collapse ghost >
                        <Panel header="" key="1">
                        <Form method="post" layout="vertical"onFinish={onFinishProvide}
                          >
                            <Row gutter={[24, 0]}>
                              <Col span={23}>
                              <Form.Item  label='구분(추가등록)'>
                                <Input.Group compact>
                                  <Form.Item 
                                  name="title" 
                                  colon={false} 
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      message: "업종을 입력해주세요",
                                    },
                                  ]}
                                  >
                                    <Input
                                    size="small"
                                    placeholder="업종구분"
                                    name='title'
                                    // value={''}
                                    style={{
                                      width: 'calc(250px)',
                                    }}
                                  />
                                  </Form.Item>
                                  <Form.Item name="astitle" colon={false} noStyle>
                                    <Input
                                    size="small"
                                    placeholder="별칭"
                                    name="astitle" 
                                    style={{
                                      width: 'calc(150px )',
                                    }}
                                    />
                                  </Form.Item>
                                    <Button size="small" type="primary" htmlType="submit"
                                  >등록</Button>
                                  </Input.Group>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                          </Panel>
                          </Collapse>
                        </>
                      )}}/>
                      </center>
                  </Col>
                  <Col span={24} lg={10} md={24}>
                  <center>
                  <Table 
                    dataSource={this.state.CompanyheadList} 
                    columns={companyheadcolumns} 
                    style={{maxWidth:"380px"}}
                    // scroll={{
                    //   x: 350,
                    // }}
                    bordered
                    title={() => {
                      return(
                        <>
                          <Title level={5}>
                          {/* <FolderOutlined
                          style={{color:'#eb2f96'}}
                          /> */}
                            업체명
                          
                          </Title>
                        </>
                      )
                    }}
                    footer={() => {
                      return (
                        <>
                        <Collapse ghost >
                        <Panel header="" key="1">
                        <Form 
                          method="post" 
                          layout="vertical"
                          onFinish={onFinishCompanyHead}
                          >
                            <Row gutter={[24, 0]}>
                              <Col span={24}>
                                <Form.Item label="업체명칭(추가등록)">
                                <Input.Group compact>
                                <Form.Item 
                                name="title" 
                                colon={false} 
                                noStyle
                                rules={[
                                  {
                                    required: true,
                                    message: "명칭을 입력해주세요",
                                  },
                                ]}
                                > 
                                  <Input
                                  size="small"
                                  placeholder="업체명칭"
                                  name="titile" 
                                  style={{
                                    width: 'calc(200px )',
                                  }}
                                  />
                                  </Form.Item>
                                  
                                  <Button 
                                  size="small"
                                  type="primary"
                                  htmlType="submit"
                                  >등록</Button>
                                  
                                </Input.Group>
                                </Form.Item>
                              
                              </Col>
                            </Row>
                          </Form>
                          </Panel>
                          </Collapse>
                        </>
                      )}}/>
                    </center>

                  </Col>
                  </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}></Col>
                      <Col span={12}className="text-left">
                        <Button
                          onClick={this.next}
                          type="primary"
                          className="px-25"
                        >
                          NEXT
                        </Button>
                      </Col>
                    </Row>
              </Card>
            )}
            {current === 1 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                      업체등록
                    </h5>
                    <p className="font-regular text-center">
                      실제 사업자에 대한 기본정보를 저장합니다
                    </p>
                  </>
                ]}
              >
                <div className="px-25">
                
                <Row gutter={[24, 0]} className="mb-24">
                  <Col span={24} lg={12}>
                    <Select
                      defaultValue="5"
                      onChange={this.handleTotalPageChange1}
                      size="large"
                    >
                      <Option value="5">5</Option>
                      <Option value="10">10</Option>
                      <Option value="15">15</Option>
                      <Option value="20">20</Option>
                      <Option value="25">25</Option>
                    </Select>

                    <label className="ml-10">entries per page</label>
                  </Col>
                  <Col span={24} lg={12} className="text-right">
                    <Input
                      style={{ maxWidth: "200px", borderRadius: "8px" }}
                      placeholder="input search text"
                      size="small"
                      suffix={suffix}
                      onChange={handleFilter}
                    />
                  </Col>
                </Row>
                </div>
                <Row gutter={[24, 0]}>
                <Col span={24} lg={24} md={24}>
                  <Title level={2}>
                <Checkbox 
                size=''
                >삭제사업장포함(기능보류)</Checkbox>
                </Title>
                <center>
                <Table
                  className="mt-20"
                  columns={companycolumns}
                  size="small"
                  // width='1200px'
                  bordered
                  dataSource={this.state.Filtered}
                  tableLayout="auto"
                  // onChange={onchange}
                  // scroll={{ x: 100 }}
                  pagination={{
                    pageSize: this.state.totalPage1,
                  }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {record.description}
                      </p>
                    ),
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                  }}
                  footer={() => {
                    return (
                      <>
                      <Collapse ghost >
                      <Panel header="" key="1">
                      <Row gutter={[24, 0]}>
                        <Col span={24} lg={24} md={24}>
                        <Form Form method="post" layout="vertical" onFinish={onFinishBranch}>
                          
                          <Form.Item  label='지점등록(단일 사업장시 본점)' style={{textAlign:'left',paddingLeft:20}}>
                            <Input.Group compact>
                              <Form.Item 
                              name="class" 
                              colon={false} 
                              noStyle
                              rules={[
                                {
                                  type: 'array',
                                  required: true,
                                  message: '구분을 선택해주세요',
                                },
                              ]}
                              >
                                <Cascader 
                                size="small"
                                options={this.state.residences} />
                              </Form.Item>
                              <Form.Item 
                              name="title" 
                              colon={false} 
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: "업체명을 입력해주세요",
                              },]}>
                                <Input
                                size="small"
                                placeholder="지점명"
                                name="astitle" 
                                style={{
                                  width: 'calc(180px )',
                                }}
                                />
                              </Form.Item>
                              <Form.Item 
                              label="사업자번호"
                              noStyle
                              // name="brnum"
                              rules={[
                                {
                                  required: true,
                                  message: "사업자번호을 입력해주세요",
                              },]}
                              >
                              <Input
                                type="text"
                                onChange={BrnumSet}
                                value={this.state.brnum}
                                maxLength={11}
                                
                                size="small"
                                name="brnum"
                                placeholder="사업자번호"
                                // name="brnum" 
                                style={{
                                  width: 'calc(180px )',
                                }}
                                />
                                <Button size="small" type="primary" htmlType="submit"
                              >등록</Button>
                                
                              </Form.Item>
                              </Input.Group>
                            </Form.Item>
                          </Form>
                        </Col>
                  </Row>
                        
                  </Panel>
                  </Collapse>
                    </>
                      
                    )}}
                />
                
                </center>
                </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col span={12} className="text-right">
                    <Button 
                    onClick={this.prev} 
                    className="px-25">
                        PREV
                    </Button>
                  </Col>
                  <Col span={12} className="text-left">
                    <Button
                        onClick={this.next}
                        type="primary"
                        className="px-25"
                      >
                        NEXT
                    </Button>
                  </Col>
                  </Row>
                
              </Card>
            )}
            {current === 2 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                      업체연락망과 사용자관리
                    </h5>
                    <p className="font-regular text-center">
                      업체에 등록된 연락망을 조회합니다 수정 및 등록은 조회 후 가능합니다
                      
                    </p>
                  </>,
                ]}
              >
                <Row gutter={[24, 0]} className="mb-24">
                  <Col span={24} lg={12} className="text-left">
                      <Input
                        style={{ maxWidth: "300px", borderRadius: "8px" }}
                        placeholder="사업장명을 입력해주세요"
                        size="small"
                        onChange={SearchSet}
                      />
                      <Button
                      type="dashed"
                      shape="circle" 
                      onClick={this.searchcompany}
                      style={{ borderRadius: "60px" ,width:'50px',height:'35px',marginLeft:"5px"}}
                      icon={<SearchOutlined />}
                      // suffix={suffix}
                      >
                      </Button>
                  </Col>
                  {/* <Col span={24} lg={12}>
                  </Col> */}
                </Row>
                <Table
                columns={companyinfocolumns}
                dataSource={this.state.SearchList} 
                rowSelection={{
                  type: "radio",
                  ...rowSelection,
                }}
                >
                </Table>
                
                <Tabs className="tabs-sliding" defaultActiveKey="1" onChange={this.callback}>
                  <TabPane tab="Email" key="1">
                    <Space size={[0, 8]} wrap>
                      <Form method="post" layout="vertical" style={{width:'600px'}}>
                      <Form.Item 
                       name='emails'
                      label="E-Mails" 
                      colon={false}
                      >
                        <Select
                          // bordered={false}
                          mode="multiple"
                          key={this.state.SelEmail}
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          // defaultActiveFirstOption={true}

                          // initialValues={this.state.SelEmail}
                          defaultValue={this.state.SelEmail}
                          // value={this.state.SelEmail}
                          onChange={SelecthandleChange}

                          size="large"
                          options={this.state.SelEmail.map((item,index) => ({
                            key:index,
                            label: item,
                            value: item,
                          }))}
                        >
                        </Select>
                        </Form.Item>
                        <Space
                          style={{
                            padding: '0 8px 4px',
                            marginTop:'10px',
                            width:'100%'
                          }}
                        >
                          <Input
                            placeholder="E-Mail 추가"
                            // ref={inputRef}
                            // value={name}
                            onChange={AddEmailOnchange}
                            style={{width:'380px'}}
                          />
                          <Button 
                          onClick={EmailAdd}
                          icon={<PlusOutlined />} >
                            Add item
                          </Button>
                          <Button 
                          onClick={EmailSave}
                          icon={<CheckOutlined />} >
                            저장
                          </Button>
                        </Space>
                        </Form>
                    </Space>
                  </TabPane>
                  <TabPane tab="TEL" key="2">
                  <Space size={[0, 8]} wrap>
                    <Form method="post" layout="vertical" style={{width:'600px'}}>
                        <Form.Item 
                        name='TELnum'
                        label="TEL-Numbers" 
                        colon={false}
                        >
                          <Select
                            mode="multiple"
                            key={this.state.SelTELNum}
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            defaultValue={this.state.SelTELNum}
                            onChange={SelecthandleChangeTel}
                            size="large"
                            options={this.state.SelTELNum.map((item,index) => ({
                              key:index,
                              label: item,
                              value: item,
                            }))}
                          >
                          </Select>
                          </Form.Item>
                          <Space
                            style={{
                              padding: '0 8px 4px',
                              marginTop:'10px',
                              width:'100%'
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="연락처 추가"
                              onChange={AddTELOnchange}
                              style={{width:'380px'}}
                            />
                            <Button 
                            onClick={TELNumAdd}
                            icon={<PlusOutlined />} >
                              Add item
                            </Button>
                            <Button 
                            onClick={TELNumSave}
                            icon={<CheckOutlined />} >
                              저장
                            </Button>
                          </Space>
                        </Form>
                    </Space>
                  </TabPane>
                  <TabPane tab="HP" key="3">
                  <Space size={[0, 8]} wrap>
                    <Form method="post" layout="vertical" style={{width:'600px'}}>
                        <Form.Item 
                        name='HPnums'
                        label="HP-Numbers" 
                        colon={false}
                        >
                          <Select
                            mode="multiple"
                            key={this.state.SelHPNum}
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            defaultValue={this.state.SelHPNum}
                            onChange={SelecthandleChangeHP}
                            size="large"
                            options={this.state.SelHPNum.map((item,index) => ({
                              key:index,
                              label: item,
                              value: item,
                            }))}
                          >
                          </Select>
                          </Form.Item>
                          <Space
                            style={{
                              padding: '0 8px 4px',
                              marginTop:'10px',
                              width:'100%'
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="휴대폰번호 추가"
                              onChange={AddHPOnchange}
                              style={{width:'380px'}}
                            />
                            <Button 
                            onClick={HPNumAdd}
                            icon={<PlusOutlined />} >
                              Add item
                            </Button>
                            <Button 
                            onClick={HPNumSave}
                            icon={<CheckOutlined />} >
                              저장
                            </Button>
                          </Space>
                        </Form>
                    </Space>
                  </TabPane>
                  <TabPane tab="FAX" key="4">
                  <Space size={[0, 8]} wrap>
                    <Form method="post" layout="vertical" style={{width:'600px'}}>
                      <Form.Item 
                        name='FAXS'
                        label="FAX-Numbers" 
                        colon={false}
                        >
                          <Select
                            mode="multiple"
                            key={this.state.SelFAXNum}
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            defaultValue={this.state.SelFAXNum}
                            onChange={SelecthandleChangeFAX}
                            size="large"
                            options={this.state.SelFAXNum.map((item,index) => ({
                              key:index,
                              label: item,
                              value: item,
                            }))}
                          >
                          </Select>
                          </Form.Item>
                          <Space
                            style={{
                              padding: '0 8px 4px',
                              marginTop:'10px',
                              width:'100%'
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="FAX번호 추가"
                              onChange={AddFAXOnchange}
                              style={{width:'380px'}}
                            />
                            <Button 
                            onClick={FAXNumAdd}
                            icon={<PlusOutlined />} >
                              Add item
                            </Button>
                            <Button 
                            onClick={FAXNumSave}
                            icon={<CheckOutlined />} >
                              저장
                            </Button>
                          </Space>
                        </Form>
                      
                    </Space>
                  </TabPane>
                  <TabPane tab="Address" key="5">
                    
                  <Space size={[0, 8]} wrap>

                  <Form method="post" layout="vertical" style={{width:'600px'}}>
                      <Form.Item 
                        name='Address'
                        label="Address" 
                        colon={false}
                        >
                          <Select
                            mode="multiple"
                            key={this.state.SelAddress}
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            disabled
                            defaultValue={this.state.SelAddress}
                            // onChange={SelecthandleChangeAddress}
                            size="large"
                            // options={this.state.SelAddress}
                          >
                          </Select>
                          </Form.Item>
                          <Space
                            style={{
                              padding: '0 8px 4px',
                              marginTop:'10px',
                              width:'100%'
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="주소명"
                              disabled
                              value={this.state.AddAddress}
                              style={{width:'280px'}}
                            />
                            <Input
                              type="text"
                              placeholder="상세주소"
                              disabled={!this.state.AddAddress?true:false}
                              onChange={AddressOnchange}
                              value={this.state.AddAddress2}
                              style={{width:'180px'}}
                            />
                            <Button 
                            onClick={this.showModal}
                            icon={<PlusOutlined />} >
                              검색
                            </Button>
                            <Modal 
                              title="" 
                              visible={this.state.isModalOpen}
                              onChange={onclose} 
                              onOk={this.handleOk} 
                              destroyOnClose={true}
                              onCancel={this.handleCancel}
                              // cancelButtonProps={{ style: { display: 'none' } }}
                              okButtonProps={{ style: { display: 'none' } }}
                              >
                            <Card>
                              <DaumPostcodeEmbed 
                              onComplete={handleComplete} 
                              // onClose={onclose} 
                              autoClose={false}
                              hideMapBtn={true} 
                              hideEngBtn={true}

                              ></DaumPostcodeEmbed>
                              </Card>
                              </Modal>
                            <Button 
                            onClick={AddressSave}
                            icon={<CheckOutlined />} >
                              저장
                            </Button>
                          </Space>
                        </Form>
                        
                        
 
                    </Space>
                  </TabPane>
                  
                  <TabPane tab="User" key="6">
                  <Space size={[0, 8]} wrap>
                    <Form method="post" layout="vertical" style={{width:'600px'}}>
                      <Form.Item 
                        name='Users'
                        label="Users" 
                        colon={false}
                        >
                          <Select
                            mode="multiple"
                            key={this.state.SelUser}
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            defaultValue={this.state.SelUser}
                            onChange={SelecthandleChangeUser}
                            size="large"
                            options={this.state.SelUser.map((item,index) => ({
                              key:index,
                              label: item,
                              value: item,
                            }))}
                          >
                          </Select>
                          </Form.Item>
                          <Space
                            style={{
                              padding: '0 8px 4px',
                              marginTop:'10px',
                              width:'100%'
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="직급"
                              onChange={AddUserClassOnchange}
                              style={{width:'190px'}}
                            />
                            <Input
                              type="text"
                              placeholder="이름"
                              onChange={AddUserOnchange}
                              style={{width:'190px'}}
                            />
                            <Button 
                            onClick={UserAdd}
                            icon={<PlusOutlined />} >
                              Add item
                            </Button>
                            <Button 
                            onClick={ManagerSave}
                            icon={<CheckOutlined />} >
                              저장
                            </Button>
                          </Space>
                        </Form>
                    </Space>
                  </TabPane>

                </Tabs>
               
                
                <Row gutter={[24, 0]} style={{marginTop:'50px'}}>
              <Col span={12} className="text-right">
                <Button 
                onClick={this.prev} 
                className="px-25">
                    PREV
                </Button>
              </Col>
              <Col span={12} className="text-left">
                <Button
                    onClick={this.next}
                    type="primary"
                    className="px-25"
                  >
                    NEXT
                </Button>
              </Col>
              </Row>
                
                
              </Card>
            )}

            {current === 3 && (
              <Card
                className="header-solid"
                bordered={false}
                title={[
                  <>
                    <h5 className="font-regular text-center ">
                    </h5>
                    <p className="font-regular text-center">
                      
                    </p>
                  </>,
                ]}
              >
                
                
                <center>
                  <Iframe url="https://nonghyup.ttmap.co.kr/main.jsp"
                    width="100%"
                    height="800"
                    id=""
                    className=""
                    // maxWidth='9000'
                    
                    // display="block"
                    // position="relative"
                    />
                </center>
                <Row gutter={[24, 0]}>
                  <Col span={12} className="text-right">
                    <Button 
                    onClick={this.prev} 
                    className="px-25">
                        PREV
                    </Button>
                  </Col>
                  <Col span={12} className="text-left">
                    {/* <Button
                        onClick={this.next}
                        type="primary"
                        className="px-25"
                      >
                        NEXT
                    </Button> */}
                  </Col>
                  </Row>
              </Card>
            )}
          </div>
        </div>
      </>
    );
  }
}
