/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component,useMemo } from "react";
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import Bgimg from "../../images/asreport2.jpg";
import gifview from '../../images/asreportview.gif';
import { Link } from "react-router-dom";
import { Row, 
  Col, 
  Card, 
  Avatar, 
  Result,
  Radio,
  Modal,
  Button,
  Carousel,
  Typography,
  Select,
  Switch,
  AutoComplete,
  Alert,
  Popconfirm,
  Form, 
  Table,
  notification,
  Descriptions,
  Space,
  Input
} from "antd";
import {
  SearchOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
  MinusOutlined,
  SmileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { disable } from "workbox-navigation-preload";


const { Title } = Typography;
const { Option } = Select;
const token = localStorage.getItem('jwt') ||false
// const { TextArea } = Input;
const today = new Date();
today.setHours(today.getHours()+9);
const date = today.setDate(today.getDate());
const tempdate = today.setDate(today.getDate()-30);
const daydata = new Date(date).toISOString().split('T')[0]
const startdata = new Date(tempdate).toISOString().split('T')[0]
const {Text } = Typography;
const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);


const contentStyle = {
  height: '1080px',
  color: '#000000',
  lineHeight: '100%',
  textAlign: 'center',
  background: '#FFFFFF',
  maxWidth:"900px"

};


const infodata = [
  {
    key: 'key',
    label: "텍스트",
    product: "장비명",
    company: "이름",
  }
];


export default class ASReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loguser:false,
      logdegree:false,
      docuser:"",
      docuserHP:"",
      docdate:daydata,
      docproduct:"",
      doctitle:"",
      doccontent:"",
      docetc:"",
      doccompany:{},
      allproductlist:[],
      optionproduct:[],
      optioncompony:[],
      companylist:[],
      productlist:[
        {
          key: 0,
          product: "",
          standard: '',
          etc:''
        },
      ],
    alter:false,
    altermessage:"",
    isModarOpen:false,
    result:false,

    totalPage:5,
    donedataswich:false,
    productswich:false,
    companyswich:false,
    listseldate:"createdate",
    startdate:startdata,
    enddate:daydata,
    listproduct:"",
    listcompany:{},
    ASlist:[],
    
    
    };
    this.printref = React.createRef();
    this.printbtn = React.createRef();
    this.searchbtn = React.createRef();
  }
  getlogin = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .post('/api/auths/')
      .then((res)=>{
        console.log(res.data)
        this.setState({
          logdegree:res.data.degree,
          loguser:res.data,
          docuser:res.data.username,
          docuserHP:res.data.HPnum,
          
        })
        console.log(res.data.HPnum)
      })
      .catch((err)=>{
        console.error(err)
      });
  }
  getProduct = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/product/list')
      .then((res)=>{
        let newallproductlist=[...this.state.allproductlist]
        newallproductlist=[]
        res.data.map((item)=>{
          newallproductlist.push({
            product:item.title,
            ea:item.ea,
            id:item.id})
        })
        this.setState({allproductlist:newallproductlist})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  getCompany = async(req, res, next)=>{
    let instance = await axios.create();
      instance.defaults.headers.common['Authorization'] = token;
      instance
      .get('/api/company/list')
      .then((res)=>{
        let newCompanylist=[...this.state.companylist]
        newCompanylist=[]
        res.data.map((item)=>{
          newCompanylist.push({title:item.Company.title,id:item.Company.id})
        })
        this.setState({companylist:newCompanylist})
      })
      .catch((err)=>{
        console.error(err)
      })
  }
  handleTotalPageChange = (val) => {
    this.setState({ totalPage: val });
  };
  componentWillMount(){
    this.getlogin();
    this.getProduct();
    this.getCompany();
  }
  // componentDidMount(prevState, prevProps){
    
  // }
  // componentDidUpdate(prevState, prevProps) {
  // };

  render() {
  const onSelectProduct = (value,num) => {
    switch (num){
      case 1:
        this.setState({docproduct:value});break
      case 2:
        this.setState({listproduct:value});break
    }
    
  };
  
  const onSelectCompany = async(value,num) => {
    let temp=this.state.companylist.filter((item)=>item.title.toLowerCase().includes(value.toLowerCase()))
    let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .get(`/api/company/id=${temp[0].id}`)
        .then((res)=>{
          let data = {
            id:res.data.id,
            title:res.data.title,
          }
          if (num===1){return this.setState({doccompany:data})}
          else if (num===2){return this.setState({listcompany:data})}
        })
        .catch((err)=>{
          console.error(err)
        })
    };
  const handleSearch = (value,num) => {
    switch(num){
      case 1:
        this.setState({
          doccompany:{},
          optioncompony:value ? searchResult(value) : []});break
      case 2:
        this.setState({
          listcompany:false,
          optioncompony:value ? searchResult(value) : []});break
    }
    
  };
  const searchResult = (value) =>
  this.state.companylist.filter((item)=>item.title.toLowerCase().includes(value.toLowerCase()))
    .map((item, idx, array) => {
      const category = `${item.title}`;
        return {
          value: item.title,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                <a
                >
                  {category} 
                </a>
              </span>
              <span>{idx===0? `을 포함한 ${array.length}건`:""}<a style={{color:"#B8B8B8"}}>(ID:{item.id})</a></span>
            </div>
            
          ),
        };
    });
    const handleSearchProduct = (value,num) => {
      switch(num){
        case 1:
          this.setState({optionproduct:value ? searchResultProduct(value) : []});
          this.setState({docproduct:value});
          break;
        case 2:
          this.setState({optionproduct:value ? searchResultProduct(value) : []});
          this.setState({listproduct:false});
          break;
      }
      
    };
    const searchResultProduct = (value) =>
    this.state.allproductlist.filter((item)=>item.product.toLowerCase().includes(value.toLowerCase()))
      .map((item, idx, array) => {
        const category = `${item.product}`;
          return {
            value: item.product,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>
                  <a
                  >
                    {category} 
                  </a>
                </span>
                <span>{idx===0? `을 포함한 ${array.length}건`:""}<a style={{color:"#B8B8B8"}}>(ID:{item.id})</a></span>
              </div>
              
            ),
          };
      });
    
    if (this.state.logdegree>3){
      return (
        <>
          <Result
            status="403"
            title="권한없는 접근입니다"
            subTitle=""
            extra={<>
            </>}
          />
        </>
      )
    }else if(!token||!this.state.logdegree){
      return(<>
        <div>
          <Row>
            <Col lg={12} md={24} sm={24} style={{textAlign:"center"}}>
              
              <img src={gifview} alt="my-gif" />
            </Col>
            <Col lg={12} md={24} sm={24} style={{textAlign:"left"}}>
              <Card>
                <Title level={2} style={{marginLeft:"15px", textAlign:"center"}}>
                  로그인이 필요한 메뉴
                </Title>
                <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Front-End</Text><br/>
                <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>라이브러리로 출력메뉴 구성</Text><br/>
                <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>다양한 조건을 고려한 조회</Text><br/>
                <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>컴포넌트 커스텀</Text><br/><br/>
                <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Back-End</Text><br/>
                <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>완전삭제가 아닌 약한 삭제로 완료처리</Text><br/> 
                <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>다대다 관계에서 관계중간테이블과 관계명 설정</Text><br/> 
              </Card>
            </Col>
              </Row>
            </div>
        </>)
    }
    const choosecolum = [
      {
        title: 'label',
        dataIndex: 'label',
        key: 'key',
        // width:'40%',
        render:(_,record)=>{
              return (
                <>
                <Row style={{textAlign:"center",justifyContent:"center"}}>
                  <Col lg={17} md={24} sm={24}>
                <Title level={1} style={{color:"#929292",textAlign:"center"}}>AS REPORT</Title>
                </Col>
                <Col lg={7} md={24} sm={24}>
                <Input
                addonBefore="담당자"
                onChange={(e)=>{onChangeDocUser(e,1)}}
                style={{textAlign:"right"}}
                value={this.state.docuser}
                >
                </Input><br/>
                <Input
                addonBefore="연락처"
                onChange={(e)=>{onChangeDocUser(e,2)}}
                style={{textAlign:"right"}}
                value={this.state.docuserHP}
                >
                </Input>
                </Col>
                {/* <p style={{color:"black",textAlign:"center",marginLeft:"160px"}}>작성자<br/>이름</p> */}
                </Row>
                </>); 
                  }
      },
    ]
    const chooseSource = [
      {
        key: 'brain',
        label: "label",
        choose: 'choose',
      },
    ];
    
    const choosecolum1 = [
      {
        title: '작성일자',
        dataIndex: 'label',
        key: 'key',
        
        render:(_,record)=>{
          return (
            <>
            <Input
            size="large"
            value={this.state.docdate}
            type="date"
            onChange={(e)=>onChangeDate(e,1)}
            style={{textAlign:"center"}}
            // disabled
            // style={{maxWidth:"130px"}}
          />
            </>)}
      },
      
      {
        title: '장비명',
        dataIndex: 'product',
        key: 'key',
        render:(_,record)=>{
          return (
            <>
            <AutoComplete
            style={{
              width: "100%",
            }}
            dropdownMatchSelectWidth={252}
            options={this.state.optionproduct}
            onSelect={(e)=>onSelectProduct(e,1)}
            onSearch={(e)=>handleSearchProduct(e,1)}
            // size="large"
            // disabled
            // style={{maxWidth:"30%"}}
          >
            <Input.Search size="large" placeholder="input here" enterButton />
            </AutoComplete>
            </>)}
        
      },
      {
        title: '사업장',
        dataIndex: 'company',
        key: 'key',
        render:(_,record)=>{
          return (
            <>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              style={{
                width: "100%",
              }}
              options={this.state.optioncompony}
              onSelect={(e)=>onSelectCompany(e,1)}
              onSearch={(e)=>handleSearch(e,1)}
            >
              <Input.Search size="large" placeholder="input here" enterButton />
            </AutoComplete>
            </>)}
        
      }
    ]
    const choosecolum2 = [
      {
        title: 'label',
        dataIndex: 'label',
        key: 'key',
        // width:'40%',
        render:(_,record)=>{
              return (
                <>
                <Row style={{textAlign:"center",justifyContent:"left"}}>
                  <Title level={3} style={{color:"#929292",textAlign:"left"}}>제 목 : </Title>
                  <Input value={this.state.doctitle} onChange={(e)=>onChangeContente(e,1)} style={{maxWidth:"85%", marginLeft:"20px", fontSize:"20px"}}></Input>
                </Row>
                <Row style={{textAlign:"right",justifyContent:"right",fontSize:"35px"}}>
                <p>의 증상으로 AS를 의뢰합니다</p>
                </Row>
                </>); 
                  }
      },
    ]
    const choosecolum3 = [
      {
        title: '상세내용',
        dataIndex: 'label',
        key: 'key',
        
        render:(_,record)=>{
          return (
            <>
            <textarea 
            value={this.state.doccontent}
            showCount
            rows={15}
            onChange={(e)=>onChangeContente(e,2)}
            size="large"
            // disabled
            style={{width:"100%",marginBottom: 0, resize:"none",fontSize:"20px",borderBlockColor:"#C8C8C8"}}
          />
            </>)}
      },]
    const choosecolum4 = [
      {
        title: 'label',
        dataIndex: 'label',
        key: 'key',
        // width:'40%',
        render:(_,record)=>{
              return (
                <>
                <Row style={{textAlign:"center",justifyContent:"center",paddingTop:"0px"}}>
                  <Title level={3} style={{color:"#929292",textAlign:"left"}}>비 고 : </Title>
                  <Input onChange={(e)=>onChangeContente(e,3)} value={this.state.docetc} style={{maxWidth:"85%", marginLeft:"20px"}}></Input>
                </Row>
                </>); 
                  }
      },
    ]
    const ASlistcolum = [
      {
        title: 'label',
        dataIndex: 'label',
        key: 'key',
        // width:'40%',
        render:(_,record)=>{
              return (
                <>
                <Row style={{textAlign:"center",justifyContent:"center"}}>
                  <Col lg={11} md={24} sm={24} style={{marginTop:"50px"}}>
                    <Title level={1} style={{color:"#929292",textAlign:"center"}}>AS LIST</Title>
                  </Col>
                  <Col lg={13} md={24} sm={24} >
                    <Row align="bottom">
                      <Descriptions title="조회조건" bordered column={2}>
                        <Descriptions.Item label="구분" span={2} >
                          <Row style={{justifyContent:"center"}}>
                            <p>완료건포함여부</p>
                            <Switch 
                            style={{marginBottom:"5px", marginLeft:"5px"}}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(e)=>{this.setState({donedataswich:e})}}
                            />
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label="날짜기준" span={2} >
                          <center>
                            <Radio.Group 
                              options={DateRadio}
                              value={this.state.listseldate}
                              onChange={(e)=>{this.setState({listseldate:e.target.value})}}
                              // optionType="button" 
                              buttonStyle="solid"
                              size="small"
                              />
                          </center>
                        </Descriptions.Item>
                        <Descriptions.Item label="시작기간">
                          <Input
                            size="small"
                            type="date"
                            onChange={(e)=>onChangeDate(e,2)}
                            value={this.state.startdate}
                            disabled={!this.state.listseldate?true:false}
                            style={{textAlign:"right", maxWidth:"130px",}}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="종료기간">
                          <Input
                            size="small"
                            type="date"
                            onChange={(e)=>onChangeDate(e,3)}
                            disabled={!this.state.listseldate?true:false}
                            value={this.state.enddate}
                            style={{textAlign:"right", maxWidth:"130px",}}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="장비명" span={2} >
                        <AutoComplete
                          style={{
                            width: "80%",
                          }}
                          dropdownMatchSelectWidth={252}
                          options={this.state.optionproduct}
                          onSelect={(e)=>onSelectProduct(e,2)}
                          onSearch={(e)=>handleSearchProduct(e,2)}
                          disabled={!this.state.productswich?true:false}
                          // size="large"
                          // disabled
                          // style={{maxWidth:"30%"}}
                        >
                          <Input.Search  placeholder="input here" enterButton />
                          </AutoComplete>
                          {/* <Input
                          size="small"
                          style={{textAlign:"right", maxWidth:"85%",}}
                          /> */}
                          <Switch style={{marginTop:"15px", marginLeft:"10px"}}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(e)=>{this.setState({productswich:e})}}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="사업장" span={2} >
                        <AutoComplete
                          dropdownMatchSelectWidth={252}
                          style={{
                            width: "80%",
                          }}
                          options={this.state.optioncompony}
                          onSelect={(e)=>onSelectCompany(e,2)}
                          onSearch={(e)=>handleSearch(e,2)}
                          disabled={!this.state.companyswich?true:false}
                        >
                          <Input.Search placeholder="input here" enterButton />
                        </AutoComplete>
                          {/* <Input
                            size="small"
                            style={{textAlign:"right", maxWidth:"85%",}}
                            /> */}
                          <Switch style={{marginTop:"15px", marginLeft:"10px"}}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(e)=>{this.setState({companyswich:e})}}
                          />
                        </Descriptions.Item>
                      </Descriptions>
                      <Button
                        style={{marginLeft:"10px", fontSize:"15px"}}
                        type="primary"
                        ref={(ref) => {this.searchbtn=ref}}
                        onClick={getList}
                        >검 색</Button>
                    </Row>
                  </Col>
                </Row>
                </>); 
                  }
      },
    ]
    const ASlistcolum2 = [
      {
        title: 'ID',
        width: 70,
        dataIndex: 'id',
        key: 'key',
        fixed: 'left',
      },
      {
        title: '작성일자',
        // width: 100,
        dataIndex: 'createDate',
        key: 'key',
        // fixed: 'left',
      },{
        title: '문서일자',
        dataIndex: 'docDate',
        key: 'key',
      },
      {
        title: '작성자',
        dataIndex: 'createDateUser',
        key: 'key',
      },
      
      {
        title: '장비명',
        dataIndex: 'Product',
        key: 'key',
      },
      {
        title: '업체명',
        dataIndex: 'Company',
        key: 'key',
      },
      {
        title: '완료일자',
        dataIndex: 'deleteDate',
        key: 'key',
      },
      {
        title: '완료자',
        dataIndex: 'doneUser',
        key: 'key',
      },
      {
        title: '완료처리',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (_, record) => {
          if (!record.doneUser){
            return (
              <>
                <Space wrap>
                  <Popconfirm
                  title={`(ID:${record.id})해당건을 완료처리하겠습니까?`}
                  onConfirm={()=>{DoneSet(record.id)}}
                  >
                    <Button 
                    style={{padding:"5px",fontSize:"15px"}}
                    type="primary"  icon={<IssuesCloseOutlined style={{fontSize:"20px",marginLeft:"5px"}}/>} 
                    size="small"
                    >
                      완료
                    </Button>
                  </Popconfirm>
                </Space>
              </>
            )
          }else{
            return (
              <>
              <Space wrap>
                <CheckCircleOutlined
                style={{fontSize:"25px",color:"#A0CB6F"}} />
              </Space>
              </>
            )
          }
          }
        ,
      },
    ]; 
    
    const DateRadio = [
      {
        label: '문서일자',
        value: 'docdate',
      },
      {
        label: '작성일자',
        value: 'createdate',
      },
      {
        label: '완료일자',
        value: 'donedate',
      },
      {
        label: '없음',
        value: false,
      },
    ];
    const onPrintHandler = () => {
      console.log(this.printref)
      let printContents = this.printref.innerHTML;
      // let printContents = ReactDOM.findDOMNode(a4Notice.current).innerHTML;
      // let windowObject = window.open('', "PrintWindow", "width=1000, height=800, top=100, left=300, toolbars=no, scrollbars=no, status=no, resizale=no");
    
      // windowObject.document.writeln(printContents);
      // windowObject.document.close();
      // windowObject.focus();
      window.open(this.printref)
      // window.print(this.printref)
      // this.printref.print();
      // windowObject.close();
      
    }
    const onChangeContente=(e,num)=>{
      e.preventDefault();
      switch (num){
        case 1 :
          this.setState({doctitle:e.target.value}); break
        case 2:
          this.setState({doccontent:e.target.value}); break
        case 3:
          this.setState({docetc:e.target.value}); break
      }
    }
    const onChangeDocUser=(e,num)=>{
      e.preventDefault();
      switch (num){
        case 1:
          this.setState({docuser:e.target.value});
          break
        case 2:
          this.setState({docuserHP:e.target.value});
          break
      }
    }
    
    const onChangeDate=(e,num)=>{
      e.preventDefault();
      switch (num){
        case 1:
          this.setState({docdate:e.target.value}); break
        case 2:
          this.setState({startdate:e.target.value}); break
        case 3:
          this.setState({enddate:e.target.value}); break
      }
    }
    const componentClear = ()=>{
      this.setState({
      docdate:daydata,
      docproduct:"",
      doctitle:"",
      doccontent:"",
      docetc:"",
      doccompany:{},
      optionproduct:[],
      optioncompony:[],
    })
    }
    
    function sleep(ms) {
      return new Promise((r) => setTimeout(r, ms));
    }

    const onSave = async()=>{
      // this.setState({})
      if (!this.state.doccompany.id){
        this.setState({altermessage:"등록된 사업장만 가능합니다",alter:2, })
        return sleep(3000).then(() => this.setState({alter:false,altermessage:""}))
      }
      else if(this.state.doctitle===""){
        this.setState({altermessage:"제목을 입력해주세요",alter:2, })
        return sleep(3000).then(() => this.setState({alter:false,altermessage:""}))
      }
      else if(this.state.docproduct===""){
        this.setState({altermessage:"장비명을 입력해주세요",alter:2, })
        return sleep(3000).then(() => this.setState({alter:false,altermessage:""}))
      }
      let data = {
        title:this.state.doctitle, 
        content:this.state.doccontent,
        etc:this.state.docetc,
        data:this.state.docdate,
        user:this.state.docuser,
        product:this.state.docproduct,
        company:this.state.doccompany,
      }
      try{
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post('/api/asreport/add',data)
        .then((res)=>{
          this.setState({
            result:res.data,
            isModarOpen:true
          })
        })
        .catch((err)=>{
          console.error(err)
        });
      }catch (error){
        console.error(error)
      }
    }
    
    
    const getList = async()=>{
      let data = {
        getdonelist:this.state.donedataswich,
        division:this.state.listseldate,
        startDate:this.state.startdate,
        endDate:this.state.enddate,
        product:this.state.productswich?this.state.listproduct:false,
        company:this.state.companyswich?this.state.listcompany:false,
      }
      try{
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post('/api/asreport/listdetail',data)
        .then((res)=>{
          if (res.status===200){
            console.log(res.data)
            let newASlist = [...this.state.ASlist]
            newASlist=[]
            res.data.map((item)=>{
              newASlist.push({
                key:item.id, id:item.id, 
                createDate:item.Createuser[0].ASCreateDate.createdAt.substr(0, 10),
                createDateUser:item.Createuser[0].name,
                deleteDate:item.Doneuser.length?item.Doneuser[0].ASDoneDate.createdAt.substr(0, 10):false,
                doneUser:item.Doneuser.length?item.Doneuser[0].name:false,
                docDate:item.doc_date.substr(0, 10),
                title:item.title,
                content:item.content,
                etc:item.etc,
                Product:item.Product.title,
                Company:item.Company.title,})
            })
            this.setState({ASlist:newASlist})
            openNotification("조회되었습니다",`${res.data.length}건 조회`,true)
            console.log(this.state.ASlist)
          }else {
            openNotification(`에러${res.status}`,false)
          }
        })
        .catch((err)=>{
          console.error(err)
        });
      }catch (error){
        console.error(error)
      }
    }
    const openNotification = (title,content,bool) => {
      notification.open({
        message: (<Title level={2}>{title}</Title>),
        description:content,
        placement:'topLeft',
        icon:bool?(
          <CheckCircleOutlined
            style={{
              color: '#108ee9',
              marginTop:"5px"
            }}
          />
        ):(
          <CloseCircleOutlined
            style={{
              color: '#108ee9',
            }}
          />
        ),
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    };
    const DoneSet = async(listid)=>{
      let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .get(`/api/asreport/done&id='${listid}`)
        .then((res)=>{
            // openNotification("처리되었습니다","",true)
          })
        .catch((err) => {
          console.error(err);
        });
        
        openNotification("처리되었습니다","",true)
      }
    
    return (
      <>
      
        <div
          className="profile-nav-bg"
          style={{ backgroundImage: "url(" + Bgimg + ")" }}
        ></div>

        <Card
          className="card-profile-head"
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={24} className="col-info">
                <Avatar.Group>
                  {/* <Avatar size={74} shape="square" src={profilavatar} /> */}

                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{this.state.loguser.username}</h4>
                    <p>{this.state.loguser.useremail}</p>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={24}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
              </Col>
            </Row>
          }
        ></Card>
        <Row gutter={[24, 0]}>
          <center>
        <Col span={25} lg={23} sm={23}>
          <Card className="ASCarousel" >
            <Carousel dotPosition={"left"} effect="fade"  touchMove={false}>
              <div>
              <div ref={el => (this.printref=el)}>
                <Form
                  name="basic"
                  className="asform"
                  style={contentStyle}
                  // className="taxtable"
                  >
                    <Table 
                    // style={{paddingLeft:"60px"}}
                    columns={choosecolum}
                    dataSource={chooseSource}
                    pagination={false}
                    showHeader={false}
                    ></Table>
                    <Table 
                    // style={{paddingLeft:"60px"}}
                    className="astable1"
                    columns={choosecolum1}
                    bordered
                    dataSource={infodata}
                    pagination={false}
                    // showHeader={false}
                    ></Table>
                    <Table 
                    // style={{paddingLeft:"60px"}}
                    columns={choosecolum2}
                    dataSource={chooseSource}
                    bordered
                    pagination={false}
                    showHeader={false}
                    ></Table>
                    <Table 
                    className="astable2"
                    // style={{paddingLeft:"60px"}}
                    columns={choosecolum3}
                    dataSource={infodata}
                    bordered
                    pagination={false}
                    // showHeader={false}
                    ></Table>
                    <Table 
                    // style={{paddingLeft:"60px"}}
                    columns={choosecolum4}
                    dataSource={chooseSource}
                    bordered
                    pagination={false}
                    showHeader={false}
                    ></Table>
                  <div ></div>
                </Form>
                </div>
                <Space
                direction="vertical"
                style={{
                  width: '100%',
                }}
              >
                {this.state.alter===1?<Alert message="저장" type="success"  />:""}
                {this.state.alter===2?<Alert message={this.state.altermessage} type="error"   style={{fontSize:"20px"}}/>:""}
              </Space>
          <Row style={{justifyContent:"center"}} >
            
          <Button type="primary" size="large" 
          onClick={componentClear}
          ref={(ref) => {this.printbtn=ref}}
          style={{fontSize:"25px",marginTop:"15px",marginRight:"15px"}}
          >
            초기화
          </Button>
          <ReactToPrint
            trigger={()=>{
              return <Button type="primary" size="large" 
            style={{fontSize:"25px",marginTop:"15px",marginRight:"15px"}}
            // onClick={onPrintHandler}
            >
              출 력
            </Button>
            }}
            content={()=>this.printref}
            documentTitle="AS REPORT PRINT"
            pageStyle="print"
            />
            <Button type="primary" size="large" 
            onClick={onSave}
            style={{fontSize:"25px",marginTop:"15px"}}
            >
              저 장
            </Button>
          </Row>
          <Modal title="" visible={this.state.isModarOpen} footer={null} onCancel={()=>{this.setState({isModarOpen:false})}}>
            <Title level={3}>
              저장되었습니다
            </Title>
            <p>
              ID : {this.state.result.id}<br/>
              TITLE : {this.state.result.title}<br/>  
              CompanyId : {this.state.result.CompanyId}<br/>  
              ProductId : {this.state.result.ProductId}<br/> 
            </p>
            <center>
            <ReactToPrint
            trigger={()=>{
              return <Button type="primary" size="small" 
            style={{fontSize:"15px",marginTop:"15px",marginRight:"15px"}}
            // onClick={onPrintHandler}
            >
              출 력
            </Button>
            }}
            onAfterPrint={()=>{this.setState({isModarOpen:false})}}
            content={()=>this.printref}
            documentTitle="AS REPORT PRINT"
            pageStyle="print"
            />
            </center>
          </Modal>
              </div>
              
            <div style={contentStyle} className="aslisttable">
              <div style={{marginTop:"47px"}}>
                
              
              <Table 
                
                // style={{paddingLeft:"60px"}}
                columns={ASlistcolum}
                dataSource={chooseSource}
                pagination={false}
                showHeader={false}
                
              ></Table>
              
              <div className="asdatalist">
              <Table
              style={{width:"80%"}}
              columns={ASlistcolum2}
              dataSource={this.state.ASlist}
              bordered
              sticky
              expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                      textAlign:"left"
                    }}
                  >
                    {record.title} : {record.content}:{record.etc}
                  </p>
                ),
                rowExpandable: (record) => record.title,
              }}
              // tableLayout="auto"
              pagination={{
                pageSize: this.state.totalPage,
              }}
              scroll={{
                x: 1400,
              }}
              >
              </Table>
              </div>
              <Row gutter={[24, 0]} className="mb-24" >
                <Col span={24}  >
                  <Select
                    defaultValue="5"
                    onChange={this.handleTotalPageChange}
                    size="large"
                    >
                    <Option value="5">5</Option>
                    <Option value="10">10</Option>
                    <Option value="15">15</Option>
                    <Option value="20">20</Option>
                    <Option value="25">25</Option>
                  </Select>
                    <label className="ml-10">페이지보기</label>
                  </Col>
                  
                 
               
              </Row>
                
              </div>
            </div>
            
          </Carousel>
          
        </Card>
      </Col>
      </center>
          

          {/* <Col span={24} lg={8} className="mb-24">
            <Card
              className="crm-bar-line header-solid h-full"
              bodyStyle={{
                padding: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <a href="#" className="text-center text-muted font-bold">
                <h3
                  className="font-semibold text-muted mb-0"
                  style={{ fontSize: "30px" }}
                >
                  +
                </h3>
                <h5 className="font-semibold text-muted">New project</h5>
              </a>
            </Card>
          </Col> */}
        </Row>
      </>
    );
  }
}
