import React, { Component } from "react";

import { Row, Col, Card } from "antd";
import ChartLine2 from "./chart/ChartLine2";
import ChartLineGradient from "./chart/ChartLineGradient";
import ChartBar2 from "./chart/ChartBar2";
import ChartBarHorizontal from "./chart/ChartBarHorizontal";
import ChartMixed from "./chart/ChartMixed";
import ChartBubble from "./chart/ChartBubble";
import ChartDoughnut from "./chart/ChartDoughnut";
import ChartPie from "./chart/ChartPie";
import ChartRadar from "./chart/ChartRadar";
import ChartPolar from "./chart/ChartPolar";
export default class HDboard extends Component {
  render() {
    return (
      <>
        <div className="chart">
          <Row gutter={[24, 0]}>
            <Col className="mb-24" span={24} lg={12}>
              <h5 className="font-semibold">Dashboard H/W</h5>
              <p>
                홈페이지 웹에 관련된 Dashboard입니다 prometheus와 nginx를 통해 자료를 수집하고 Grafana로 그래프를 생성합니다
              </p>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col lg={12} span={24}>
              <Card
                bordered={false}
                className="header-solid mb-24"
                title={[
                  <React.Fragment key={0}>
                    <h5 className="font-bold">1</h5>
                  </React.Fragment>,
                ]}
                bodyStyle={{ padding: "0px 12px 8px 3px" }}
              >
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=168" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=174" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=161" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=160" width="100%" height="350px" frameborder="0"></iframe>
              </Card>
            </Col>
            <Col lg={12} span={24}>
              <Card
                bordered={false}
                className="header-solid mb-24"
                title={[
                  <React.Fragment key={0}>
                    <h5 className="font-bold">2</h5>
                  </React.Fragment>,
                ]}
                bodyStyle={{ padding: "0px 12px 8px 3px" }}
              >
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=7" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=156" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=13" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=175" width="100%" height="350px" frameborder="0"></iframe>
              </Card>
            </Col>
            <Col lg={12} span={24}>
              <Card
                bordered={false}
                className="header-solid mb-24"
                title={[
                  <React.Fragment key={0}>
                    <h5 className="font-bold">3</h5>
                  </React.Fragment>,
                ]}
                bodyStyle={{ padding: "0px 12px 8px 3px" }}
              >
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=191" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=195" width="100%" height="350px" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=197" width="100%" height="350px" frameborder="0"></iframe>
                
              </Card>
            </Col>
            <Col lg={12} span={24}>
              <Card
                bordered={false}
                className="header-solid mb-24"
                title={[
                  <React.Fragment key={0}>
                    <h5 className="font-bold">4</h5>
                  </React.Fragment>,
                ]}
                bodyStyle={{ padding: "0px 12px 8px 3px" }}
              >
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=181" width="100%" height="100%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=158" width="100%" height="100%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=177" width="100%" height="100%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=183" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=157" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=16" width="100%" height="30%" frameborder="0"></iframe>
              </Card>
            </Col>

            {/* <Col lg={12} span={24}>
              <Card
                bordered={false}
                className="header-solid mb-24"
                title={[
                  <React.Fragment key={0}>
                    <h5 className="font-bold">5</h5>
                  </React.Fragment>,
                ]}
                bodyStyle={{ padding: "0px 12px 8px 3px" }}
              >
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=15" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=20" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=14" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=179" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=75" width="100%" height="30%" frameborder="0"></iframe>
                <iframe src="https://ityumhouse.com/grafana/d-solo/xfpJB9FGz/1-node-exporter-for-prometheus-dashboard-en-20201010?orgId=1&refresh=5s&theme=light&panelId=178" width="100%" height="30%" frameborder="0"></iframe>
              </Card>
            </Col> */}
            
          </Row>
        </div>
      </>
    );
  }
}
