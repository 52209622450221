import React, { Component } from "react";
import axios from 'axios';
import ReactDOM from 'react-dom';
import {Client} from "@googlemaps/google-maps-services-js";
import gifview from '../../images/mapplagon.gif';
import {MapMarker, Map,Polygon, CustomOverlayMap,} from 'react-kakao-maps-sdk'
import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  Result,
  Radio,
  Divider, 
  Descriptions,
  Tabs,
  Space,
  Carousel, 
  Tag,
  Input
} from "antd";
const token = localStorage.getItem('jwt') ||false
const { Title } = Typography;
const { Text } = Typography;
const { TabPane } = Tabs;

const andong = require('./Polygon/안동시.json');
const youngzu = require('./Polygon/영주시.json');
const bonghwa = require('./Polygon/봉화군.json');
const youngyang = require('./Polygon/영양군.json');
const ulzin = require('./Polygon/울진군.json');
const yechun = require('./Polygon/예천군.json');
const youngduk = require('./Polygon/영덕군.json');
const pohangbuk = require('./Polygon/포항북구.json');
const pohangnam = require('./Polygon/포항남구.json');
const chungsong = require('./Polygon/청송군.json');
const ewsung = require('./Polygon/의성군.json');
const sangzu = require('./Polygon/상주시.json');
const jechun = require('./Polygon/제천시.json');
const danyang = require('./Polygon/단양군.json');
const munkyung = require('./Polygon/문경시.json');
const gunwe = require('./Polygon/군위군.json');
const colorlist=['#fff1d9','#fed9a3','#fec36d','#fda93d','#fd961f','#fd8609','#f87806','#f06705','#e95606','#de3a06']
const ncolorlist=['#ffe5ea','#fec1c9','#e98589','#dc5c60','#e93b3f','#ee2b29','#dc2228','#c71a23','#b7151e','#a00108']
const today = new Date();
today.setHours(today.getHours()+9);
const date = today.setDate(today.getDate());
const tempdate = today.setDate(today.getDate()-30);
const daydata = new Date(date).toISOString().split('T')[0]
const startdata = new Date(tempdate).toISOString().split('T')[0]
  export default class Billing extends Component {
    constructor(props){
        super(props);
        this.state={
          loguser:false,
          logdegree:false,
          downCount:0,
          ASdoctype:"createdate",
          startdate:startdata,
          enddate:daydata,
          mousePosition:{
            lat: 0,
            lng: 0,},
          polygonlist :[
            {name:"안동시",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(andong.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"영주시",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(youngzu.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"봉화군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(bonghwa.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"영양군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(youngyang.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"울진군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(ulzin.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"예천군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(yechun.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"영덕군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(youngduk.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"포항시 남구",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(pohangnam.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"포항시 북구",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(pohangbuk.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"청송군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(chungsong.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"의성군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(ewsung.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"상주시",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(sangzu.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"제천시",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(jechun.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"단양군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(danyang.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"문경시",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(munkyung.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
            {name:"군위군",isMouseOver:false, isMouseClick:false, color:colorlist[0], count:0, ncolor:ncolorlist[0], ncount:0,
              list:new Array(gunwe.features[0].geometry.coordinates[0].map((item,index)=>{return { lat: item[1], lng: item[0] }}))},
          ]
        }
        this.map = React.createRef();
        this.script = React.createRef();
        
        // this.script.src="https://polyfill.io/v3/polyfill.min.js?features=default"
        // this.script.async=true
        // this.document.body.appendChild(this.script)
    }
    getlogin = async(req, res, next)=>{
      let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .post('/api/auths/')
        .then((res)=>{
          this.setState({
            logdegree:res.data.degree,
            loguser:res.data,
          })
        })
        .catch((err)=>{
          console.error(err)
        });
    }
    getAscount = async(req, res, next)=>{
      let list= []
      this.state.polygonlist.map((item)=>list.push(item.name))
      let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .get('/api/map/ascount')
        .then((res)=>{
          let newPolygonlist = [...this.state.polygonlist]
          res.data.map((item,index,array)=>{
            let target = newPolygonlist.findIndex(element => item.add.region_2depth_name===element.name)
            if (newPolygonlist[target]){
              newPolygonlist[target].count+=1
              // let countlist = [...newPolygonlist.map(num=>num.count)]; 
              let totalnum = array.length
              let num = newPolygonlist[target].count
              newPolygonlist[target].color=
              num >=(totalnum*0.9)?colorlist[9]:
              num >=(totalnum*0.8)?colorlist[8]:
              num >=(totalnum*0.7)?colorlist[7]:
              num >=(totalnum*0.6)?colorlist[6]:
              num >=(totalnum*0.5)?colorlist[5]:
              num >=(totalnum*0.4)?colorlist[4]:
              num >=(totalnum*0.3)?colorlist[3]:
              num >=(totalnum*0.2)?colorlist[2]:
              num >=(totalnum*0.1)?colorlist[1]:
              colorlist[0]
            }
              
          })
          this.setState({polygonlist:newPolygonlist})
          console.log(this.state.polygonlist)
          
        })
        .catch((err)=>{
          console.error(err)
        });
    }
    componentWillMount(){
      this.getlogin();
      this.getAscount()
    }
    componentDidMount() {
      // const script = document.createElement("script");
      // script.type = 'text/javascript';
      // script.async = true;
      // script.src=process.env.PUBLIC_URL+"/js/map.js"
      // document.body.append(script)
      // // this.script.appendChild(script)
      // console.log(this.script)
    }
    componentWillUnmount(){

    }
    
    render() {
      if (this.state.logdegree>3){
        return (
          <>
            <Result
              status="403"
              title="권한없는 접근입니다"
              subTitle=""
              extra={<>
              </>}
            />
          </>
        )
      }else if(!token||!this.state.logdegree){
        return(<>
        <div>
          <Row>
            <Col lg={12} md={24} sm={24} style={{textAlign:"center"}}>
              
              <img src={gifview} alt="my-gif" />
            </Col>
            <Col lg={12} md={24} sm={24} style={{textAlign:"left"}}>
              <Card>
              <Title level={2} style={{marginLeft:"15px", textAlign:"center"}}>
                로그인이 필요한 메뉴
              </Title>
              <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Front-End</Text><br/>
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>행정구역별 현재 AS 진행건과 기간별누적건을 분포도로 표시</Text><br/>
              <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>QGIS를 통해 각 행정구역의 로케이션으로 구역구분</Text><br/>
              
              {/* <Text type="secondary" style={{fontSize:"15px", marginLeft:"25px"}}>컴포넌트 커스텀</Text><br/><br/> */}
              <Text code style={{fontSize:"20px", marginLeft:"15px"}}>Back-End</Text><br/>
              </Card>
            </Col>
              </Row>
            </div>
        </>)
      }
      const test =()=>{
        console.log(this.state.polygonlist.findIndex((v) => v.isMouseover))
      }
      const onChangeTab =(e)=>{
        this.map.goTo(e-1)
        console.log(e)
      }
      const onChangeDate=(e,num)=>{
        e.preventDefault();
        switch (num){
          case 1:
            this.setState({startdate:e.target.value}); break
          case 2:
            this.setState({enddate:e.target.value}); break
        }
      }
      const onSearch = async()=>{
        console.log(this.state.ASdoctype, this.state.startdate, this.state.enddate)
        let instance = await axios.create();
        instance.defaults.headers.common['Authorization'] = token;
        instance
        .get(`/api/map/ascount/query?start=${this.state.startdate}&end=${this.state.enddate}&division=${this.state.ASdoctype}`)
        .then((res)=>{
          let newPolygonlist = [...this.state.polygonlist]
          newPolygonlist.map((item) => {
            item.ncount = 0;
            item.ncolor = ncolorlist[0];
          })
          res.data.map((item,index,array)=>{
            let target = newPolygonlist.findIndex(element => item.add.region_2depth_name===element.name)
            if (newPolygonlist[target]){
              newPolygonlist[target].ncount+=1
              // let countlist = [...newPolygonlist.map(num=>num.count)]; 
              let totalnum = array.length
              let num = newPolygonlist[target].ncount
              console.log(totalnum,num,newPolygonlist[target].ncount)
              newPolygonlist[target].ncolor=
              num >=(totalnum*0.9)?ncolorlist[9]:
              num >=(totalnum*0.8)?ncolorlist[8]:
              num >=(totalnum*0.7)?ncolorlist[7]:
              num >=(totalnum*0.6)?ncolorlist[6]:
              num >=(totalnum*0.5)?ncolorlist[5]:
              num >=(totalnum*0.4)?ncolorlist[4]:
              num >=(totalnum*0.3)?ncolorlist[3]:
              num >=(totalnum*0.2)?ncolorlist[2]:
              num >=(totalnum*0.1)?ncolorlist[1]:
              ncolorlist[0]
            }
              
          })
          this.setState({polygonlist:newPolygonlist})
          console.log(this.state.polygonlist)
        })
        .catch((err)=>{
          console.error(err)
        });
      }
      const contentStyle = {
        height: '100%',
        width: '100%',
        // color: '#fff',
        // lineHeight: '160px',
        textAlign: 'center',
        // background: '#364d79',
      };
      return (
        
        <>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24} lg={24} xl={12}>
                <Row gutter={[24, 0]}>
                    <Col span={24} md={24} className="mb-24">
                    <Card
                        className="header-solid h-full"
                        bordered={false}
                        title={[
                        <h6 className="font-semibold m-0" key={0}>MAP</h6>,
                        ]}
                        bodyStyle={{ paddingTop: "0" }}
                    >
                      <div className="mapCarousel">
                      <Carousel effect="fade" touchMove={false} ref={(ref) => {this.map=ref}} >
                        <div>
                          <div  style={contentStyle}>
                          <Map
                              center={{
                                // 지도의 중심좌표
                                lat: 36.5761205474728,
                                lng: 128.505722686385,
                              }}
                              level={11}
                              style={{ width: "100%", height: "550px" }}

                              onMouseMove={(_map, mouseEvent) =>
                                this.setState({mousePosition:{
                                  lat: mouseEvent.latLng.getLat(),
                                  lng: mouseEvent.latLng.getLng(),}})
                              }
                              
                            >
                              {this.state.polygonlist.map((item,index)=>{
                                return (<Polygon
                                  key={item.name}
                                  path={item.list}
                                  strokeWeight={2} 
                                  strokeColor={"#39DE2A"} 
                                  strokeOpacity={0.8} 
                                  strokeStyle={"solid"} 
                                  fillColor={item.isMouseOver ? "#EFFFED" : item.color} // 채우기 색깔입니다
                                  fillOpacity={!item.isMouseOver ? 0.8 : 0.9} // 채우기 불투명도입니다
                                  onMouseover={() => {
                                    let temp =[...this.state.polygonlist]
                                    if (temp[index].isMouseOver){return}
                                    temp[index].isMouseOver =true
                                    return this.setState({polygonlist:temp})
                                  }}
                                  onMouseout={() => {
                                    let temp =[...this.state.polygonlist]
                                    if (!temp[index].isMouseOver){return}
                                    temp[index].isMouseOver =false
                                    return this.setState({polygonlist:temp})
                                  }}
                                  onMousedown={(_polygon, mouseEvent) => {
                                    let temp =[...this.state.polygonlist]
                                    temp.map(item=>item.isMouseClick=false)
                                    temp[index].isMouseClick =true
                                    return this.setState({polygonlist:temp})
                                  }}
                              />)
                              })}

                            </Map>
                          </div>
                        </div>
                        <div>
                          <div  style={contentStyle}>
                          <Map
                              center={{
                                // 지도의 중심좌표
                                lat: 36.5761205474728,
                                lng: 128.505722686385,
                              }}
                              level={11}
                              style={{ width: "100%", height: "550px" }}

                              onMouseMove={(_map, mouseEvent) =>
                                this.setState({mousePosition:{
                                  lat: mouseEvent.latLng.getLat(),
                                  lng: mouseEvent.latLng.getLng(),}})
                              }
                              
                            >
                              {this.state.polygonlist.map((item,index)=>{
                                return (<Polygon
                                  key={item.name}
                                  path={item.list}
                                  strokeWeight={2} 
                                  strokeColor={"#39DE2A"} 
                                  strokeOpacity={0.8} 
                                  strokeStyle={"solid"} 
                                  fillColor={item.isMouseOver ? "#EFFFED" : item.ncolor} // 채우기 색깔입니다
                                  fillOpacity={!item.isMouseOver ? 0.8 : 0.9} // 채우기 불투명도입니다
                                  onMouseover={() => {
                                    let temp =[...this.state.polygonlist]
                                    if (temp[index].isMouseOver){return}
                                    temp[index].isMouseOver =true
                                    return this.setState({polygonlist:temp})
                                  }}
                                  onMouseout={() => {
                                    let temp =[...this.state.polygonlist]
                                    if (!temp[index].isMouseOver){return}
                                    temp[index].isMouseOver =false
                                    return this.setState({polygonlist:temp})
                                  }}
                                  onMousedown={(_polygon, mouseEvent) => {
                                    let temp =[...this.state.polygonlist]
                                    temp.map(item=>item.isMouseClick=false)
                                    temp[index].isMouseClick =true
                                    return this.setState({polygonlist:temp})
                                  }}
                              />)
                              })}

                            </Map>
                          </div>
                        </div>
                        <div>
                          <div  style={contentStyle} >
                            <div id="map" style={{width:"100%",height: "550px"}} ref={(ref) => {this.script=ref}}>

                            </div>
                          </div>
                        </div>
                        {/* <div id="map" ref={(ref) => {this.map=ref} }></div> */}
                        {/* <div ref={DOMNodeRef=>{this.script=DOMNodeRef}}></div> */}
                        {/* <Button onClick={test}>ㅇㅇ</Button> */}
                        </Carousel>
                        </div>
                    </Card>
                    </Col>
                
                </Row>
            </Col>
            <Col span={24} md={24} lg={24} xl={12} className="mb-24">
            <Tabs className="tabs-sliding" defaultActiveKey="1" tabPosition={"right"} onChange={onChangeTab}>
              <TabPane tab="AS(진행건)" key="1">
                  <Card
                    bordered={false}
                    className="header-solid h-full ant-invoice-card"
                    title={[<h6 className="font-semibold m-0" key={0}>AS 분포도</h6>]}
                  >
                    <div className="ant-list-box table-responsive">
                      <table className="width-100 table-settings-notifications">
                        <thead>
                          <tr>
                            <th>지역명</th>
                            <th>건수</th>
                            <th>지역명</th>
                            <th>건수</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.polygonlist.map((item,index,array)=>{
                            if(index %2===0){
                              return(
                                <tr >
                                  <td style={{backgroundColor:item.isMouseClick?item.color:""}}>
                                    <Text style={{fontSize:"15px"}} >{item.name}</Text>
                                  </td>
                                  <td style={{backgroundColor:item.isMouseClick?item.color:""}}>
                                    <p>{item.count}</p>
                                  </td>
                                  <td style={{backgroundColor:array[index+1].isMouseClick?array[index+1].color:""}}>
                                    <Text style={{fontSize:"15px"}} >{array[index+1]. name}</Text>
                                  </td>
                                  <td style={{backgroundColor:array[index+1].isMouseClick?array[index+1].color:""}}>
                                    <p>{array[index+1].count}</p>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </TabPane>
                <TabPane tab="AS(누적건)" key="2">
                  <Card
                    bordered={false}
                    // style={{minWidth:"350px"}}
                    className="header-solid h-full ant-invoice-card"
                    title={[<h6 className="font-semibold m-0" key={0}>누적AS 분포도</h6>]}
                  >
                    <div className="mapAS">
                    <Descriptions title={"조회조건"} bordered column={2} extra={<Button type="primary" size="small" onClick={onSearch}>검색</Button>}>
                      <Descriptions.Item label="구분" span={2} > 
                          <Radio.Group size="small" defaultValue={this.state.ASdoctype} onChange={(e)=>{this.setState({ASdoctype:e.target.value})}}>
                            <Radio value={"createdate"}>작성일자</Radio>
                            <Radio value={"docdate"}>문서일자</Radio>
                            <Radio value={"donedate"}>완료일자</Radio>
                          </Radio.Group>
                      </Descriptions.Item>
                      <Descriptions.Item label="시작" >
                         <Input size="small" type="date" style={{maxWidth:"140px"}} value={this.state.startdate} onChange={(e)=>{onChangeDate(e,1)}}></Input>
                      </Descriptions.Item>
                      <Descriptions.Item label="종료"  >
                        <Input size="small" type="date" style={{maxWidth:"140px"}} value={this.state.enddate} onChange={(e)=>{onChangeDate(e,2)}}></Input>
                      </Descriptions.Item>
                    </Descriptions>
                    </div>
                    <div className="ant-list-box table-responsive">
                      <table className="width-100 table-settings-notifications">
                        <thead>
                          <tr>
                            <th>지역명</th>
                            <th>건수</th>
                            <th>지역명</th>
                            <th>건수</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.polygonlist.map((item,index,array)=>{
                            if(index %2===0){
                              return(
                                <tr >
                                  <td style={{backgroundColor:item.isMouseClick?item.ncolor:""}}>
                                    <Text style={{fontSize:"15px"}} >{item.name}</Text>
                                  </td>
                                  <td style={{backgroundColor:item.isMouseClick?item.ncolor:""}}>
                                    <p>{item.ncount}</p>
                                  </td>
                                  <td style={{backgroundColor:array[index+1].isMouseClick?array[index+1].ncolor:""}}>
                                    <Text style={{fontSize:"15px"}} >{array[index+1]. name}</Text>
                                  </td>
                                  <td style={{backgroundColor:array[index+1].isMouseClick?array[index+1].ncolor:""}}>
                                    <p>{array[index+1].ncount}</p>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </TabPane>
                <TabPane tab="동선검색" key="3">
                  <Card
                    bordered={false}
                    className="header-solid h-full ant-invoice-card"
                    title={[<h6 className="font-semibold m-0" key={0}>동선검색</h6>]}
                  >
                  </Card>
                </TabPane>
              
              
              </Tabs>
            </Col>
          </Row>
          
        </>
      );
    }
  }
